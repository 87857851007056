import React, { Component } from "react";
import moment from "moment";
import "./ErrorBoundary.css";
import CustomButton from "../custom/CustomButton";
import { trackEvent } from "../../analytics";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    trackEvent("errorboundary_error", { error, info });
    console.error("ErrorBoundary caught an error", error, info);
    const lastReloaded = localStorage.getItem("last_error_reloaded");
    if (
      !lastReloaded ||
      moment(lastReloaded).isBefore(moment().subtract(10, "minutes"))
    ) {
      localStorage.setItem("last_error_reloaded", moment().toISOString());
      this.handleReload();
    }
    this.setState({
      hasError: true,
    });
  }

  handleReload = () => {
    window.location.reload(true);
    trackEvent("errorboundary_reloaded");
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="errorboundary-container">
          <div className="errorboundary-heading">
            Oops! Something went wrong.
          </div>
          <div className="errorboundary-message">
            We're sorry for the inconvenience. Please try refreshing the page or
            reopening the tab.
          </div>
          {/* <CustomButton onClick={this.handleReload} color="primary">
            Try Reload
          </CustomButton> */}
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
