const tokenKey = "userAdmin";
const { stringify, parse } = JSON;

const storageService = {
  get: (key = tokenKey) => parse(localStorage.getItem(key) ?? null),
  set: (key = tokenKey, value) => localStorage.setItem(key, stringify(value)),
  remove: (key = tokenKey) => localStorage.removeItem(key),
  clear: () => localStorage.clear(),
};

export default storageService;
