import depositsTypes from "../../actionTypes/manageDepositTypes";

const initialState = {
  depositTypesList: [],
  depositTypeDetails: {},
  fetching: false,
  actionFetching: false,
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case depositsTypes.SET_LIST: {
      return { ...state, depositTypesList: action.payload };
    }

    case depositsTypes.DETAILS: {
      return { ...state, depositTypeDetails: action.payload };
    }

    case depositsTypes.FETCHING: {
      return { ...state, fetching: action.payload };
    }

    case depositsTypes.ACTION_FETCHING: {
      return { ...state, actionFetching: action.payload };
    }

    default: {
      return { ...state };
    }
  }
};
