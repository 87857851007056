const bookingTypes = {
  FETCHING: "BOOKING_FETCHING",
  SET_BOOKING_LIST: "SET_BOOKING_LIST",
  SET_BOOKING_VIEW: "SET_BOOKING_VIEW",
  SET_REASONS: "SET_REASONS",
  SET_BOOKING_COUNT:"SET_BOOKING_COUNT",
  SET_BOOKING_COUNT_HOTELWISE:"SET_BOOKING_COUNT_HOTELWISE"

};

export default bookingTypes;
