import { routes } from "../../../../component_route/Index";
import { history } from "../../../../history";
import {
  changePasswordApi,
  forgotPasswordApi,
  getProfileApi,
  updateProfileApi,
  verifyNumber,
  verifyOTP,
} from "../../../../services/authServices";
import storageService from "../../../../services/storageServices";
import authType from "../../../actionTypes/authTypes";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";
import ga4, { trackEvent } from "../../../../analytics";

export const verifyNumberAction = (data) => {
  return async (dispatch) => {
    dispatch({ type: authType.FETCHING, payload: true });

    const response = await verifyNumber(data);

    dispatch({ type: authType.FETCHING, payload: false });

    if (response?.status == 200) {
      toast.success(response?.message);
      return response.data;
      // const { name, email, country, role, permission } = response?.data?.user;
      // await storageService.set({
      //   ...response?.data?.user,
      //   permission: permission || [],
      // });
      // dispatch({ type: authType.CHANGE_ROLE, payload: role });
      // dispatch({ type: authType.SET_USER, payload: { name, email, country } });
      // window.location = `/admin${routes.home}`;
    } else {
      toast.error(response?.message);
    }
  };
};

export const verifyOTPAction = (data) => {
  return async (dispatch) => {
    dispatch({ type: authType.FETCHING, payload: true });

    const response = await verifyOTP(data);

    dispatch({ type: authType.FETCHING, payload: false });

    if (response?.status == 200) {
      toast.success(response?.message);
      const { mobile, dialCode } = response?.data;
      ReactGA.set({ userId: mobile });
      ga4.eventTrack("LoginPage", `${mobile} logged in`, "Login");
      storageService.set("userAdmin", {
        ...response?.data,
        role: response?.data?.roleInfo?.name,
        // permission: [],
        permission: [
          "users",
          "bookings",
          "manage",
          "add_staff",
          "hotelImage",
          "inventoryManagement",
          "allImage",
          "settlements",
          "gads",
        ],
      });
      console.log("res", response.data);

      storageService.set("token", response?.data?.token);
      dispatch({ type: authType.CHANGE_ROLE, payload: "HOTEL_OWNER" });
      dispatch({ type: authType.SET_USER, payload: { mobile, dialCode } });
      window.location = `/admin${routes.home}`;
    } else {
      toast.error(response?.message);
    }
  };
};

export const verifyAfterGoogleSignin = (data) => {
  return async (dispatch) => {
    const { email, token, roleInfo } = data;
    ReactGA.set({ userId: email });
    ga4.eventTrack("LoginPage", `${email} logged in`, "Login");
    trackEvent('LoginPage-logged-in-email', {email})
    storageService.set("userAdmin", {
      ...data,
      role: roleInfo?.name,
      permission: [
        "users",
        "bookings",
        "manage",
        "add_staff",
        "hotelImage",
        "inventoryManagement",
        "allImage",
        "settlements",
        "gads",
      ],
    });
    storageService.set("token", token);
    dispatch({ type: authType.CHANGE_ROLE, payload: "HOTEL_OWNER" });
    dispatch({ type: authType.SET_USER, payload: { email } });
    window.location = `/admin${routes.home}`;
  }
}

export const logOutAction = (data) => {
  return async (dispatch) => {
    localStorage.clear();
    history.push(routes.login);
  };
};

export const updateProfile = (data, callback) => async (dispatch) => {
  dispatch({ type: authType.FETCHING, payload: true });

  const response = await updateProfileApi(data);

  dispatch({ type: authType.FETCHING, payload: false });
  if (response?.statusCode === 200) {
    const profile = await getProfileApi();
    storageService.set({ ...storageService.get(), ...profile?.data?.result });

    dispatch({ type: authType.SET_USER, payload: profile.data?.result });
    toast.success(response?.message);
    callback();
  } else {
    toast.error(response?.message);
  }
};

export const changePassword = (data, callback) => async (dispatch) => {
  dispatch({ type: authType.FETCHING, payload: true });

  const response = await changePasswordApi(data);

  dispatch({ type: authType.FETCHING, payload: false });
  if (response?.statusCode === 200) {
    toast.success(
      "Password changes successfully. kindly login again to continue."
    );
    callback();
  } else {
    toast.error(response?.message);
  }
};

export const forgotPassword = (data, callback) => async (dispatch) => {
  dispatch({ type: authType.FETCHING, payload: true });

  const response = await forgotPasswordApi(data);

  dispatch({ type: authType.FETCHING, payload: false });
  if (response?.statusCode === 200) {
    toast.success("Please check your mail");
    callback();
  } else {
    toast.error(response?.message);
  }
};
