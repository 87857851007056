import { parseISO } from "date-fns";
import { addDays, format } from "date-fns/esm";
import storageService from "../../../../services/storageServices";
import inventoryTypes from "../../../actionTypes/inventoryTypes";

const initialState = {
  updatedDates: {},
  fetching: false,
  hotelRooms: [],
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case inventoryTypes.FETCHING: {
      return { ...state, fetching: action.payload };
    }

    case inventoryTypes.FETCH_ROOMS: {
      return { ...state, hotelRooms: action.payload };
    }

    case inventoryTypes.FETCH_ROOM_INVENTORY: {
      let startDate = storageService.get("startDate");

      const selectedHotel = storageService?.get("selectedHotel");

      let copyObj = { ...state.updatedDates };

      let initCount = storageService.get("initCount");

      initCount === 1 &&
        state.hotelRooms.forEach((room) => {
          let roomArr = [];
          let copyStart = startDate.toString();

          for (let i = 0; i <= 14; i++) {
            let temp = {
              checkInDate: copyStart,
              hotelRoomId: room?.id,
              price: room?.defaultPrice,
              roomCount: room?.roomCount,
            };
            roomArr.push(temp);
            copyStart = format(addDays(parseISO(copyStart), 1), "yyyy-MM-dd");
          }

          copyObj = { ...copyObj, [room?.id]: [...roomArr] };
        });
      storageService.set("initCount", ++initCount);

      let requiredRoom =
        copyObj[action.payload && action.payload[0]?.hotelRoomId];

      if (requiredRoom) {
        for (let i = 0; i < requiredRoom?.length; i++) {
          for (let j = 0; j < action.payload.length; j++) {
            if (requiredRoom[i].checkInDate === action.payload[j].checkInDate) {
              requiredRoom.splice(i, 1, action.payload[j]);
            }
          }
        }
        copyObj = {
          ...copyObj,
          [action.payload[0]?.hotelRoomId]: [...requiredRoom],
        };
      }

      return {
        ...state,
        updatedDates: copyObj,
      };
    }

    default: {
      return { ...state };
    }
  }
};
