import React, { useState } from "react";
import styles from "../styles/CustomButtonStyles.css";
import { Loader } from "react-feather";
import { Button } from "reactstrap";

export default function CustomButton({
  onClick,
  color = "#04245a",
  loading,
  children,
  style,
  disable,
  buttonStyle = {},
  textColor,
}) {
  color = color === "primary" ? "#04245a" : color;

  const disabled = !!(loading || disable);
  return (
    <div className="custom-button-wrapper" style={style}>
      <button
        className={"button"}
        onClick={disabled ? null : onClick}
        style={{
          position: "relative",
          backgroundColor: color,
          color: textColor
            ? textColor
            : color === "#04245a"
            ? "white"
            : "black",
          ...(disable ? { opacity: "0.5" } : {}),
          ...buttonStyle,
        }}
        disabled={disabled}
      >
        {loading && <Loader className="custom-button-loader" />}
        <span style={loading ? { opacity: 0 } : {}}>{children}</span>
      </button>
    </div>
  );
}
