import userTypes from "../../../actionTypes/userTypes";
const initialState = {
  usersList: [],
  fetching: false,
  pagination: { pageNo: 1, pages: 1 },
  actionLoader: false,
  images: [],
  hotelImages: [],
  settleBookingIds: [],
  settleHotels: [],
  fetchingBooking: false,
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case userTypes.SET_LIST: {
      return { ...state, usersList: action.payload };
    }

    case userTypes.FETCHING: {
      return { ...state, fetching: action.payload };
    }

    case userTypes.PAGINATION: {
      return { ...state, pagination: action.payload };
    }

    case userTypes.FETCH_IMAGE: {
      return { ...state, images: action.payload };
    }

    case userTypes.FETCH_HOTEL_IMAGE: {
      return { ...state, hotelImages: action.payload };
    }

    case userTypes.SETTLE_HOTEL_ID: {
      return { ...state, settleBookingIds: action.payload };
    }

    case userTypes.SETTLE_HOTEL_LIST: {
      return { ...state, settleHotels: action.payload };
    }

    case userTypes.FETCH_BOOKING_ID: {
      return { ...state, fetchingBooking: action.payload };
    }

    default: {
      return { ...state };
    }
  }
};
