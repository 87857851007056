const complysTypes = {
  FETCHING: "COMPLY_LIST_FETCHING",
  ACTION_FETCHING: "COMPLY_ACTION_FETCHING",
  SET_LIST: "SET_COMPLY_LIST",
  DETAILS: "SET_COMPLY_DETAILS",
  PAGINATION: "COMPLY_LIST_PAGINATION",
  SET_DOCUMENTS: "SET_DOCUMENTS_LIST",
  CLEAR_DOCUMENTS: "CLEAR_DOCUMENTS_LIST",
};

export default complysTypes;
