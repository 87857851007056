import transactionsTypes from "../../actionTypes/transactionsTypes";

const initialState = {
  transactionsList: [],
  transactionDetails: {},
  fetching: false,
  pagination: { pageNo: 1, pages: 1 },
  actionLoader: false,
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case transactionsTypes.SET_LIST: {
      return { ...state, transactionsList: action.payload };
    }

    case transactionsTypes.DETAILS: {
      return { ...state, transactionDetails: action.payload };
    }

    case transactionsTypes.FETCHING: {
      return { ...state, fetching: action.payload };
    }

    case transactionsTypes.ACTION_LOADER: {
      return { ...state, actionLoader: action.payload };
    }

    case transactionsTypes.PAGINATION: {
      return { ...state, pagination: action.payload };
    }

    default: {
      return { ...state };
    }
  }
};
