import storageService from "../../../../services/storageServices";
import hotelTypes from "../../../actionTypes/hotelTypes";

const initialState = {
  fetching: false,
  hotels: [],
  selectedHotel: {},
  selectedHotelStats: {},
  views: {},
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case hotelTypes.FETCHING: {
      return { ...state, fetching: action.payload };
    }

    case hotelTypes.SET_HOTELS_LIST: {
      return { ...state, hotels: action.payload };
    }

    case hotelTypes.SET_SELECTED_HOTEL: {
      return { ...state, selectedHotel: action.payload };
    }

    case hotelTypes.SET_STATS: {
      return { ...state, selectedHotelStats: action.payload };
    }

    case hotelTypes.SET_VIEWS: {
      return { ...state, views: action.payload };
    }

    default: {
      return { ...state };
    }
  }
};
