// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          Home: "Home",
          "Enter valid question!": "Enter valid question!",
          taxes: "Taxes",
          overview: "Overview",
          this_week: "This Week",
          sales: "Sales",
          this_month: "This Month",
          lifetime: "Lifetime",
          website_views: "Website Views",
          bookings: "Bookings",
          reviews_links_sent: "Reviews Links Sent",
          share_your_website: "Share your website",
          copy: "Copy",
          revenue_manager: "Revenue Manager",
          great_you_are_offering_the_best_price:
            "Great! You are offering the best price",
          keep_same_price_get_more_profits:
            "Keep the same price to get more profits.",
          attention_needed_you_are_losing_money:
            "Attention needed, you are losing money!",
          loosing_money_without_lowest_price:
            "You'll be losing money if your website doesn't offer the lowest price.",
          dont_let_otas_take_booking_profits:
            "Don't let OTA's Take your Booking & profits",
          your_official_site: "Your Official Site",
          update_price: "Update Price",
          price_last_synced_from_google_on: "Price last synced from Google on",
          set_up_your_website: "Set up your website",
          complete_profile_for_more_conversions:
            "Complete your profile for more conversions.",
          add_cancellation_policy: "Add Cancellation Policy",
          add_staff_receptionist: "Add Staff / Receptionist",
          add_posts: "Add Posts",
          add_profile: "Add Profile",
          add_website_logo: "Add Website Logo",
          set_payment_mode: "Set Payment Mode",
          add_your_email: "Add Your Email",
          add_discounts: "Add Discounts",
          add_property_rules: "Add Property Rules",
          dd_faqs: "Add FAQs",
          whats_new: "What's new",
          share_your_website: "Share your website",
          copy: "Copy",
          create_profile: "Create Profile",
          set_up_and_manage_profile_visible_as_about_us:
            "Set up and manage the profile which will be visible as about us.",
          about_us: "About Us",
          your_host: "Your Host",
          im_tejaswi_the_heart_behind_the_little_ranch:
            "I'm Tejaswi, the heart behind 'The Little Ranch'. I aim to offer you an authentic escape. With a commitment to sustainability, your stay supports a greener tomorrow. Book directly with us for the best service and offers!",
          share_post: "Share Post",
          share_post_in_google: "Share post in Google",
          google_my_business_profile: "Google my Business Profile",
          set_up_and_manage_your_google_profile:
            "Set up and manage your Google profile",
          custom_domain: "Custom Domain",
          rank_higher_in_google: "Rank Higher in Google",
          out_of_10_pending: "Out of 10 pending",
          go_back: "Go Back",
          new: "New",
          upcoming: "Upcoming",
          completed: "Completed",
          cancelled: "Cancelled",
          all_bookings: "All Bookings",
          name: "Name",
          booking_id: "Booking ID",
          mobile: "Mobile",
          search: "Search",
          get_report: "Get Report",
          hotel_name: "Hotel Name",
          hourly: "Hourly",
          view_details: "View Details",
          invoice_raised_no_changes_allowed:
            "Invoice raised, no changes allowed.",
          accept: "Accept",
          reject: "Reject",
          no_show: "No Show",
          checkin: "Checkin",
          cancel: "Cancel",
          checkout: "Checkout",
          checkin_checkout_timings: "Checkin - Checkout Timings",
          rooms: "Rooms",
          total_amount: "Total Amount",
          guests: "Guests",
          payment_mode: "Payment Mode",
          settlement: "Settlement",
          source: "Source",
          status: "Status",
          addons: "Addons",
          select_reason: "Select Reason",
          proceed: "Proceed",
          cancel: "Cancel",
          send_new_price_to: "Send New Price to",
          we_will_notify: "We will notify",
          on_whatsapp_email_and_sms: "on Whatsapp, Email and SMS",
          booking_summary: "Booking Summary",
          total_rooms: "Total Rooms",
          total_guests: "Total Guests",
          total_amount: "Total Amount",
          enter_revised_amount: "Enter Revised Amount",
          note_new_price_will_be_sent_to_guest:
            "Note: The new price will be sent to the guest. The booking will not be canceled unless the guest says no to the new price. If the guest agrees to the new price, the booking will be confirmed.",
          send_updated_price: "Send Updated Price",
          cannot_cancel_post_checkin_date:
            "Cannot cancel post check-in date, for any booking changes directly contact guest at",
          note: "Note",
          mark_no_show: "Mark No show",
          price_details: "Price Details",
          amount_paid: "Amount Paid",
          refund_amount: "Refund Amount",
          do_you_still_want_to: "Do you still want to",
          suggested_price: "Suggested Price",
          already_selling_for_suggested_price:
            "You are already selling it for our suggested price.",
          your_lowest_priced_room_on_date: "Your lowest priced room on date",
          on: "On",
          on_your_website: "On your website",
          our_suggested_price: "Our suggested price",
          confirm: "Confirm",
          enter_custom_price: "Enter custom price",
          acceptance_rate: "Acceptance Rate",
          reject_anyway: "Reject Anyway",
          cancel_anyway: "Cancel Anyway",
          call_us: "Call Us",
          call_or_whatsapp_us_at: "Call or WhatsApp us at",
          need_bank_details: "Need Bank Details",
          please_add_bank_account_before_check_in:
            "Please add your bank account before Check In.",
          add_bank_account: "Add Bank Account",
          do_you_want_to_apply_cancellation_policy:
            "Do you want to apply cancellation policy?",
          yes_apply: "Yes, Apply",
          type: "Type",
          quantity: "Quantity",
          invoice_raised_no_change_allowed:
            "Invoice raised, no change allowed.",
          email: "Email",
          adult_count: "Adult Count",
          child_count: "Child Count",
          infant_count: "Infant Count",
          awaiting_guest_confirmation: "Awaiting Guest Confirmation",
          guest_confirmed_new_price: "Guest Confirmed New Price",
          no_rooms_added: "No Rooms Added",
          meals: "Meals",
          no_addons_added: "No Addons Added",
          payment_details: "Payments Details",
          new_amount: "New Amount",
          balance_amount: "Balance Amount",
          commission: "Commission",
          tax: "tax",
          ref_no: "Ref no",
          settlement_amount: "Settlement Amount",
          settled_date: "Settled Date",
          refund_details: "Refund Details",
          refund_credited_within_5_7_working_days:
            "The refund amount will be credited to the account within 5-7 working days starting from",
          select_reason: "Select Reason",
          do_you_still_want_to: "Do you still want to",
          cannot_cancel_post_checkin_date:
            "Cannot cancel post check-in date, for any booking changes directly contact guest at",
          your_acceptance_rate: "Your Acceptance Rate",
          high: "High",
          settlement_details: "Settlement Details",
          something_went_wrong: "Something went wrong",
          no_data_found: "No Data found",
          bulk_edit: "Bulk Edit",
          updated_room_count: "Updated Room Count",
          updated_price: "Updated Price",
          default_room_count_default_price:
            "Default Room Count / Default Price",
          update: "Update",
          price_for: "Price for",
          room: "Room",
          price: "Price",
          guest: "Guest",
          timestamp: "Timestamp",
          room_count: "Room count",
          phone: "Phone",
          no_datas_associated_with_date_and_room:
            "No Datas associated with that date and room.",
          changed_on: "Changed On",
          bulk_edit: "Bulk Edit",
          from_date: "From Date",
          to_date: "To Date",
          available_rooms: "Available Rooms",
          prev_month: "Prev Month",
          prev_week: "Prev Week",
          next_month: "Next Month",
          next_week: "Next Week",
          update_property_details: "Update Property Details",
          save: "Save",
          property_name: "Property Name",
          amenities: "Amenities",
          stay_type: "Stay Type",
          currency: "Currency",
          reception_number: "Reception number",
          gst_in: "GST IN",
          add_email: "Add Email",
          property_address: "Property Address",
          property_description: "Property Description",
          improve_description: "Improve description",
          generate_description: "Generate Description",
          powered_by_ai: "powered by AI",
          select_hotel_amenities: "Select Hotel Amenities",
          select_stay_type: "Select Stay Type",
          where_upload_images_from: "Where do you want to upload images from?",
          contact_us: "Contact Us",
          email_us: "Email Us",
          whatsapp_us: "Whatsapp Us",
          directly_call_us_at: "You can directly call us at",
          close: "Close",
          sure_to_change_gmb_number: "Are you sure to change the GMB number?",
          yes_sure: "Yes, sure",
          create_room: "Create Room",
          edit_room: "Edit Room",
          room_name: "Room Name",
          room_type: "Room Type",
          bed_count: "Bed Count",
          room_count: "Room Count",
          bed_type: "Bed Type",
          base_price: "Base Price",
          edit: "Edit",
          configure_hourly_rate: "Configure Hourly Rate",
          hourly_rate: "Hourly Rate",
          available: "Available",
          based_on_todays_full_day_price_of:
            "Based on today's full day price of",
          for: "for",
          edit_price: "Edit Price",
          minimum_guest_count: "Minimum Guest Count",
          maximum_guest_count: "Maximum Guest Count",
          min_guest_count_cannot_be_greater_than_max:
            "Minimum Guest Count cannot be greater than Maximum Guest Count",
          price_details: "Price Details",
          bed: "bed",
          guests_cannot_be_greater_than_price_for:
            "guests can not be greater than price for",
          price_per_child: "Price per child",
          age_range: "Age range",
          to: "to",
          years: "Years",
          price_for_infant: "Price for infant",
          price_for_child: "Price for Child",
          to_18_years: "to 18 years",
          food_options: "Food Options",
          breakfast: "Breakfast",
          included_or_free: "Included / Free",
          lunch: "Lunch",
          dinner: "Dinner",
          enter_price_for_breakfast: "Enter Price for Breakfast",
          enter_price_for_lunch: "Enter Price for Lunch",
          enter_price_for_dinner: "Enter Price for Dinner",
          choose_images: "Choose images",
          add_room: "Add Room",
          add_your_room_welcome_guests: "Add your room, welcome your guests!",
          last_checkin: "Last Check-in",
          add_room_details: "Add Room Details",
          add_guest_details: "Add Guest Details",
          minimum_number_of_guests_allowed: "Minimum number of guests allowed",
          maximum_number_of_guests_allowed: "Maximum number of guests allowed",
          incl_of_taxes: "Incl. of taxes",
          add_price_details: "Add Price Details",
          reduce_by: "Reduce by",
          increase_by: "Increase By",
          base_price: "Base price",
          add_children_pricing: "Add Children Pricing",
          age: "Age",
          add_food_details: "Add Food Details",
          do_you_provide_food: "Do you provide food?",
          yes: "Yes",
          no: "No",
          included: "Included",
          select_room_amenities: "Select Room Amenities",
          upload_room_images: "Upload Room Images",
          back: "Back",
          next: "Next",
          upload: "Upload",
          payment_configuration: "Payment Configuration",
          advanced_percentage: "Enter advance Percentage",
          online_payment: "Online Payment",
          your_property_accept_online_payments:
            "Enable this to accept fully paid online bookings via UPI/DEBIT/CREDIT cards or Netbanking",
          partial_payment: "Partial Payment",
          reduce_no_shows_by_collecting_percentage:
            "Enable this to collect partial payment while booking",
          pay_at_property: "Pay at Property",
          your_property_accept_pay_at_property_bookings:
            "Enable this to accept pay at property bookings",
          bank_details: "Bank Details",
          upi_id: "UPI ID",
          bank_account_details: "Bank Account Details",
          invalid_upi_id: "Invalid UPI ID",
          enter_account_holder_name: "Enter Account Holder Name",
          invalid_account_holder_name: "Invalid Account Holder Name",
          account_holder_name: "Account Holder Name",
          enter_account_number: "Enter the Account Number",
          invalid_account_number: "Invalid Account Number",
          account_number: "Account Number",
          enter_ifsc_code: "Enter IFSC Code",
          invalid_ifsc_code: "Invalid IFSC Code",
          ifsc_code: "IFSC Code",
          save_bank_details: "Save Bank Details",
          buy_custom_domain:
            "Buy your Custom domain to create a brand & improve your property search",
          includes: "Includes",
          google_search_seo: "Google search SEO",
          website_hosting: "Website Hosting",
          free: "Free",
          ssl_encryption_domain_setup: "SSL encryption & domain setup",
          online_website: "Online Website",
          add_rooms_addons_payments_more:
            "Add rooms, add-ons, payments and more",
          custom_domain: "Custom Domain",
          your_hotelname_dot_com: "Your hotelname.com",
          manage_your_website_access: "Manage Your Website (access)",
          android_ios_web: "Android + iOS + Web",
          add_unlimited_rooms: "Add Unlimited Rooms",
          sales_channel: "Sales Channel",
          sell_online_share_social_media:
            "Sell online and share on social media",
          google_hotels_integration: "Google Hotels Integration",
          official_site_tag_google_profile:
            "Official site tag on google profile",
          addons: "Add ons",
          sell_value_added_service:
            "Sell value added service like guided tours, vehicle rental, jeep safari, spa and so on directly from your website.",
          chat_with_guests_whatsapp:
            "Chat with your guests directly via whatsapp to answer queries and convert visitors to bookings.",
          whatsapp_chat: "Whatsapp Chat",
          google_reviews: "Google Reviews",
          automated_messages_google_reviews:
            "Send automated messages via sms, email and whatsapp to checked out guests for google reviews.",
          payments: "Payments",
          set_partial_payment_methods:
            "Set partial payment methods to reduce no shows.",
          nearby_attractions: "Nearby attractions",
          showcase_nearby_places:
            "Showcase nearby places to visit near your property and attract more guests.",
          automated_abandoned_guest_recovery:
            "Automated abandoned guest recovery",
          automated_whatsapp_messages:
            "Send automated personalised whatsapp messages to website visitors and generate more bookings.",
          reporting_analytics: "Reporting & Analytics",
          get_sales_report:
            "Get sales report in excel format and website views.",
          property_logo: "Property Logo",
          get_property_logo:
            "Get a property logo made by hotelzify team for your website.",
          partner_support_24_7: "24/7 partner support",
          language_support:
            "With language support in Hindi, Kannada, Tamil, Malayalam, English and Arabic.",
          available_domains: "Available domains for you.",
          search_for_domain: "Search for domain.",
          no_available_domains:
            "Oops! No domains available. Try different name.",
          domain_already_purchased: "Domain Already Purchased.",
          special_discount: "Special Discount",
          available_at: "Available at",
          buy_now_at: "Buy now at",
          per_day: "per day",
          payment_successful: "Payment Successful",
          web_reflection_time:
            "Please expect a web reflection time of approximately 48 hours. Thank you for your patience!",
          social_media: "Social Media",
          number: "Number",
          username: "Username",
          reports: "Reports",
          select_month: "Select month",
          emails: "Emails",
          download_and_send_report: "Get Report",
          select_date_range_less_than_one_month:
            "Please select date range less than one month.",
          add_logo: "Add Logo",
          upload_your_logo: "Upload Your Logo",
          house_rules: "House Rules",
          property_rules: "Property Rules",
          property_rules: "Property Rules",
          check_in_time: "Check-in time",
          cancellation_policy: "Cancellation Policy",
          add_cancellation_policy: "Add Cancellation Policy",
          is_charged_for: "is charged for",
          before_checkin: "before check-in",
          website_with_clear_cancellation_policy_helps:
            "Website with clear cancellation policy helps to get more bookings.",
          how_much_of_booking_amount_charged_for_cancellation:
            "How much % of booking amount is the guest charged for cancellation?",
          days_before_arrival: "Days before arrival",
          enter_percentage_of_amount_to_deduct:
            "Enter percentage of amount to deduct",
          enter_number_of_days_before_arrival:
            "Enter number of days before arrival",
          delete_cancellation_policy: "Delete Cancellation Policy",
          booking_preference: "Booking Preference",
          instant_book: "Instant Book",
          when_this_is_on_bookings_are_accepted_automatically:
            "When this is on, bookings are accepted automatically. When off, you will need to manually accept or decline booking requests.",
          edit_faq: "Edit FAQ",
          enter_question: "Enter Question",
          enter_answer: "Enter Answer",
          delete_faq: "Delete FAQ",
          are_you_sure_you_want_to_delete: "Are you sure you want to delete?",
          faqs: "FAQs",
          show_new_faq: "Show New FAQ",
          add_faq: "Add FAQ",
          please_add_faqs_to_increase_bookings:
            "Please Add FAQs to increase bookings",
          answer: "Answer",
          answer_the_question: "Answer the question",
          answer_and_add_to_faq: "Answer and Add to FAQ",
          add: "Add",
          enter_your_own_question: "Enter Your Own Question",
          suggested: "SUGGESTED",
          select_addon_type: "Select Addon Type",
          enter_addon_name: "Enter Addon Name",
          enter_price: "Enter Price",
          addon_name: "Addon Name",
          addon_price: "Addon Price",
          addon_description: "Enter Addon Description",
          upload_addon_images: "Upload Addon Images",
          actions: "Actions",
          addons: "Addons",
          add_Addon: "Add Addon",
          no_addons_found: "No Addons Found",
          Enter_Addon_Details: "Enter Addon Details",
          Enter_Addon_Name: "Enter_Addon_Name",
          Edit_Addon: "Edit_Addon",
          hotel_name: "Hotel Name",
          amount: "Amount",
          leads: "Leads",
          fetching_leads: "Fetching Leads",
          no_leads_found: "No Leads found",
          id: "ID",
          add_staff: "Add Staff",
          no_staffs_found: "No Staffs found",
          phone_number: "Phone Number",
          active_invoice: "Active Invoice",
          no_active_invoices: "No Active Invoices",
          past_invoice: "Past Invoice",
          no_past_invoices: "No Past Invoice",
          due_on: "Due on",
          overdue: "Overdue",
          online_prepaid: "Online / Prepaid",
          pay_now: "Pay now",
          invoice: "Invoice",
          improving_profile: "Please wait while we improve your profile...",
          google_my_business_profile: "Google My Business Profile",
          use_hotelzify_to_set_up_and_manage_google_business_profile:
            "Use Hotelzify to set up and manage your Google Business Profile",
          how_does_it_work: "How does it work?",
          see_profile_before_after:
            "See how your profile looks before and after Google My Business",
          google_my_business_setup: "Google My Business Profile Setup",
          select_property_gmb:
            "We found these properties associated with your account. Select one:",
          duplicate_profile: "Duplicate Profile",
          verification_required: "Verification Required",
          connect_google_profile: "Connect Google Profile",
          create_gmb_profile: "Create Google My Business Profile",
          try_another_email: "Try another email?",
          someone_else_may_manage_profile:
            "Someone else may manage this Google Profile",
          switch_account_message:
            "If this is registered with a different email, switch accounts below.",
          no_access_message: "If you no longer have access to the account,",
          let_us_know: "let us know",
          request_access_on_google:
            "Or request access from the current manager of the business on Google",
          request_access: "Request Access",
          switch_account: "Switch Account",
          connect_sections:
            "Select the sections to connect on your Google Business Profile",
          website_url: "Website URL",
          photos: "Photos",
          promotions: "Promotions",
          flat: "Flat",
          off: "off",
          strengthen_online_presence:
            "Strengthen your online presence with a polished and engaging Google Business Profile that highlights your hotel's unique features and amenities.",
          keep_guests_informed:
            "Keep your potential guests well-informed with accurate details about your hotel, including contact information, address, operating hours, and more.",
          high_quality_visual_content: "Hochwertiger visueller Inhalt",
          on_bookings_above: "On bookings above",
          last_minute_deals: "Last Minute Deals",
          early_bird_offer: "Early Bird Offer",
          hotel_addons: "Hotel Addons",
          sync_google_profile: "Sync Google Profile",
          google_business_profile: "Google Business Profile",
          hotelzify_google_partner: "Hotelzify is an official Google Partner!",
          view_agreement: "View agreement",
          key_benefits_of_the_new_feature: "Key Benefits of the New Feature",
          enhanced_digital_presence: "Enhanced Digital Presence",
          accurate_up_to_date_information:
            "Accurate and Up-to-Date Information",
          verify_profile: "Verify your Google profile to continue",
          visual_content:
            "Showcase your property through captivating images that leave a lasting impression on potential guests, compelling them to choose your hotel.",
          verify: "Überprüfen",
          if_different_email:
            "If you manage this account from a different email address",
          fantastic_job: "Fantastic Job",
          google_profile_optimized:
            "Your Google profile is now optimized for success.",
          note_enhancing_profile: "Note: Enhancing your profile",
          automated_reply: "Automated Reply",
          auto_reply_feature:
            "You can now reply to all reviews rated 4 stars & above effortlessly with our new auto-reply feature.",
          reviews: "Reviews",
          filter_by: "Filter by",
          all: "All",
          sort_by: "Sort by",
          no_reviews: "No reviews yet!",
          check_out_to_send_review_links:
            "Check-out bookings to send Google review links to guests",
          check_out_bookings: "Check-out bookings",
          previous_reply: "Previous Reply",
          cancel: "Cancel",
          confirm: "Confirm",
          improved_reply: "Improved Reply",
          replying_to_review: "Replying to review",
          guests_notified_about_reply:
            "The guests will be notified about your reply, and it will be publicly visible on your business profile",
          submit_response: "Submit response",
          your_response: "Your Response",
          improve_with_ai: "Improve with AI",
          reply: "Reply",

          not_found_property: "Couldn't find property?",
          search_your_place: "Search your place",
          enter_details_for_accuracy:
            "To get accurate results, enter the area, city, and country along with the property name!",
          enter_address_manually: "Enter address manually",
          property_details: "Property Details",
          address: "Address",
          pincode: "Pincode",
          country: "Country",
          state: "State",
          enter_mobile_number: "Enter Mobile Number",
          select_stay_type: "Select the type of your stay",
          select_hotel_amenities: "Select Hotel Amenities",
          upload_images: "Upload Images",
          logout: "Logout",
          property_exists: "Property Exists",
          is_already_registered_by: "is already registered by",
          request_staff_access_or_raise_dispute:
            "You can request staff access or raise a dispute from below",
          request_employee_access: "Request Employee Access",
          raise_a_dispute: "Raise a dispute",
          confirm_property_ownership:
            "Please confirm if this property belongs to you?",
          continue: "Yes, Continue",
          visit_dashboard: "Visit Dashboard",
          visit_your_website: "Visit your website",
          congratulations:
            "Congratulations! You have successfully created your website",
          enter_property_details: "Enter your property name, area, city",
          check_out_time: "Check-out time",
          try_adding_post: "Try adding a post now",
          add_new_post: "Add New Post",
          title: "Title",
          description: "Description",
          submit: "Submit",
          posts: "Posts",
          profile: "Profile",
          your_name: "What is your name?",
          languages_you_know: "Languages you know",
          what_do_you_like: "What do you like?",
          what_is_your_nature: "What is your nature?",
          upload_an_image: "Upload an Image",
          profile_completed: "Profile Completed",
          view_button: "View",
          relax_message:
            "Sit back and relax – our digital artisans are busy crafting your online masterpiece...",

          your_host: "Your Host",
          last_minute_deals_explanation:
            "Last-minute deals are a way to offer special rates to guests when the hotel has a high vacancy rate and wants to fill rooms quickly. For example, if a guest books two days before check-in and more than half of the rooms are empty, the hotel may offer a 20% discount. If the last-minute discount only applies to certain nights within a reservation, it will only be applied to those nights and not the entire stay.",
          generate_description_ai: "Generate Description using AI",
          languages: "Languages",
          nature: "Nature",
          interests: "Interests",
          bulk_discounts_explanation:
            "Bulk discounts are a way to offer discounts to guests who are staying for a long time or in a large group. These discounts can be set as a percentage of the bill when it exceeds a certain amount. For example, if the bill is over Rs. 10,000, the hotel may offer a 10% discount.",
          early_bird_discount: "Early Bird Discount",
          last_minute_discount: "Last Minute Discount",
          full_name: "Full Name",
          bulk_discount: "Bulk Discount",
          fill_all_fields: "Please fill all the fields",
          keep_one_day_before_checkin:
            "Please keep at least 1 day before check-in",
          promotions_discounts: "Promotions / Discounts",
          add_promotion: "Add Promotion",
          bulk_discount: "Bulk Discount",
          discounts: "discounts",
          added: "Added",
          days_before_check_in: "Days before Checkin",
          add_your_first_discount: "Add your first discount",
          early_bird_discounts_explanation:
            "Early bird discounts are a way to encourage guests to book and pay in advance. For example, if a guest books more than three weeks ahead of time, they may receive an additional discount of 10%.",
          profile_completed_message:
            "You have successfully completed all the required fields for the profile section. Click the below button to view or update profile.",
          days_discount: "Days - Discount",
          add_now: "Add Now",
          bulk_discounts: "Bulk Discounts",
          apply: "Apply",
          percentage: "Percentage",
          for_total_bill_amount_more_than: "For total bill amount more than",
          no_promotions_available:
            "No promotions available, please add promotions to attract more guests!",
          discount: "Discount",
          number_of_days: "Number of Days",
          discount_in: "Discount in",
          select_discount: "Select Discount",
          delete_promotion: "Delete Promotion",
          personal_info: "Personal Info",
          login: "Login",
          welcome_back: "Welcome back, please login to your account",
          success: "success",
          logout_confirmation: "Are you sure you want to logout ?",
          send_otp: "Send OTP",
          enter_otp: "Enter the OTP",
          verify_otp: "Verify OTP",
          by_signing_in: "By signing in you agree to the",
          or: "or",
          terms_and_conditions: "Terms and Conditions",
          change_language: "Change Language",
          create_property: "Create Property",
          enter_property_name: "Enter property name",
          enter_address: "Enter Address",
          select_hotel_type: "Select Hotel Type",
          please_try_again: "Please try again",
          no_results_found: "No results found",
          names_updated_successfully: "Names updated successfully",
          mobile_number_changed_successfully:
            "Mobile number changed successfully",
          personal_information: "Personal Information",
          first_name: "First Name",
          update_email: "Update Email",
          last_name: "Last Name",
          enter_price: "Please enter price",
          price_cannot_be_zero: "Price cannot be zero",
          contact_to_change_property_name_or_address:
            "Please contact us to change the property name or address",
          otp_send_successfully: "OTP send successfully",
          you_have_seen_all_the_bookings: "You have seen all the bookings",
          value_cannot_be_less_than_0: "Value cannot be less than 0",
          update_inventory_for_at_least_one_room:
            "Please update inventory for at least one room",
          from_date_cannot_be_greater_than_to_date:
            "From date cannot be greater than To date",
          from_and_to_date_cannot_be_the_same:
            "From and To date cannot be the same",
          please_select_from_date: "Please select FROM date",
          please_select_to_date: "Please select TO date",
          please_enter_valid_phone_number: "Please enter valid phone number",
          please_add_at_least_3_images: "Please add at least 3 images",
          invalid_gst_number: "Invalid GST Number",
          please_try_again_later: "Please try again later!",
          price_is_required: "Please enter price",
          check_in_dates_required:
            "First Check-In and Last Check-In is required",
          price_greater_than_zero: "Price must be greater than zero",
          check_in_dates_order: "Last Check-In must be after First Check-In",
          price_less_than_base_price: "Price must be less than base price",
          min_guest_greater_than_max_guest:
            "Minimum Guest should not be greater than Maximum Guest",
          enter_correct_price_for_all_guests:
            "Please enter correct price for all guests!",
          add_atleast_3_images: "Please add at least 3 images",
          add_atleast_1_images: "Please add at least 1 images",
          enter_room_name: "Please enter room name",
          select_bed_type: "Please select bed type",
          select_room_type: "Please select room type",
          fill_all_details: "Please fill all the details",
          enter_price_for: "Please enter the price for",
          enter_correct_prices: "Please enter correct prices",
          minimum_guest_not_less_than_1: "Minimum guest cannot be less than 1!",
          room_count_greater_than_zero: "Room count must be greater than 0",
          please_select_Weekdays_you_want_to_update_inventory_for:
            "Please select Weekdays",
          guest_count_error:
            "Maximum guest count cannot be less than minimum guest count",
          account_number_required: "Account Number is required",
          please_search_your_property: "Please search your property",
          please_select_property_type: "Please select the property type",
          account_holder_name_required: "Account Holder Name is required",
          keep_percentage_between_5_and_95:
            "Please keep the percentage between 5 and 95",
          select_date_range_below_one_month:
            "Please select date range below one month",
          ifsc_code_required: "IFSC Code is required",
          enter_percentage_to_be_charged:
            "Please enter percentage to be charged",
          percentage_should_be_between_0_100:
            "Percentage should be between 0 - 100",
          days: "days",
          percentage_must_be_greater_than:
            "The percentage must be greater than",
          please_enter_name: "Please enter name",
          please_enter_description: "Please enter description",
          please_enter_number_of_days: "Please enter number of days",
          policy_already_exists: "Policy already exists for the given days",
          new_booking_alert: "New Booking Alert",
          youve_got: "You have got",
          new_booking: "New booking",
          reply_updated_successfully: "Reply updated successfully",
          accept_now: "Accept Now",
          image_upload_failed: "Image upload failed",
          designation: "Designation",
          enter_designation: "Enter Designation",
          start_date: "Start Date",
          end_date: "End Date",
          cannot_mark_show_now: "You cannot mark show now",
          discount_range_error:
            "Discount should be greater than 0 and less than 100",
          "Custom Website": "Custom Website",
          add_section: "Add Section",
          section_title: "Section title",
          section_description: "Section Description",
          improve_with_ai: "Improve description with AI",
          select_layout: "Select Layout",
          meta_title: "Meta Title",
          meta_description: "Meta Description",
          enter_meta_title: "Enter Meta Title",
          owner_details: "Owner Details",
          enter_meta_description: "Enter Meta Description",
          enter_analytics_id: "Enter Google Analytics ID",
          user_id: "User ID",
          owned_hotels: "Owned Hotels",
          "Enter valid answer!": "Enter valid answer!",
          no_hotels: "No Hotels",
          no_posts_yet: "No Posts Yet",
          enter_email: "Enter Email",
          "High Intent": "High Interest",
          "Medium Intent": "Medium Interest",
          "Unlock for": "Unlock for",
          "No Leads Found": "No Leads Found",

          AI_Revenue_Manager: "AI Revenue Manager",
          enable_auto_price_match: "Enable auto price match",
          Scrape_Fast: "Scrape Fast",
          Include_OTA: "Include OTA",
          Select_Options: "Select Options",
          Exclude_OTA: "Exclude OTA",
          Min_Reduction_Percentage: "Min Reduction (Percentage)",
          Enter_percentage: "Enter percentage",
          Min_Price: "Min Price",
          Enter_Value: "Enter Value",
          Submit: "Submit",
          Enter_Reason: "Enter Reason",
          please_enter_the_reason_for_blocking_the_rooms_to_continue:
            "Please enter the reason for blocking the room to continue",
          Modified_Date: "Modified Date",
          Created_Date: "Created Date",
          rate_plan: "Rate plan",
          about_you: "About you",
          tax_value_heading: "Tax value",
          reorder: "Reorder",
          name: "Name",
          tax_type: "Tax Type",
          tax_value: "Enter Tax Value in",
          price_from: "Taxable Price Range From (if applicable)",
          price_to: "Taxable Price Range To (if applicable)",
          tax_applicable_level: "Tax Applicable Level",
          action: "Action",
          tax_details: "Tax Details",
          tax_name: "Enter Tax Name",
          tax_name_heading: "Tax name",
          tax_type: "Select Tax Type",
          tax_type_heading: "Tax type",
          tax_applicable_at: "Tax Applicable At",
          current_taxes: "Current Taxes",
          cumulative_percentage: "Cumulative percentage",
          booking: "Booking",
          fixed: "Fixed",
          is_tax_included: "Tax included in all rateplan ?",
          drag_to_reorder: "Drag to reorder",
          tax_inclusion_status_updated_successfully:
            "Tax inclusion status updated successfully",
          failed_to_update_tax_inclusion_status:
            "Failed to update tax inclusion status",
          error_updating_tax_inclusion_status:
            "Error updating tax inclusion status",
          name_cannot_be_empty: "Name cannot be empty",
          please_enter_tax_value: "Please enter tax value",
          to_price_can_not_be_less_than_from_price:
            "to price can not be less than from price",
          tax_saved_successfully: "Tax saved successfully",
          something_went_wrong: "Something went wrong!!",
          tax_deleted_successfully: "Tax deleted successfully",
          somethings_wrong_try_again_later: "Something's wrong try again later",
          cannot_be_less_than_0: "Cannot be less than 0",
          tax_percentage_should_be_between_0_and_100:
            "Tax percentage should be between 0 and 100",
          please_enter_correct_tax_value: "Please enter correct tax value",
          to_price_cannot_be_less_than_from_price:
            "To price cannot be less than from price",
          tax_sorted_successfully: "Tax sorted successfully",
          error_sorting_tax: "Error sorting tax",
          configuration: "Configuration",
          see_how_leads_work: "See how leads work",
          medium_intent: "Medium intent",
          high_intent: "High intent",
          contact_information_will_be_revealed_after_purchase:
            "Contact information will be revealed after purchase",
          charges_for_leads: "Charges for leads",
          of_booking_amount: " of booking amount",
          of_your_lowest_room_rate: " of your lowest room rate",
          contact_information: "Contact information",
          show_lead: "Show lead | ",
          expired: "Expired",
          adults: "Adults",
          adult: "Adult",
          per_night: "Per room night",
          total_booking_amount: "Total booking amount",
          tax_percent: "Enter tax in percentage",
          default_tax_cannot_be_deleted: "Default tax can not be deleted",
          default_tax_cannot_be_edited: "Default tax can not be edited",
          view_bookings: "View bookings",
          please_enter_percentage: "Please enter percentage",
          please_keep_the_percentage_more_than_8_85:
            "Please enter the percentage more than 8.85",
          auto_price_match: "Auto price match",
          min_reduction_type: "Minimum reduction type",
          min_reduction_percent: "Minimum reduction percentage",
          min_reduction_price_in: "Minimum reduction price in ",
          block_room: "Block room",
          hotel_name_cannot_be_empty: "Hotel name can not be empty",
          tax_updated_successfully: "Tax updated successfully",
          you_have_seen_all_leads: "You have seen all the leads",
        },
      },
      de: {
        translation: {
          "No Leads Found": "Keine Leads gefunden",
          "Enter valid question!": "Geben Sie eine gültige Frage ein!",
          "Unlock for": "Entsperren für",
          "Medium Intent": "Mittlere Absicht",
          "High Intent": "Hohe Absicht",
          Home: "Start",
          overview: "Überblick",
          this_week: "Diese Woche",
          "Enter valid answer!": "Geben Sie eine gültige Antwort ein!",
          sales: "Vertrieb",
          Bookings: "Buchungen",
          "Manage Inventory": "Inventar verwalten",
          "Update Property": "Eigenschaft aktualisieren",
          "Payment Settings": "Zahlungseinstellungen",
          Taxes: "Steuern",
          Rooms: "Räume",
          Settings: "Einstellungen",
          "Payment Setting": "Zahlungseinstellungen",
          "Buy Domain": "Domain bestellen",
          "Social Media": "Soziale Medien",
          Reports: "Berichte",
          "Add Logo": "Logo hinzufügen",
          "Property Rules": "Hausordnung",
          "Cancellation Policy": "Storno-Regeln",
          "Booking Preference": "Buchungspräferenz",
          FAQ: "Häufig gestellte Fragen",
          Addons: "Zusatzleistungen",
          "More Bookings": "weitere Buchungen",
          "Google Ads": "Google Anzeigen",
          "Google My Business": "Google Business",
          "Google Post": "Google Artikel",
          "Google Reviews": "Google Bewertungen",
          Profile: "Profil",
          Invoices: "Rechnungen",
          Staff: "Team",
          "Add Property": "Unterkunft hinzufügen",
          Promotion: "Werbeaktionen",
          Leads: "Anfragen",
          this_month: "Diesen Monat",
          lifetime: "Gesamte Zeit",
          website_views: "Webseitenbetrachtungen",
          bookings: "Buchungen",
          reviews_links_sent: "Bewertungslink gesendet",
          share_your_website: "Webseite teilen",
          copy: "Kopieren",
          revenue_manager: "Umsatzmanager",
          great_you_are_offering_the_best_price:
            "Perfect, Sie bieten den besten Preis an:",
          keep_same_price_get_more_profits:
            "Bleiben Sie bei dem Preis um einen besseren Ertrag zu erhalten.",
          attention_needed_you_are_losing_money:
            "Achtung - Sie verlieren Umsätze.",
          loosing_money_without_lowest_price:
            "Sie verlieren Buchungen, wenn der Preis auf Ihrer Webseite zu hoch ist.",
          dont_let_otas_take_booking_profits:
            "Lassen Sie sich die Buchungen nicht von den Plattformen wegschnappen.",
          your_official_site: "Ihre Webseite",
          update_price: "Preis aktualisieren",
          price_last_synced_from_google_on:
            "Preis zuletzt mit Google synchronisiert am:",
          set_up_your_website: "Webseite erstellen",
          complete_profile_for_more_conversions:
            "Perfektionieren Sie Ihr Profil, um mehr direkte Buchungen zu erhalten.",
          add_cancellation_policy: "Stornierungsbedingungen",
          add_staff_receptionist: "Benutzer hinzufügen",
          add_posts: "Beiträge hinzufügen",
          add_profile: "Profil hinzufügen",
          add_website_logo: "Logo hinzufügen",
          set_payment_mode: "Zahlungsart hinzufügen",
          add_your_email: "eMail Adresse hinzufügen",
          add_discounts: "Rabatte hinzufügen",
          add_property_rules: "Hausordnung erstellen",
          dd_faqs: "FAQ erstellen",
          whats_new: "Aktuelles",
          share_your_website: "Webseite teilen",
          copy: "Kopieren",
          create_profile: "Profil erstellen",
          set_up_and_manage_profile_visible_as_about_us: `Den Inhalt für die Rubrik "Über uns" verwalten`,
          about_us: "Über uns",
          your_host: "Gastgeber",
          im_tejaswi_the_heart_behind_the_little_ranch:
            "Hier fügen Sie Ihren individuellen Text ein.",
          share_post: "Beitrag teilen",
          share_post_in_google: "Beitrag auf Google Business teilen",
          google_my_business_profile: "Google Business Profil",
          set_up_and_manage_your_google_profile:
            "Google Business Profil verwalten",
          custom_domain: "Eigene Domain",
          rank_higher_in_google: "In Google besser angezeigt werden",
          out_of_10_pending: "Von 10 ausstehenden",
          go_back: "zurück",
          new: "Neu",
          upcoming: "Nächste",
          completed: "Abgeschlossen",
          cancelled: "Storniert",
          all_bookings: "Alle Buchungen",
          name: "Name",
          booking_id: "Buchungsnummer",
          mobile: "Mobil",
          search: "Suche",
          get_report: "Bericht erhalten",
          hotel_name: "Hotel Name",
          hourly: "Stündlich",
          view_details: "Details anzeigen",
          invoice_raised_no_changes_allowed:
            "Rechnung wurde schon erstellt. Änderungen gesetzlich nicht mehr möglich.",
          accept: "Angenommen",
          reject: "Abgelehnt",
          no_show: "Nicht erschienen",
          checkin: "Anreise",
          cancel: "Storniert",
          checkout: "Abreise",
          rooms: "Zimmer",
          total_amount: "Gesamtbetrag",
          guests: "Gäste",
          payment_mode: "Zahlungsart",
          settlement: "Abrechnung",
          source: "Quelle",
          status: "Status",
          addons: "Extras",
          select_reason: "Grund auswählen",
          proceed: "Weiter",
          cancel: "Abbruch",
          send_new_price_to: "Neuen Preis senden an",
          we_will_notify: "Wir benachrichtigen",
          on_whatsapp_email_and_sms: "per Whatsapp, email und SMS",
          booking_summary: "Zusammenfassung der Buchung",
          total_rooms: "Anzahl Zimmer",
          total_guests: "Anzahl Gäste",
          total_amount: "Gesamtbetrag",
          enter_revised_amount: "Geänderten Betrag eingeben",
          note_new_price_will_be_sent_to_guest:
            "Hinweis: Der neue Preis wird dem Gast zugesandt. Die Buchung wird nicht storniert, es sei denn, der Gast stimmt dem neuen Preis nicht zu. Wenn der Gast mit dem neuen Preis einverstanden ist, wird die Buchung bestätigt.",
          send_updated_price: "aktualisierten Preis senden",
          cannot_cancel_post_checkin_date:
            "Kann nicht nach dem Anreise-Datum storniert werden. Für Buchungsänderungen wenden Sie sich bitte direkt an den Gast unter",
          note: "Hinweis",
          mark_no_show: "Als nicht erschienen markieren",
          price_details: "Preisdetails",
          amount_paid: "Bezahlter Betrag",
          refund_amount: "Erstattunsbetrag",
          do_you_still_want_to: "Möchten Sie immer noch",
          suggested_price: "Vorgeschlagener Preis",
          already_selling_for_suggested_price:
            "Sie verkaufen bereits zu unserem empfohlenen Preis.",
          your_lowest_priced_room_on_date: "Ihr preisgünstigstes Zimmer am",
          on: "am",
          on_your_website: "Auf Ihrer Webseite",
          our_suggested_price: "Unser empfohlener Preis",
          confirm: "Bestätigen",
          enter_custom_price: "Benutzerdefinierten Preis eingeben",
          acceptance_rate: "Akzeptiere den Preis",
          reject_anyway: "Trotzdem ablehnen",
          cancel_anyway: "Trotzdem stornieren",
          call_us: "Bitte rufen Sie uns an",
          call_or_whatsapp_us_at:
            "Bitte rufen Sie uns an oder sende uns eine WhatsApp",
          need_bank_details: "Wir benötigen Ihre Zahlungsdaten",
          please_add_bank_account_before_check_in:
            "Bitte fügen Sie Ihr Bankkonto vor der Anreise hinzu.",
          add_bank_account: "Bankverbindung hinzufügen",
          do_you_want_to_apply_cancellation_policy:
            "Möchten Sie die Stornierungsbedingungen anwenden?",
          yes_apply: "Ja",
          tax_type: "Steuerart",
          quantity: "Anzahl",
          invoice_raised_no_change_allowed:
            "Rechnung ist erstellt, gesetzlich ist keine Änderung mehr erlaubt.",
          email: "eMail",
          adult_count: "Anahl Erwachsene",
          child_count: "Anzahl Kinder",
          infant_count: "Anzahl Kleinkinder",
          awaiting_guest_confirmation: "Auf Bestätigung vom Gast warten",
          guest_confirmed_new_price: "Der Gast hat den neuen Preis bestätigt",
          no_rooms_added: "Keine Zimmer hinzugefügt",
          meals: "Mahlzeiten",
          no_addons_added: "Keine Extras hinzugefügt",
          payment_details: "Zahlungsdetails",
          new_amount: "Neuer Betrag",
          balance_amount: "Saldo",
          commission: "Provision",
          tax: "Mehrwertsteuer",
          ref_no: "Buchungsreferenz",
          settlement_amount: "Abrechnungsbetrag",
          settled_date: "Datum der Zahlung",
          refund_details: "Erstattungsdetails",
          refund_credited_within_5_7_working_days:
            "Der Erstattungsbetrag wird innerhalb von 5-7 Arbeitstagen ab dem Tag der Gutschrift auf dem Konto gutgeschrieben.",
          select_reason: "Grund auswählen",
          do_you_still_want_to: "Möchten Sie dennoch",
          cannot_cancel_post_checkin_date:
            "Eine Stornierung nach dem Anreise-Datum ist nicht möglich, für Buchungsänderungen wenden Sie sich bitte direkt an den Gast unter",
          your_acceptance_rate: "Your Acceptance Rate",
          high: "Hoch",
          settlement_details: "Abrechnungsdetails",
          something_went_wrong: "Es gab einen Fehler",
          no_data_found: "Keine Daten gefunden",
          bulk_edit: "Stapelbearbeitung",
          updated_room_count: "Aktualisierte Anzahl Zimmer",
          updated_price: "Aktualisierter Preis",
          default_room_count_default_price: "Standard Zimmerpreis",
          update: "Aktualisierung",
          price_for: "Preis für",
          room: "Zimmer",
          price: "Preis",
          guest: "Gast",
          timestamp: "Datum/Zeit",
          room_count: "Anzahl Zimmer",
          phone: "Telefon",
          no_datas_associated_with_date_and_room:
            "Keine Daten, die mit diesem Datum und Raum verbunden sind.",
          changed_on: "Geändert am",
          bulk_edit: "Stapelbearbeitung",
          from_date: "von Datum",
          to_date: "bis Datum",
          available_rooms: "Verfügbare Zimmer",
          prev_month: "vorheriger Monat",
          prev_week: "vorherige Woche",
          next_month: "Nächster Monat",
          next_week: "Nächstes Woche",
          update_property_details: "Unternehmensdetails ändern",
          save: "Speichern",
          property_name: "Name der Unterkunft",
          amenities: "Ausstattung",
          stay_type: "Stay Type",
          currency: "Währung",
          reception_number: "Telefon Rezeption",
          gst_in: "UST ID",
          add_email: "eMail hinzufügen",
          property_address: "Adresse",
          property_description: "Beschreibung",
          improve_description: "Beschreibung optimieren",
          generate_description: "Beschreibung vorschlagen lassen",
          powered_by_ai: "durch rainmaker KI",
          select_hotel_amenities: "Ausstattungsdetails auswählen",
          select_stay_type: "Select Stay Type",
          where_upload_images_from: "Von wo möchten Sie Bilder hochladen?",
          contact_us: "Kontakt",
          email_us: "eMail",
          whatsapp_us: "WhatsApp",
          directly_call_us_at: "Rufen Sie uns an unter",
          close: "Schliessen",
          sure_to_change_gmb_number:
            "Sind Sie sicher, dass sie die ID des Google Profils ändern möchten?",
          yes_sure: "Ja",
          create_room: "Zimmer erstellen",
          edit_room: "Zimmer bearbeiten",
          room_name: "Zimmername",
          room_type: "Timmertyp",
          bed_count: "Anzahl Betten",
          room_count: "Anzahl Zimmer",
          bed_type: "Art des Bettes",
          base_price: "Grundpreis",
          edit: "Bearbeiten",
          configure_hourly_rate: "Configure Hourly Rate",
          hourly_rate: "Hourly Rate",
          available: "Verfügbar",
          based_on_todays_full_day_price_of:
            "Basierend auf unserem aktuellen Preis von",
          for: "for",
          edit_price: "Preis bearbeiten",
          minimum_guest_count: "Minimale Anzahl Gäste im Zimmer",
          maximum_guest_count: "Maximale Anzahl Gäste im Zimmer",
          min_guest_count_cannot_be_greater_than_max:
            "Die minimale Gästezahl kann nicht größer sein als die maximale Gästezahl",
          price_details: "Preisdetails",
          bed: "Bett",
          guests_cannot_be_greater_than_price_for:
            "guests can not be greater than price for",
          price_per_child: "Preis pro Kind",
          age_range: "Altersspane",
          to: "an",
          years: "Jahre",
          price_for_infant: "Preis für Kleinkinder",
          price_for_child: "Preis für Kinder",
          to_18_years: "bis zu 18 Jahren",
          food_options: "Mahlzeiten",
          breakfast: "Frühstück",
          included_or_free: "Inkludiert / Kostenlos",
          lunch: "Mittagessen",
          dinner: "Abendessen",
          enter_price_for_breakfast: "Preis für Frühstück eingeben",
          enter_price_for_lunch: "Preis für Mittagessen eingeben",
          enter_price_for_dinner: "Preis für Abendessen eingeben",
          choose_images: "Bilder auswählen",
          add_room: "Zimmer hinzufügen",
          add_your_room_welcome_guests:
            "Fügen Sie Ihr Zimmer hinzu, heißen Sie Ihre Gäste willkommen.",
          last_checkin: "Letzte Anreise",
          add_room_details: "Zimmerdetails hinzufügen",
          add_guest_details: "Gästedetails hinzufügen",
          minimum_number_of_guests_allowed: "Mindestanzahl von Gästen",
          maximum_number_of_guests_allowed: "Maximalanzahl von Gästen",
          incl_of_taxes: "Inklusive Steuern",
          add_price_details: "Preisdetails hinzufügen",
          reduce_by: "Reduzieren um",
          increase_by: "Erhöhen um",
          base_price: "Grundpreis",
          add_children_pricing: "Kinderpreis hinzufügen",
          age: "Alter",
          add_food_details: "Mahlzeiten hinzufügen",
          do_you_provide_food: "Bieten Sie Mahlzeiten an?",
          yes: "Ja",
          no: "Nein",
          included: "Inkludiert",
          select_room_amenities: "Zimmerausstattung auswählen",
          upload_room_images: "Bilder der Zimmer hochladen",
          back: "Zurück",
          next: "Weiter",
          upload: "Hochladen",
          payment_configuration: "Konfiguration Bezahlung",
          online_payment: "Onlinebezahlung",
          your_property_accept_online_payments:
            "Aktivieren Sie diese Option, um vollständig bezahlte Online-Buchungen über UPI-/DEBIT-/KREDITkarten oder Netbanking zu akzeptieren",
          partial_payment: "Teilzahlung",
          reduce_no_shows_by_collecting_percentage:
            "Aktivieren Sie diese Option, um bei der Buchung eine Teilzahlung einzuziehen",
          pay_at_property: "Bei Anreise zu bezahlen",
          your_property_accept_pay_at_property_bookings:
            "Aktivieren Sie dies, um Buchungen mit Bezahlung bei Unterkunft zu akzeptieren",
          bank_details: "Bank Details",
          upi_id: "UPI ID",
          bank_account_details: "Bankverbindung",
          invalid_upi_id: "Invalid UPI ID",
          enter_account_holder_name: "Name des Kontoinhabers eingeben",
          invalid_account_holder_name:
            "Der Name des Kontoinhabers ist nicht korrekt",
          account_holder_name: "Name des Kontoinhabers",
          advanced_percentage: "Geben Sie den Vorschussprozentsatz ein",
          enter_account_number: "IBAN Nummer",
          invalid_account_number: "Die IBAN Nummer ist nicht korrekt",
          account_number: "BIC/SWIFT ID",
          enter_ifsc_code: "BOT IN USE",
          invalid_ifsc_code: "NOT IN USE",
          ifsc_code: "IFSC code",
          save_bank_details: "Bankdaten speichern",
          buy_custom_domain:
            "Verknüpfen oder erstellen Sie Ihre Webseite mit ihrer eigenen Domain",
          includes: "b´Beinhaltet",
          google_search_seo: "Google Suchmaschinenoptimierung",
          website_hosting: "Betriebssystem & Wartung der Webseite",
          free: "Kostenlos",
          ssl_encryption_domain_setup:
            "SSL Verschlüsselung und Domaineinrichtung",
          online_website: "Webseite",
          add_rooms_addons_payments_more:
            "Zimmer, Extras und Zahlungsarten hinzufügen",
          custom_domain: "Kundeneigene Domain",
          your_hotelname_dot_com: "meinedomain.de",
          manage_your_website_access: "Webseitenzugang verwalten",
          android_ios_web: "Apple iOS, Android, Web",
          add_unlimited_rooms: "Unbegrenzte Anzahl Zimmer hinzufügen",
          sales_channel: "Vertriebskanal",
          sell_online_share_social_media:
            "Digital verkaufen und in sozialen Medien teilen",
          google_hotels_integration: "Google Hotel integration",
          official_site_tag_google_profile:
            "Offizielle Website-Markierung in Ihrem Google Profil",
          addons: "Extras",
          sell_value_added_service:
            "Bieten Sie Extras wie Aktivitäten, Erlebnisse, Sehenswürdigkeiten, Fahrrad- oder Autoverleih etc. direkt auf Ihrer Webseite an.",
          chat_with_guests_whatsapp:
            "Kommunizieren Sie mit Ihren Gästen direkt über Whatsapp, um Fragen zu beantworten und mehr direkte Buchungen zu erhalten.",
          whatsapp_chat: "WhatsApp Nachrichten",
          google_reviews: "Google Bewertungen",
          automated_messages_google_reviews:
            "Senden Sie automatische Nachrichten per SMS, eMail oder WhatsApp an abreisende Gäste, um mehr Bewertungen zu erhalten und diese in Ihrem Google Profil zu veröffentlichen.",
          payments: "Zahlungen",
          set_partial_payment_methods:
            "Definieren Sie Ihre Anzahlungsdetails, um Stornierungen und No-Shows zu verhindern.",
          nearby_attractions: "Nahegelegene Erlebnisse",
          showcase_nearby_places:
            "Präsentieren Sie nahegelegene Ausflugsziele in der Nähe.",
          automated_abandoned_guest_recovery:
            "Automatische wiederherstellung von Gästen mit abgebrochenen Buchungsversuchen.",
          automated_whatsapp_messages:
            "Senden Sie automatisierte und personalisierte WhatsApp-Nachrichten an Webseiten-Besucher, um mehr direkte Buchungen zu erhalten.",
          reporting_analytics: "Berichte & Statistiken",
          get_sales_report:
            "Vertriebsbericht im Excel-Format oder Anzeige auf dem Bildschirm.",
          property_logo: "Ihr Logo",
          get_property_logo:
            "Lassen Sie sich ein Logo vom rainmaker Grafikteam erstellen",
          partner_support_24_7: "365/24/7 Partnerunterstützuung",
          language_support:
            "Derzeit unterstützen wir die Sprachen Deutsch und Englisch. Weitere Sprachen werden konrinuierlich hinzugefügt",
          available_domains: "Verfügbare Domains",
          search_for_domain: "Nach einer Domain suchen",
          no_available_domains:
            "Für diesen Namen sind leider keine Domains verfügbar. Versuchen Sie einen anderen Namen.",
          domain_already_purchased:
            "Sie haben diese Domain bereits registriert.",
          special_discount: "Sonderangebot",
          available_at: "Verfügbar bei",
          buy_now_at: "Jetzt Bestellen bei",
          per_day: "pro Nacht",
          payment_successful: "Zahlung erfolgreich",
          web_reflection_time:
            "Die Daten werden in einem Zeitraum von ca. 24h veröffentlicht.",
          number: "Nummer",
          username: "Benutzername",
          reports: "Berichte",
          select_date_range_less_than_one_month:
            "Bitte wählen Sie einen Datumsbereich von weniger als einem Monat.",
          add_logo: "Logo hinzufügen",
          upload_your_logo: "Logo hochladen",
          house_rules: "Hausordnung",
          property_rules: "Eigentumsregeln",
          check_in_time: "Anreisezeit (CheckIn)",
          cancellation_policy: "Stornierungsbedingungen",
          add_cancellation_policy: "Stornierungsbedingung hinzufügen",
          is_charged_for: "wird in Rechnung gestellt",
          before_checkin: " vor Anreise",
          website_with_clear_cancellation_policy_helps:
            "Eine Website mit klaren Stornierungsbedingungen hilft, mehr Buchungen zu erhalten.",
          how_much_of_booking_amount_charged_for_cancellation:
            "Wie viel % des Buchungsbetrags wird dem Gast bei einer Stornierung in Rechnung gestellt?",
          days_before_arrival: "Tage vor Anreise",
          enter_percentage_of_amount_to_deduct:
            "Prozentsatz des berechneten Betrags eingeben",
          enter_number_of_days_before_arrival:
            "Anzahl der Tage vor der Anreise eingeben",
          delete_cancellation_policy: "Stornierungsbedingung löschen",
          booking_preference: "Buchungspräfarenz",
          instant_book: "Sofortbuchung",
          when_this_is_on_bookings_are_accepted_automatically:
            "Wenn diese Funktion aktiviert ist, werden Buchungen automatisch angenommen. Ist sie ausgeschaltet, müssen Sie Buchungsanfragen manuell annehmen oder ablehnen.",
          edit_faq: "FAQ bearbeiten",
          enter_question: "Frage eingeben",
          enter_answer: "Antwort eingeben",
          delete_faq: "FAQ löschen",
          are_you_sure_you_want_to_delete:
            "Send Sie sicher, dass Sie löschen möchten?",
          faqs: "FAQs",
          show_new_faq: "Neue FAQ anzeigen",
          add_faq: "FAQ hinzufügen",
          please_add_faqs_to_increase_bookings:
            "Fügen Sie FAQs hinzu, um mehr direkte Buchungen zu erhalten",
          answer: "Antwort",
          answer_the_question: "Beantworten Sie die Frage",
          answer_and_add_to_faq: "Beantworten und der FAQ hinzufügen",
          add: "Hinzufügen",
          enter_your_own_question: "Geben Sie Ihre eigene Frage ein",
          here_are_some_suggestions: "Hier sind einige Vorschläge",

          select_addon_type: "Kategorie der Extras auswählen ",
          enter_addon_name: "Name des Extras eingeben",
          enter_price: "Preis eingeben",
          addon_name: "Name des Extras",
          addon_price: "Preis des Extras",
          addon_description: "Beschreibung des Extras eingeben",
          upload_addon_images: "Bilder zum Extra hochladen",
          actions: "Aktionen",
          addons: "Extras",
          add_Addon: "Extra hinzufügen",
          no_addons_found: "Keine Extras gefunden",
          Enter_Addon_Details: "Extra Details eingeben",
          Enter_Addon_Name: "Name des Extras eingeben",
          Edit_Addon: "Extra bearbeiten",
          hotel_name: "Name der Unterkunft",
          amount: "Betrag",
          leads: "Neue Kontakte",
          fetching_leads: "Neue Kontakte einlesen",
          no_leads_found: "Keine neuen Kontakte gefunden",
          id: "ID",
          add_staff: "Benutzer hinzufügen",
          no_staffs_found: "Keine Benutzer gefunden",
          phone_number: "Telefonnummer",
          active_invoice: "Aktive Rechnung",
          no_active_invoices: "Keine aktiven Rechnungen",
          past_invoice: "Frühere Rechnungen",
          no_past_invoices: "Keine früheren Rechnungen",
          due_on: "Fällig am",
          overdue: "Überfällig",
          online_prepaid: "Online | Vorausbezahlt",
          pay_now: "JETZT BEZAHLEN",
          invoice: "Rechnung",
          improving_profile:
            "Bitte warten Sie, während wir Ihr Profil optimieren...",
          google_my_business_profile: "Google Business Profil",
          use_hotelzify_to_set_up_and_manage_google_business_profile:
            "rainmaker richtet auch Ihr Google Business Profil ein und optimiert es für Sie.",
          how_does_it_work: "Wie funktioniert das?",
          see_profile_before_after:
            "Sehen Sie, wie Ihr Google Business Profil vor und nach unserer Optimierung aussieht",
          google_my_business_setup: "Google Business Profileinrichtung",
          select_property_gmb:
            "Wir haben diese Fehler in ihrem Google Business gefunden. Wählen Sie aus, um die Fehler zu beheben:",
          duplicate_profile: "Doppeltes Profil",
          verification_required: "Verifizierung erforderlich",
          connect_google_profile: "Mit Google Business verbinden",
          create_gmb_profile: "Neues Google Business Profil erstellen",
          try_another_email: "Bitte verwenden Sie eine andere eMail Adresse",
          someone_else_may_manage_profile:
            "Jemand anderes scheint dieses Google Business Profil zu verwenden.",
          switch_account_message:
            "Wenn diese mit einer anderen eMail-Adresse registriert ist, wechseln Sie unten das Konto.",
          no_access_message:
            "Wenn Sie keinen Zugriff mehr auf das Konto haben,",
          let_us_know: "geben Sie uns bitte Bescheid.",
          request_access_on_google:
            "Oder fordern Sie den Zugang bei dem derzeitigen Kontoinhaber über Google an.",
          request_access: "Zugang über Google anfordern",
          switch_account: "Konto wechseln",
          connect_sections:
            "Wählen Sie die Bereiche aus, die Sie mit Ihrem Google Business-Profil verbinden möchten",
          website_url: "Webseite",
          photos: "Bilder",
          promotions: "Sonderaktionen",
          flat: "Flach",
          off: "Aus",
          strengthen_online_presence:
            "Stärken Sie Ihre Online-Präsenz mit einem ausgefeilten und ansprechenden Google Business-Profil, das die einzigartigen Merkmale und Annehmlichkeiten Ihres Hauses hervorhebt.",
          keep_guests_informed:
            "Halten Sie Ihre potenziellen Gäste mit genauen Angaben zu Ihrem Haus auf dem Laufenden, einschließlich Kontaktinformationen, Adresse, Öffnungszeiten und mehr.",
          high_quality_visual_content: "Hochwertiger visueller Inhalt",
          on_bookings_above: "Bei Buchungen über",
          last_minute_deals: "Last-Minute-Angebote",
          early_bird_offer: "Frühbucher Angebote",
          hotel_addons: "Extras",
          sync_google_profile: "Google Profil Synchronisieren",
          google_business_profile: "Google Business Profile",
          hotelzify_google_partner:
            "rainmaker ist ein offizieller Google Partner.",
          view_agreement: "Vereinbarung ansehen",
          key_benefits_of_the_new_feature:
            "Die wichtigsten Vorteile der neuen Funktion",
          enhanced_digital_presence: "Verbesserte digitale Präsenz",
          accurate_up_to_date_information:
            "Akkurate und aktuelle Informationen",
          verify_profile:
            "Verifizieren Sie Ihr Google Business Profil, um fortzufahren",
          visual_content:
            "Präsentieren Sie Ihr Haus mit fesselnden Bildern, die bei potenziellen Gästen einen bleibenden Eindruck hinterlassen und sie dazu bewegen, sich für Ihr Haus zu entscheiden.",
          verify: "Verifizieren",
          if_different_email:
            "Wenn Sie dieses Konto von einer anderen eMail-Adresse aus verwalten",
          fantastic_job: "Sehr gut",
          google_profile_optimized:
            "Ihr Google Business Profil ist nun für Ihren Erfolg optimiert.",
          note_enhancing_profile:
            "Hinweis: Optimieren Sie Ihr Profil. Dieser Vorgang dauert in der Regel weniger als 2 Stunden.",
          automated_reply: "Automatische antwort",
          auto_reply_feature:
            "Sie können jetzt auf alle Bewertungen, die mit 4 oder mehr Sternen bewertet wurden, mühelos mit unserer neuen Auto-Antwort-Funktion antworten.",
          reviews: "Bewertungen",
          filter_by: "Filtern nach",
          all: "Alle",
          sort_by: "Sortieren nach",
          no_reviews: "Noch keine Bewertungen",
          check_out_to_send_review_links:
            "Senden Sie abreisenden Gäste eine eMail und Fragen Sie nach ihrer Bewertung.",
          check_out_bookings: "Abreisende Gäste",
          previous_reply: "Vorherige Antwort",
          cancel: "Abbrechen",
          confirm: "Bestätigen",
          improved_reply: "Verbesserte antwort",
          replying_to_review: "Auf Bewertung antworten",
          guests_notified_about_reply:
            "Die Gäste werden über Ihre Antwort benachrichtigt. Die Antwort wird auch auf Ihrem Profil öffentlich sichtbar sein",
          submit_response: "Antwort veröffentlichen",
          your_response: "Meine Antwort",
          improve_with_ai: "Mit rainmaker künstlicher Intelligenz optimieren.",
          reply: "Antwort",

          not_found_property: "Der Eintrag konnte nicht gefunden werden.",
          search_your_place: "Suchen Sie Ihr Unternehmen",
          enter_details_for_accuracy:
            "Um genaue Ergebnisse zu erhalten, geben Sie die Region, die Stadt und das Land zusammen mit dem Namen Ihres Hauses ein!",
          enter_address_manually: "Adresse manuell eingeben",
          property_details: "Unternehmensdetails",
          address: "Adresse",
          pincode: "Postleitzahl",
          country: "Land",
          state: "Bundesland",
          enter_mobile_number: "Mobiltelefonnummer eingeben",
          select_stay_type: "Wählen Sie die Art Ihres Aufenthalts",
          select_hotel_amenities: "Ausstattungsdetails auswählen",
          upload_images: "Bilder hochladen",
          logout: "Abmelden",
          property_exists: "Das Haus besteht bereits",
          is_already_registered_by: "ist bereits registriert durch",
          request_staff_access_or_raise_dispute:
            "Sie können den Zugang von Benutzern anmelden oder eine Reklamation einreichen.",
          request_employee_access: "Zugang für Benutzer beantragen",
          raise_a_dispute: "Reklamation einreichen",
          confirm_property_ownership:
            "Bitte bestätigen Sie, ob dieses Unternehmen Ihnen gehört?",
          continue: "Ja, weiter",
          visit_dashboard: "Überblick anzeigen",
          visit_your_website: "Ihre Webseite ansehen",
          congratulations:
            "Herzlichen Glückwunsch! Sie haben Ihre Webseite erfolgreich erstellt",
          enter_property_details:
            "Geben Sie den Namen Ihres Unternehmens, das Bundesland und die Stadt ein",
          check_out_time: "Abreisezeit (Check-out)",
          try_adding_post: "Versuchen Sie jetzt einen Beitrag anzulegen",
          add_new_post: "Neuen Beitrag hinzufügen",
          title: "Titel",
          description: "Inhalt",
          submit: "Absenden",
          posts: "Beiträge",
          profile: "Profil",
          your_name: "Was ist Ihr Name?",
          languages_you_know: "Welche Sprachen sprechen Sie?",
          what_do_you_like: "Was mögen Sie?",
          what_is_your_nature: "Was ist Ihre Eigenschaft?",
          upload_an_image: "Bild hochladen",
          profile_completed: "Profil vervollständigt",
          view_button: "Ansehen",
          relax_message:
            "Lehnen Sie sich zurück und entspannen Sie sich - unsere digitalen Kollegen sind damit beschäftigt, Ihre digitale Präsenz zu erstellen...",

          your_host: "Ihr Gastgeber",
          last_minute_deals_explanation:
            "Last-Minute-Angebote sind eine Möglichkeit, Gästen Sonderpreise anzubieten, wenn das Haus eine hohe Leerstandsquote hat und die Zimmer schnell füllen möchte. Bucht ein Gast beispielsweise zwei Tage vor der Anreise und mehr als die Hälfte der Zimmer sind leer, kann Ihr Haus z.B. einen Nachlass gewähren. Wenn der Last-Minute-Nachlass nur für bestimmte Nächte innerhalb einer Reservierung gilt, wird er nur auf diese Nächte und nicht auf den gesamten Aufenthalt angewandt.",
          generate_description_ai:
            "Beschreibung mit rainmaker künstlicher Intelligenz erstellen",
          languages: "Sprachen",
          nature: "Eigenschaften",
          interests: "Interessen",
          bulk_discounts_explanation:
            "Volumenrabatte sind eine Möglichkeit, Gästen, die lange Zeit oder in einer großen Gruppe bleiben, Rabatte anzubieten. Diese Rabatte können als Prozentsatz der Rechnung festgelegt werden, wenn diese einen bestimmten Betrag überschreitet. Beträgt die Rechnung beispielsweise mehr als € 2.000, können Sie einen Nachlass von z.B. 10 % gewähren.",
          early_bird_discount: "Frühbucher Aktion",
          last_minute_discount: "Last Minute Aktion",
          full_name: "Voller Name",
          bulk_discount: "Volumennachlass",
          fill_all_fields: "Bitte alle Felder ausfüllen",
          keep_one_day_before_checkin:
            "Bitte mindestens 1 Tag vor der Anreise aufbewahren",
          promotions_discounts: "Angebote| Rabatte",
          add_promotion: "Sonderaktion hinzufügen",
          bulk_discount: "Volumennachlass",
          discounts: "Sonderpreise",
          added: "Hinzugefügt",
          day: "Tag",
          days: "Tage",
          before_check_in: "vor dem Einchecken",
          add_your_first_discount: "Ersten Sonderpreis erstellen",
          early_bird_discounts_explanation:
            "Frühbucherrabatte sind eine Möglichkeit, Gäste zu ermutigen, im Voraus zu buchen und zu bezahlen. Wenn ein Gast zum Beispiel mehr als drei Monate im Voraus bucht, kann er einen zusätzlichen Nachlass von z.B. 10 % erhalten.",
          profile_completed_message:
            "Sie haben alle erforderlichen Felder für den Profilbereich ausgefüllt. Klicken Sie auf die Schaltfläche unten, um das Profil anzuzeigen oder zu aktualisieren.",
          days_discount: "Tage | Sonderpreis",
          add_now: "Jetzt hinzufügen",
          bulk_discounts: "Volumenpreise",
          apply: "Anwenden",
          percentage: "Prozent",
          for_total_bill_amount_more_than:
            "Bei einem Rechnungsbetrag von mehr als",
          no_promotions_available:
            "Keine Sonderangebote verfügbar, fügen Sie Sonderangebote hinzu, um den Vertrieb anzukurbeln",
          discount: "Nachlass",
          number_of_days: "Anzahl Tage",
          discount_in: "Nachlass in",
          select_discount: "Nachlass auswählen",
          delete_promotion: "Sonderpreis löschen",
          personal_info: "Persönliche Daten",
          login: "Anmelden",
          welcome_back:
            "Willkommen zurück, bitte melden Sie sich in Ihrem Konto an",
          success: "Erfolgreich",
          logout_confirmation:
            "Sind Sie sicher, dass Sie sich abmelden möchten?",
          send_otp: "PIN senden",
          enter_otp: "PIN eingeben",
          verify_otp: "PIN verifizieren",
          by_signing_in: "Mit Ihrer Anmeldung erklären Sie sich mit den",
          or: "oder",
          terms_and_conditions: "Allgemeinen Geschäftsbedingungen",
          change_language: "Sprache ändern",
          create_property: "Neues Haus erstellen",
          enter_property_name: "Name der Unterkunft eingeben",
          enter_address: "Adresse eingeben",
          select_hotel_type: "Unterkunfts-Typ auswählen",
          please_try_again: "Bitte erneut versuchen",
          no_results_found: "Keine Ergebnisse gefunden",
          names_updated_successfully: "Namen erfolgreich aktualisiert",
          mobile_number_changed_successfully:
            "Mobile Telefonnummer erfolgreich geändert",
          personal_information: "Persönliche Informationen",
          first_name: "Vorname",
          update_email: "eMail Adresse aktualisieren",
          last_name: "Nachname",
          enter_price: "Preis eingeben",
          price_cannot_be_zero: "Der Preis kann nicht 0,00 sein",
          contact_to_change_property_name_or_address:
            "Bitte kontaktieren Sie  den rainmaker Service , wenn Sie den Namen oder die Adresse Ihres Hauses ändern möchten",
          otp_send_successfully: "PIN erfolgreich gesendet",
          you_have_seen_all_the_bookings: "Sie haben alle Buchungen gesehen",
          value_cannot_be_less_than_0:
            "Der Wert kann nicht kleiner als 0 sein.",
          update_inventory_for_at_least_one_room:
            "Bitte aktualisieren Sie die Verfügbarkeit für mindestens ein Zimmer",
          from_date_cannot_be_greater_than_to_date:
            "Das Datum von kann nicht größer sein als das Datum bis",
          from_and_to_date_cannot_be_the_same:
            "Von und Bis-Datum können nicht identisch sein",
          please_select_from_date: `Datum "ab" auswählen`,
          please_select_to_date: `Datum "bis" auswählen`,
          please_enter_valid_phone_number:
            "Bitte eine korrekte Telefonnummer eingeben",
          please_add_at_least_3_images:
            "Bitte mindestens drei Bilder hochladen.",
          invalid_gst_number: "UST ID ist nicht korrekt",
          please_try_again_later: "Bitte versuchen Sie es später noch einmal.",
          price_is_required: "Bitte den Preis eingeben",
          check_in_dates_required:
            "Erste Anreise und letzte Abreise ist erforderlich",
          price_greater_than_zero: "Der Preis muss höher sein als 0,00",
          check_in_dates_order:
            "Der letzte Check-In muss nach dem ersten Check-In erfolgen.",
          price_less_than_base_price: "Preis muss unter dem Grundpreis liegen",
          min_guest_greater_than_max_guest:
            "Die minimale Anzahl Gäste kann nicht größer sein als die maximale Anzahl Gäste",
          enter_correct_price_for_all_guests:
            "Bitte geben Sie den korrekten Preis für alle Gäste ein.",
          add_atleast_3_images: "Bitte mindestens drei Bilder hochladen.",
          add_atleast_1_images: "Bitte mindestens ein Bild hochladen.",
          enter_room_name: "Bitte Name des Zimmers eingeben",
          select_bed_type: "Welchen Typ Bett hat das Zimmer",
          select_room_type: "Welchem Zimmertyp entspricht das Zimmer",
          fill_all_details: "Bitte alle Details eingeben",
          enter_price_for: "Bitte den Preis eingeben für",
          enter_correct_prices: "Bitte den korrekten Preis eingeben",
          minimum_guest_not_less_than_1:
            "Die Mindestanzahl der Gäste muss mindestens 1 betragen.",
          room_count_greater_than_zero:
            "Die Anzahl der Zimmer muss größer als 0 sein",
          guest_count_error:
            "Die maximale Gästezahl darf nicht unter der minimalen Gästezahl liegen.",
          account_number_required: "Kontonummer ist erforderlich",
          please_search_your_property: "Bitte suchen Sie Ihre Unterkunft.",
          please_select_property_type: "Bitte wählen Sie den Unterkunftstyp",
          account_holder_name_required: "Kontoinhaber ist erforderlich",
          keep_percentage_between_5_and_95:
            "Bitte halten Sie den Prozentsatz zwischen 5 und 95",
          select_date_range_below_one_month:
            "Bitte wählen Sie einen Datumsbereich unter einem Monat",
          ifsc_code_required: "IFSC Code is required",
          enter_percentage_to_be_charged:
            "Bitte geben Sie den zu berechnenden Prozentsatz ein",
          percentage_should_be_between_0_100:
            "Der Prozentsatz sollte zwischen 0 und 100 liegen.",
          days: "Tage",
          percentage_must_be_greater_than:
            "Der Prozentsatz muss größer sein als",
          please_enter_name: "Bitte Name eingeben",
          please_enter_description: "Bite Beschreibung eingeben",
          please_enter_number_of_days: "Bitte Anzahl der Tage eingeben",
          policy_already_exists:
            "Für die angegebenen Tage existiert bereits eine Bedingung",
          new_booking_alert: "Neue Buchung eingegangen",
          youve_got: "Sie haben",
          new_booking: "Neue Buchung",
          reply_updated_successfully: "Antwort erfolgreich aktualisiert",
          accept_now: "Jetzt akzeptieren",
          image_upload_failed:
            "Das Laden der Bilder hat einen Fehler verursacht.",
          designation: "Position",
          enter_designation: "Position eingeben",
          start_date: "Startdatum",
          end_date: "Enddatum",
          cannot_mark_show_now: "Sie können jetzt nicht zeigen markieren",
          discount_range_error:
            "Der Nachlass sollte größer als 0 und kleiner als 100 sein.",
          "Custom Website": "Kundeneigene Webseite",
          add_section: "Abschnitt hinzufügen",
          section_title: "Titel des Abschnitts",
          section_description: "Beschreibung des Abschnitts",
          improve_with_ai:
            "Beschreibung mit rainmaker künstlicher Intelligenz optimieren",
          select_layout: "Design auswöhlen",
          meta_title: "SEO Meta Title (55 Zeichen)",
          meta_description: "SEO Meta Beschreibung (160 Zeichen)",
          enter_meta_title: "SEO Meta Titel eingeben",
          owner_details: "Details der Inhaber",
          enter_meta_description: "SEO Meta Beschreibung eingeben",
          enter_analytics_id: "Google Analytics ID eingeben",
          user_id: "Benutzer ID",
          owned_hotels: "Eigene Häuser",
          no_hotels: "Keine Häuser",
          enter_email: "eMail eingeben",
          AI_Revenue_Manager: "AI Revenue Manager",
          Enable_Scraper: "Scraper aktivieren",
          Scrape_Fast: "Kratzen Sie schnell",
          Include_OTA: "OTA einbeziehen",
          Select_Options: "Optionen wählen",
          Exclude_OTA: "OTA ausschließen",
          Min_Reduction_Percentage: "Mindestreduktionsprozentsatz",
          Enter_percentage: "Geben Sie Prozentsatz ein",
          Min_Price: "Mindestpreis",
          Enter_Value: "Geben Sie den Wert ein",
          Submit: "Einreichen",
          Enter_Reason: "Geben Sie den Grund ein",
          please_enter_the_reason_for_blocking_the_rooms_to_continue:
            "Bitte geben Sie den Grund für die Sperrung der Räume ein, um fortzufahren",
          Modified_Date: "Änderungsdatum",
          Created_Date: "Erstellungsdatum",
          rate_plan: "Tarif",
          reorder: "Neu anordnen",
          name: "Name",
          type: "Typ",
          tax_value: "Geben Sie den Steuerwert ein",
          price_from: "Steuerpflichtige Preisspanne von (falls zutreffend)",
          price_to: "steuerpflichtige Preisspanne bis (falls zutreffend)",
          tax_applicable_level: "Steueranwendbare Ebene",
          action: "Aktion",
          tax_details: "Steuerdetails",
          tax_name: "Geben Sie den Steuernamen ein",
          tax_name_heading: "Steuername",
          tax_type: "Steuerart auswählen",
          tax_applicable_at: "Steuer anwendbar bei",
          current_taxes: "Aktuelle Steuern",
          tax_type_heading: "Steuerart",
          tax_value_heading: "Steuerwert",
          tax_percent: "Geben Sie die Steuer in Prozent ein",
          cumulative_percentage: "Kumulativer Prozentsatz",
          booking: "Buchung",
          fixed: "Fest",
          is_tax_included: "Steuer in allen Tarifen enthalten?",
          drag_to_reorder: "Zum Neuordnen ziehen",
          tax_inclusion_status_updated_successfully:
            "Steuerstatus erfolgreich aktualisiert",
          failed_to_update_tax_inclusion_status:
            "Fehler beim Aktualisieren des Steuerstatus",
          error_updating_tax_inclusion_status:
            "Fehler beim Aktualisieren des Steuerstatus",
          name_cannot_be_empty: "Name darf nicht leer sein",
          please_enter_tax_value: "Bitte Steuerwert eingeben",
          to_price_can_not_be_less_than_from_price:
            "Preis bis darf nicht kleiner sein als Preis ab",
          tax_saved_successfully: "Steuer erfolgreich gespeichert",
          something_went_wrong: "Etwas ist schiefgelaufen!!",
          tax_deleted_successfully: "Steuer erfolgreich gelöscht",
          somethings_wrong_try_again_later:
            "Etwas stimmt nicht, bitte versuchen Sie es später erneut",
          cannot_be_less_than_0: "Darf nicht kleiner als 0 sein",
          tax_percentage_should_be_between_0_and_100:
            "Steuerprozentsatz sollte zwischen 0 und 100 liegen",
          please_enter_correct_tax_value:
            "Bitte geben Sie den richtigen Steuerwert ein",
          to_price_cannot_be_less_than_from_price:
            "Preis bis darf nicht kleiner sein als Preis ab",
          tax_sorted_successfully: "Steuer erfolgreich sortiert",
          error_sorting_tax: "Fehler beim Sortieren der Steuer",
          configuration: "Konfiguration",
          see_how_leads_work: "Erfahren Sie, wie Leads funktionieren",
          medium_intent: "Mittleres Interesse",
          high_intent: "Hohes Interesse",
          contact_information_will_be_revealed_after_purchase:
            "Kontaktinformationen werden nach dem Kauf offengelegt",
          charges_for_leads: "Kosten für Leads",
          of_booking_amount: "0,5% _des_Buchungsbetrags",
          contact_information: "Kontaktinformationen",
          show_lead: "Lead anzeigen |",
          expired: "Abgelaufen",
          of_booking_amount: "vom Buchungsbetrag",
          of_your_lowest_room_rate: "von Ihrem niedrigsten Zimmerpreis",
          adults: "Erwachsene",
          adult: "Erwachsener",
          taxes: "Steuern",
          view_bookings: "Buchungen ansehen",
          per_night: "pro Zimmernacht",
          total_booking_amount: "Gesamtbuchungsbetrag",
          default_tax_cannot_be_deleted:
            "Die Standardsteuer kann nicht gelöscht werden",
          default_tax_cannot_be_edited:
            "Die Standardsteuer kann nicht bearbeitet werden",
          please_enter_percentage: "Bitte geben Sie den Prozentsatz ein",
          please_keep_the_percentage_more_than_8_85:
            "Bitte geben Sie einen Prozentsatz über 8,85 ein",
          auto_price_match: "Autopreisanpassung",
          min_reduction_type: "Mindestreduzierungstyp",
          min_reduction_percent: "Minimaler Reduzierungsprozentsatz",
          min_reduction_price_in: "Mindestermäßigungspreis in ",
          Enable_auto_price_match:
            "Aktivieren Sie die automatische Preisanpassung",
          block_room: "Blockraum",
          hotel_name_cannot_be_empty: "Der Hotelname darf nicht leer sein",
          tax_updated_successfully: "Steuer erfolgreich aktualisiert",
          you_have_seen_all_leads: "Sie haben alle Hinweise gesehen",
        },
      },
      ar: {
        translation: {
          "Enter valid question!": "أدخل سؤالا صالحا!",
          "Manage Inventory": "إدارة المخزون",
          "Update Property": "تحديث الفندق",
          "Payment Settings": "إعدادات الدفع",
          Settings: "إعدادات",
          "Enter valid answer!": "أدخل إجابة صحيحة!",
          "Payment Setting": "إعداد الدفع",
          "Buy Domain": "شراء المجال",
          Taxes: "الضرائب",
          "Social Media": "وسائل التواصل الاجتماعي",
          "Add Logo": "إضافة شعار",
          "Property Rules": "قواعد الملكية",
          "Cancellation Policy": "سياسة الإلغاء",
          "Booking Preference": "تفضيل الحجز",
          FAQ: "الأسئلة الشائعة",
          Addons: "الإضافات",
          "More Bookings": "المزيد من الحجوزات",
          "Google Ads": "إعلانات جوجل",
          "Google My Business": "جوجل أعمالي",
          "Google Post": "مشاركة جوجل",
          "Google Reviews": "تقييمات جوجل",
          Profile: "حساب تعريفي",
          Invoices: "الفواتير",
          Staff: "طاقم عمل",
          "Add Property": "إضافة فندق",
          Promotion: "ترقية",
          Leads: "العملاء المحتملون",
          this_month: "هذا الشهر",
          lifetime: "حياة",
          website_views: "مشاهدات الموقع",
          Home: "الرئيسية",
          overview: "نظرة عامة",
          this_week: "هذا الأسبوع",
          sales: "المبيعات",
          this_month: "هذا الشهر",
          lifetime: "مدى الحياة",
          website_views: "زيارات الموقع",
          bookings: "الحجوزات",
          Bookings: "الحجوزات",
          reviews_links_sent: "روابط التقييمات المرسلة",
          share_your_website: "شارك موقعك",
          copy: "نسخ",
          revenue_manager: "مدير الإيرادات",
          great_you_are_offering_the_best_price: "رائع! نحن نقدم أفضل سعر",
          keep_same_price_get_more_profits:
            "حافظ على نفس السعر لتحقيق المزيد من الأرباح",
          attention_needed_you_are_losing_money:
            "يتطلب اهتمامًا، أنت تفقد المال!",
          loosing_money_without_lowest_price:
            "سوف تفقد الأموال إذا لم يقدم موقعك أدنى سعر",
          dont_let_otas_take_booking_profits:
            "لا تسمح لمنصات الحجوزات عبر الإنترنت بأخذ حجوزاتك وأرباحك",
          your_official_site: "موقعك الرسمي",
          update_price: "تحديث السعر",
          price_last_synced_from_google_on: "تم تحديث السعر آخر مرة من جوجل في",
          set_up_your_website: "قم بإعداد موقعك الإلكتروني",
          complete_profile_for_more_conversions:
            "أكمل ملفك الشخصي لزيادة التحويلات",
          add_cancellation_policy: "إضافة سياسة الإلغاء",
          add_staff_receptionist: "إضافة موظف / موظف استقبال",
          add_posts: "إضافة مشاركات",
          add_profile: "إضافة ملف شخصي",
          add_website_logo: "إضافة شعار الموقع",
          set_payment_mode: "تعيين طريقة الدفع",
          add_your_email: "أضف بريدك الإلكتروني",
          add_discounts: "إضافة خصومات",
          add_property_rules: "إضافة قواعد الفندق",
          dd_faqs: "إضافة الأسئلة الشائعة",
          whats_new: "ما الجديد؟",
          create_profile: "إنشاء ملف شخصي",
          set_up_and_manage_profile_visible_as_about_us:
            'إعداد وإدارة الملف الشخصي الذي سيكون مرئيًا تحت قسم "معلومات عنا',
          about_us: "معلومات عنا",
          your_host: "ُضيفك",
          im_tejaswi_the_heart_behind_the_little_ranch:
            'أنا تيجاسوي، القلب النابض وراء "المزرعة الصغيرة". هدفي هو أن أقدم لك تجربة تجوال حقيقية. من خلال التزامنا بالاستدامة، تدعم إقامتك غدًا أخضر. احجز مباشرة معنا للحصول على أفضل الخدمات والعروض!',
          share_post: "مشاركة المنشور",
          share_post_in_google: "مشاركة المنشور على جوجل",
          google_my_business_profile: "الملف التجاري على جوجل",
          set_up_and_manage_your_google_profile: "إعداد وإدارة ملفك على جوجل",
          custom_domain: "domain خاص",
          rank_higher_in_google: "التصدر في نتائج بحث جوجل",
          out_of_10_pending: "من 10، قيد الانتظار",
          go_back: "العودة",
          new: "جديد",
          upcoming: "القادمة",
          completed: "مكتمل",
          cancelled: "ملغى",
          all_bookings: "جميع الحجوزات",
          name: "الاسم",
          booking_id: "رقم الحجز",
          mobile: "الجوال",
          search: "بحث",
          get_report: "الحصول على تقرير",
          hotel_name: "اسم الفندق",
          hourly: "بالساعة",
          view_details: "عرض التفاصيل",
          invoice_raised_no_changes_allowed:
            "تم إصدار الفاتورة، لا يُسمح بالتغييرات.",
          accept: "قبول",
          reject: "رفض",
          no_show: "عدم الحضور",
          checkin: "تسجيل الوصول",
          cancel: "إلغاء",
          checkout: "الخروج",
          rooms: "الغرف",
          Rooms: "الغرف",
          total_amount: "المبلغ الإجمالي",
          guests: "الضيوف",
          payment_mode: "طريقة الدفع",
          settlement: "تسوية",
          source: "مصدر",
          status: "الحالة",
          addons: "الإضافات",
          select_reason: "اختر السبب",
          proceed: "متابعة",
          send_new_price_to: "أرسل السعر الجديد إلى",
          we_will_notify: "سنقوم بإخطار",
          on_whatsapp_email_and_sms:
            "عبر الواتساب، البريد الإلكتروني، والرسائل القصيرة",
          booking_summary: "ملخص الحجز",
          total_rooms: "إجمالي الغرف",
          total_guests: "إجمالي الضيوف",
          enter_revised_amount: "أدخل المبلغ المعدل",
          note_new_price_will_be_sent_to_guest:
            "ملاحظة: سيتم إرسال السعر الجديد إلى الضيف. لن يتم إلغاء الحجز ما لم يقول الضيف لا للسعر الجديد. إذا وافق الضيف على السعر الجديد، سيتم تأكيد الحجز.",
          send_updated_price: "إرسال السعر المحدث",
          cannot_cancel_post_checkin_date:
            "لا يمكن إلغاء التاريخ بعد تسجيل الوصول. لأي تغيير في الحجز، يرجى التواصل مباشرة مع الضيف على",
          note: "ملاحظة",
          mark_no_show: "وضع علامة على عدم الحضور",
          price_details: "تفاصيل السعر",
          amount_paid: "المبلغ المدفوع",
          refund_amount: "مبلغ الاسترداد",
          do_you_still_want_to: "هل مازلت ترغب في",
          suggested_price: "السعر المقترح",
          already_selling_for_suggested_price:
            "أنت تبيعه بالفعل بالسعر المقترح من قبلنا.",
          your_lowest_priced_room_on_date: "أقل غرفة سعرًا متوفرة في [التاريخ]",
          on: "على",
          on_your_website: "على موقعك الإلكتروني",
          our_suggested_price: "سعرنا المقترح",
          confirm: "تأكيد",
          enter_custom_price: "أدخل السعر",
          acceptance_rate: "السعر مقبول",
          reject_anyway: "رفض على أي حال",
          cancel_anyway: "إلغاء على أي حال",
          call_us: "اتصل بنا",
          call_or_whatsapp_us_at: "اتصل أو أرسل رسالة لنا عبر واتساب",
          need_bank_details: "بحاجة إلى تفاصيل البنك",
          please_add_bank_account_before_check_in:
            "يرجى إضافة حسابك البنكي قبل تسجيل الوصول.",
          add_bank_account: "إضافة حساب بنكي",
          do_you_want_to_apply_cancellation_policy:
            "هل ترغب في تطبيق سياسة الإلغاء؟",
          yes_apply: "نعم، تطبيق",
          type: "النوع",
          quantity: "الكمية",
          invoice_raised_no_change_allowed:
            "تم إصدار الفاتورة، لا يُسمح بالتغيير.",
          email: "البريد الإلكتروني",
          adult_count: "عدد البالغين",
          child_count: "عدد الأطفال",
          infant_count: "عدد الرضّع",
          awaiting_guest_confirmation: "في انتظار تأكيد الضيف",
          guest_confirmed_new_price: "أكد الضيف السعر الجديد",
          no_rooms_added: "لم تتم إضافة غرف",
          meals: "وجبات",
          no_addons_added: "لم تتم إضافة إضافات",
          payment_details: "تفاصيل الدفع",
          new_amount: "المبلغ الجديد",
          balance_amount: "المبلغ المتبقي",
          commission: "العمولة",
          tax: "الضريبة",
          ref_no: "الرقم المرجعي",
          settlement_amount: "المبلغ المستحق للتسوية",
          settled_date: "تاريخ التسوية",
          refund_details: "تفاصيل الاسترداد",
          refund_credited_within_5_7_working_days:
            "سيتم تحويل مبلغ الاسترداد إلى الحساب خلال 5-7 أيام عمل، بدءًا من",
          your_acceptance_rate: "السعر المقبول",
          high: "مرتفع",
          settlement_details: "تفاصيل التسوية",
          something_went_wrong: "حدث خطأ ما",
          no_data_found: "لم يتم العثور على بيانات",
          bulk_edit: "تعديل",
          updated_room_count: "عدد الغرف المُحدّث",
          updated_price: "السعر المُحدّث",
          default_room_count_default_price:
            "عدد الغرف الافتراضي / السعر الافتراضي",
          update: "تحديث",
          price_for: "السعر لـ",
          room: "الغرفة",
          price: "السعر",
          guest: "الضيف",
          timestamp: "الطابع الزمني",
          room_count: "عدد الغرف",
          phone: "الهاتف",
          no_datas_associated_with_date_and_room:
            "ا توجد بيانات مرتبطة بهذا التاريخ والغرفة",
          changed_on: "تم التغيير في",
          from_date: "من تاريخ",
          to_date: "ِلى تاريخ",
          available_rooms: "الغرف المتوفرة",
          prev_month: "الشهر السابق",
          prev_week: "الاسبوع السابق",
          next_month: "الشهر التالي",
          next_week: "الاسبوع التالي",
          update_property_details: "تحديث بيانات الفندق",
          save: "حفظ",
          property_name: "اسم الفندق",
          amenities: "المرافق",
          stay_type: "نوع الإقامة",
          currency: "العملة",
          reception_number: "رقم الاستقبال",
          gst_in: "رقم التعريف الضريبي للسلع والخدمات",
          add_email: "إضافة البريد الإلكتروني",
          property_address: "عنوان الفندق",
          property_description: "وصف الفندق",
          improve_description: "تحسين الوصف",
          generate_description: "إنشاء وصف",
          powered_by_ai: "مدعوم بالذكاء الاصطناعي",
          select_hotel_amenities: "اختيار مرافق الفندق",
          select_stay_type: "اختر نوع إقامتك",
          where_upload_images_from: "من أين تود تحميل الصور؟",
          contact_us: "اتصل بنا",
          email_us: "أرسل لنا بريدًا إلكترونيًا",
          whatsapp_us: "تواصل معنا عبر الواتساب",
          directly_call_us_at: "يمكنك الاتصال بنا مباشرة على",
          close: "إغلاق",
          sure_to_change_gmb_number:
            "هل أنت متأكد من تغيير رقم الملف التجاري على جوجل ؟",
          yes_sure: "نعم، بالتأكيد",
          create_room: "إنشاء غرفة",
          edit_room: "تعديل غرفة",
          room_name: "اسم الغرفة",
          room_type: "نوع الغرفة",
          bed_count: "عدد الأسرة",
          bed_type: "نوع السرير",
          base_price: "السعر الأساسي",
          edit: "تعديل",
          configure_hourly_rate: "ضبط السعر بالساعة",
          hourly_rate: "سعر الساعة",
          available: "متوفر",
          based_on_todays_full_day_price_of: "بناء على السعر اليومى كاملا ل",
          for: "ل",
          edit_price: "تعديل السعر",
          minimum_guest_count: "الحد الأدنى لعدد الضيوف",
          maximum_guest_count: "الحد الأقصى لعدد الضيوف",
          min_guest_count_cannot_be_greater_than_max:
            "لا يمكن أن يكون الحد الأدنى لعدد الضيوف أكبر من الحد الأقصى لعدد الضيوف",
          bed: "سرير",
          guests_cannot_be_greater_than_price_for:
            "لا يمكن أن يكون عدد الضيوف أكبر من السعر",
          price_per_child: "السعر لكل طفل",
          age_range: "الفئة العمرية",
          to: "إلى",
          years: "سنوات",
          price_for_infant: "سعر الرضيع",
          price_for_child: "سعر الطفل",
          to_18_years: "حتى 18 سنة",
          food_options: "خيارات الطعام",
          breakfast: " الإفطار",
          included_or_free: "مشمول / مجاني",
          lunch: "الغذاء",
          dinner: "العشاء",
          enter_price_for_breakfast: "أدخل سعر وجبة الإفطار",
          enter_price_for_lunch: "ادخل سعر وجبة الغداء",
          enter_price_for_dinner: "أدخل سعر وجبة العشاء",
          choose_images: "اختر الصور",
          add_room: "إضافة غرفة",
          add_your_room_welcome_guests: "أضف غرفتك واستقبل ضيوفك بكل ترحاب!",
          last_checkin: "آخر تسجيل دخول",
          add_room_details: "إضافة تفاصيل الغرفة",
          add_guest_details: "إضافة تفاصيل الضيف",
          minimum_number_of_guests_allowed:
            "الحد الأدنى لعدد الضيوف المسموح به",
          maximum_number_of_guests_allowed:
            "الحد الأقصى لعدد الضيوف المسموح به",
          incl_of_taxes: "شامل الضريبة",
          add_price_details: "إضافة تفاصيل السعر",
          reduce_by: "تخفيض عن طريق",
          increase_by: "زيادة عن طريق",
          add_children_pricing: "إضافة تسعير للأطفال",
          age: "العمر",
          add_food_details: "اضافة تفاصيل الوجبات",
          do_you_provide_food: "هل توفرون خدمة تقديم الطعام؟",
          yes: "نعم",
          no: "لا",
          included: "يشمل",
          select_room_amenities: "اختيار مرافق الغرف",
          upload_room_images: "رفع صور الغرفة",
          back: "عودة",
          next: "التالي",
          upload: "رفع",
          payment_configuration: "إعدادات الدفع",
          online_payment: "الدفع عبر الإنترنت",
          your_property_accept_online_payments:
            "قم بتمكين هذا لقبول الحجوزات المدفوعة بالكامل عبر الإنترنت عبر بطاقات UPI/DEBIT/CREDIT أو Netbanking",
          partial_payment: "دفع جزئي",
          reduce_no_shows_by_collecting_percentage:
            "قم بتمكين هذا لتحصيل الدفع الجزئي أثناء الحجز",
          pay_at_property: "الدفع عند الوصول",
          your_property_accept_pay_at_property_bookings:
            "قم بتمكين هذا لقبول الدفع في حجوزات الفندق",
          bank_details: "تفاصيل البنك",
          upi_id: "رقم UPI",
          bank_account_details: "تفاصيل الحساب البنكى",
          invalid_upi_id: "رقم UPI غير صحيح",
          enter_account_holder_name: "أدخل اسم صاحب الحساب",
          invalid_account_holder_name: "اسم صاحب الحساب غير صحيح",
          account_holder_name: "اسم صاحب الحساب",
          enter_account_number: "أدخل رقم الحساب",
          invalid_account_number: "رقم الحساب غير صحيح",
          advanced_percentage: "أدخل نسبة التقدم",
          account_number: "رقم الحساب",
          enter_ifsc_code: "أدخل رمز IFSC",
          invalid_ifsc_code: "رمز IFSC غير صحيح",
          ifsc_code: "رمز IFSC ( النظام المالي الهندي )",
          save_bank_details: "حفظ تفاصيل البنك",
          buy_custom_domain:
            "اشترِ domain المخصص لإنشاء علامة تجارية وتحسين بحث العقارات الخاصة بك",
          includes: "يشمل",
          google_search_seo: "تحسين محركات البحث في جوجل",
          website_hosting: "استضافة الموقع الإلكتروني",
          free: "مجاني",
          ssl_encryption_domain_setup: "تشفير SSL وإعداد domain",
          online_website: "موقع إلكتروني عبر الإنترنت",
          add_rooms_addons_payments_more: "إضافة غرف، إضافات، مدفوعات والمزيد",
          your_hotelname_dot_com: "اسم الفندق.com",
          manage_your_website_access: "إدارة موقعك الإلكتروني (الوصول)",
          android_ios_web: "أندرويد + آي أو إس + ويب",
          add_unlimited_rooms: "إضافة غرف غير محدودة",
          sales_channel: "قناة المبيعات",
          sell_online_share_social_media:
            "بيع عبر الإنترنت وشارك على وسائل التواصل الاجتماعي",
          google_hotels_integration: "دمج فنادق جوجل",
          official_site_tag_google_profile:
            "العلامة الرسمية للموقع على ملف جوجل",
          sell_value_added_service:
            "بيع خدمات مضافة مثل الجولات السياحية الموجهة، تأجير السيارات، سفاري بالجيب، السبا وغيرها مباشرة من موقع الويب الخاص بك",
          chat_with_guests_whatsapp:
            "تفاعل مع ضيوفك مباشرة عبر واتساب للرد على الاستفسارات وتحويل الزوار إلى حجوزات.",
          whatsapp_chat: "محادثة واتساب",
          google_reviews: "تقييمات جوجل",
          automated_messages_google_reviews:
            "أرسل رسائل آلية عبر رسائل نصية قصيرة، البريد الإلكتروني، وواتساب للضيوف الذين قاموا بالتسجيل للحصول على تقييمات جوجل.",
          payments: "المدفوعات",
          set_partial_payment_methods:
            "تعيين طرق دفع جزئية لتقليل حالات عدم الحضور.",
          nearby_attractions: "المعالم السياحية القريبة",
          showcase_nearby_places:
            "عرض الأماكن القريبة لزيارتها بالقرب من ممتلكاتك وجذب المزيد من الضيوف.",
          automated_abandoned_guest_recovery:
            "استعادة النزلاء المتروكة تلقائيًا",
          automated_whatsapp_messages:
            "أرسل رسائل واتساب شخصية آلية إلى زوار الموقع وزد من حجوزاتك",
          reporting_analytics: "التقارير والتحليلات",
          get_sales_report:
            "احصل على تقرير مبيعات بتنسيق Excel وعدد مشاهدات الموقع",
          property_logo: "شعار الفندق",
          get_property_logo:
            "احصل على شعار للفندق الخاص بك من فريق Hotelzify لموقعك الإلكتروني",
          partner_support_24_7: "دعم الفريق على مدار الساعة طوال أيام الأسبوع",
          language_support:
            "مع دعم للغات الهندية، الكنّادا، التاميل، الماليالامية، الإنجليزية، والعربية",
          available_domains: "توفير اسم domains لك",
          search_for_domain: "البحث عن domain",
          no_available_domains: "عذرًا! لا يتوفر domain . جرب اسمًا مختلفًا",
          domain_already_purchased: "domain مشترى بالفعل.",
          special_discount: "خصم خاص",
          available_at: "متوفر في",
          buy_now_at: "اشتر الآن من",
          per_day: "في اليوم",
          payment_successful: "تم الدفع بنجاح",
          web_reflection_time:
            "من المتوقع أن يستغرق وقت الانعكاس على الويب حوالي 48 ساعة. شكرًا على صبرك!",
          social_media: "وسائل التواصل الاجتماعي",
          number: "رقم",
          username: "اسم المستخدم",
          reports: "التقارير",
          Reports: "التقارير",
          select_date_range_less_than_one_month:
            "يرجى تحديد نطاق تاريخي يقل عن شهر واحد.",
          add_logo: "إضافة شعار",
          upload_your_logo: "قم بتحميل شعارك",
          house_rules: "قواعد الفندق",
          property_rules: "قواعد الملكية",
          check_in_time: "وقت الوصول",
          cancellation_policy: "سياسة الإلغاء",
          is_charged_for: "اتهم ل",
          before_checkin: "قبل تسجيل الوصول",
          website_with_clear_cancellation_policy_helps:
            "الموقع الإلكتروني الذي يحتوي على سياسة إلغاء واضحة يساعد على زيادة الحجوزات",
          how_much_of_booking_amount_charged_for_cancellation:
            "كم النسبة من مبلغ الحجز يتم تحصيلها من الضيف في حالة الإلغاء؟",
          days_before_arrival: "الأيام قبل الوصول",
          enter_percentage_of_amount_to_deduct:
            "أدخل نسبة المبلغ المطلوب خصمها",
          enter_number_of_days_before_arrival: "أدخل عدد الأيام قبل الوصول",
          delete_cancellation_policy: "حذف سياسة الإلغاء",
          booking_preference: "تفضيلات الحجز",
          instant_book: "الحجز الفوري",
          when_this_is_on_bookings_are_accepted_automatically:
            "عندما يكون هذا الخيار مفعلًا، يتم قبول الحجوزات تلقائيًا. عندما يكون مغلقًا، ستحتاج إلى قبول أو رفض طلبات الحجز يدويًا",
          edit_faq: "تعديل الأسئلة الشائعة",
          enter_question: "أدخل السؤال",
          enter_answer: "أدخل الإجابة",
          delete_faq: "حذف الأسئلة الشائعة",
          are_you_sure_you_want_to_delete: "هل أنت متأكد من رغبتك في الحذف؟",
          faqs: "الأسئلة الشائعة",
          show_new_faq: "عرض الأسئلة الشائعة الجديدة",
          add_faq: "إضافة سؤال شائع",
          please_add_faqs_to_increase_bookings:
            "يرجى إضافة الأسئلة الشائعة لزيادة عدد الحجوزات",
          answer: "الجواب",
          answer_the_question: "اجابة السؤال",
          answer_and_add_to_faq: "الإجابة وإضافتها إلى الأسئلة الشائعة",
          add: "إضافة",
          enter_your_own_question: "أدخل سؤالك الخاص",
          here_are_some_suggestions: "إليك بعض الاقتراحات",
          select_addon_type: "اختر نوع الإضافة",
          enter_addon_name: "أدخل اسم الإضافة",
          enter_price: "الرجاء إدخال السعر",
          addon_name: "اسم الإضافة",
          addon_price: "سعر الإضافة",
          addon_description: "أدخل وصف الإضافة",
          upload_addon_images: "تحميل صور الإضافة",
          actions: "إجراءات",
          add_Addon: "إضافة إضافة جديدة",
          no_addons_found: "ا توجد إضافات",
          Enter_Addon_Details: "أدخل تفاصيل الإضافة",
          Enter_Addon_Name: "ادخل اسم الاضافة",
          Edit_Addon: "تعديل الاضافة",
          amount: "المبلغ",
          leads: "العملاء المحتملون",
          fetching_leads: "جلب العملاء المحتملين",
          no_leads_found: "لم يتم العثور على عملاء محتملين",
          id: "الرقم التعريفي",
          add_staff: "إضافة موظف",
          no_staffs_found: "لم يتم العثور على موظفين",
          phone_number: "رقم الهاتف",
          active_invoice: "الفاتورة الحالية",
          no_active_invoices: "لا توجد فواتير حالية",
          past_invoice: "الفاتورة السابقة",
          no_past_invoices: "ا توجد فواتير سابقة",
          due_on: "المستحق في",
          overdue: "مستحق",
          online_prepaid: "عبر الإنترنت / مدفوع مسبقاً",
          pay_now: "ادفع الآن",
          invoice: "فاتورة",
          improving_profile: "رجى الانتظار بينما نحسن ملفك الشخصي...",
          use_hotelzify_to_set_up_and_manage_google_business_profile:
            "استخدم Hotelzify لإعداد وإدارة ملفك التجاري على Google",
          how_does_it_work: "كيف يعمل؟",
          see_profile_before_after:
            "انظر كيف يبدو ملفك الشخصي قبل وبعد استخدام Google My Business",
          google_my_business_setup: "Google My Business إعداد مل  ",
          select_property_gmb:
            "وجدنا هذه الخصائص المرتبطة بحسابك. الرجاء اختيار واحدة",
          duplicate_profile: "ملف شخصي مكرر",
          verification_required: "يتطلب التحقق",
          connect_google_profile: "ربط ملفك الشخصي على جوجل",
          create_gmb_profile: "إنشاء ملف أعمالي على جوجل",
          try_another_email: "هل ترغب في تجربة بريد إلكتروني آخر؟",
          someone_else_may_manage_profile: "قد يدير شخص آخر هذا الملف على جوجل",
          switch_account_message:
            "إذا كان مُسجَّلًا باستخدام بريد إلكتروني مختلف، قم بالتبديل بين الحسابات أدناه",
          no_access_message: "إذا لم تعد لديك امكانية وصول إلى الحساب",
          let_us_know: "أخبرنا",
          request_access_on_google:
            "أو اطلب الوصول من المدير الحالي للعمل على جوجل",
          request_access: "طلب الوصول",
          switch_account: "تبديل الحساب",
          connect_sections:
            "اختر الأقسام التي ترغب في ربطها على ملف أعمالك على جوجل",
          website_url: "رابط الموقع الالكتروني",
          photos: "الصور",
          promotions: "العروض",
          flat: "شقة",
          off: "غير متصل",
          strengthen_online_presence:
            "قوِّي وجودك على الإنترنت باستخدام ملف أعمال جوجل متميز وجذاب يبرز ميزات ومرافق فندقك بشكل فريد",
          keep_guests_informed:
            "حافظ على إبقاء ضيوفك المحتملين على علم بالتفاصيل الدقيقة حول فندقك، بما في ذلك معلومات الاتصال والعنوان وساعات العمل وغيرها",
          high_quality_visual_content: "محتوى بصري عالي الجودة",
          on_bookings_above: "على الحجوزات التي تزيد عن",
          last_minute_deals: "عروض اللحظة الأخيرة",
          early_bird_offer: "عرض الحجز المبكر",
          hotel_addons: "إضافات الفندق",
          sync_google_profile: "مزامنة ملفك الشخصي على جوجل",
          google_business_profile: "ملف أعمال جوجل",
          hotelzify_google_partner: "Hotezify هو شريك رسمي مع جوجل!",
          view_agreement: "عرض الاتفاقية",
          key_benefits_of_the_new_feature: "الفوائد الرئيسية للميزة الجديدة",
          enhanced_digital_presence: "تعزيز الوجود الرقمي",
          accurate_up_to_date_information: "معلومات دقيقة ومحدّثة",
          verify_profile: "قم بالتحقق من ملفك الشخصي على جوجل للمتابعة",
          visual_content:
            "عرض الفندق بصور مبهرة تترك انطباعاً دائماً على الضيوف المحتملين، مما يجعلهم يختارون فندقك",
          verify: "التحقق",
          if_different_email:
            "إذا كنت تدير هذا الحساب من عنوان بريد إلكتروني مختلف",
          fantastic_job: "عمل رائع",
          google_profile_optimized:
            "تم تحسين ملفك الشخصي على جوجل لتحقيق النجاح",
          note_enhancing_profile: "ملاحظة: تحسين ملفك الشخصي",
          automated_reply: "الرد التلقائي",
          auto_reply_feature:
            "يمكنك الآن الرد على جميع التقييمات التي حصلت على 4 نجوم أو أكثر بسهولة باستخدام ميزة الرد التلقائي الجديدة لدينا",
          reviews: "التقييمات",
          filter_by: "تصفية حسب",
          all: "الكل",
          sort_by: "ترتيب حسب",
          no_reviews: "لا توجد تقييمات بعد!",
          check_out_to_send_review_links:
            "تحقق من حجوزات الخروج لإرسال روابط تقييم جوجل للضيوف",
          check_out_bookings: "حجوزات تسجيل الخروج",
          previous_reply: "الرد السابق",
          improved_reply: "الرد المطوّر",
          replying_to_review: "الرد على التقييم",
          guests_notified_about_reply:
            "سيتم إخطار الضيوف بردك، وسيكون مرئيًا علنيًا على ملف عملك",
          submit_response: "إرسال الرد",
          your_response: "ردك",
          improve_with_ai: "تحسين الوصف باستخدام الذكاء الاصطناعي",
          reply: "الرد",
          not_found_property: "لم تتمكن من العثور على الفندق؟",
          search_your_place: "البحث عن موقعك",
          enter_details_for_accuracy:
            "للحصول على نتائج دقيقة، أدخل المنطقة، والمدينة، والبلد إلى جانب اسم الممتلكات",
          enter_address_manually: "أدخل العنوان يدويًا",
          property_details: "تفاصيل الفندق",
          address: "العنوان",
          pincode: "الرمز السري",
          country: "البلد",
          state: "الولاية",
          enter_mobile_number: "أدخل رقم الجوال",
          upload_images: "تحميل الصور",
          logout: "تسجيل خروج",
          property_exists: "الفنادق الموجودة",
          is_already_registered_by: "مُسجل بالفعل بواسطة",
          request_staff_access_or_raise_dispute:
            "يمكنك طلب الوصول كموظف أو رفع نزاع من الأسفل",
          request_employee_access: "طلب الوصول كموظف",
          raise_a_dispute: "رفع نزاع",
          confirm_property_ownership: "يرجى تأكيد ملكية هذا الفندق لك؟",
          continue: "نعم، تابع",
          visit_dashboard: "زيارة لوحة التحكم",
          visit_your_website: "قم بزيارة موقعك الإلكتروني",
          congratulations: "تهانينا! لقد أنشأت موقعك الإلكتروني بنجاح",
          enter_property_details: "أدخل اسم ممتلكاتك، المنطقة، المدينة",
          check_out_time: "وقت تسجيل الخروج",
          try_adding_post: "جرب إضافة منشور الآن",
          add_new_post: "إضافة منشور جديد",
          title: "العنوان",
          description: "الوصف",
          submit: "إرسال",
          posts: "المشاركات",
          profile: "الملف الشخصي",
          your_name: "ما هو اسمك؟",
          languages_you_know: "اللغات التي تعرفها",
          what_do_you_like: "ماذا تُحِبّ؟",
          what_is_your_nature: "ما هى طبيعتك",
          upload_an_image: "قم بتحميل صورة",
          profile_completed: "اكتمل الملف الشخصي",
          view_button: "عرض",
          relax_message:
            "استرخ واستمتع - محتريفونا الرقميون مشغولون بصنع رائعتك على الإنترنت...",
          last_minute_deals_explanation:
            "العروض الأخيرة هي طريقة لتقديم أسعار خاصة للضيوف عندما يكون في الفندق نسبة شغور عالية ويرغب في سرعة استيعاب الغرف. على سبيل المثال، إذا حجز الضيف قبل يومين من تاريخ الوصول وكان أكثر من نصف الغرف فارغة، يمكن أن يقدم الفندق خصماً بنسبة 20%. إذا كان الخصم الأخير الدقيق ينطبق فقط على ليالي معينة ضمن الحجز، سيتم تطبيقه فقط على تلك الليالي وليس على كامل الإقامة.",
          generate_description_ai: "يرجى انشاء وصف باستخدام الذكاء الاصطناعي",
          languages: "اللغات",
          nature: "الطبيعة",
          interests: "الاهتمامات",
          bulk_discounts_explanation:
            "الخصومات الجماعية هي وسيلة لتقديم خصومات للنزلاء الذين يقيمون لفترة طويلة أو في مجموعة كبيرة. يمكن تحديد هذه الخصومات كنسبة مئوية من الفاتورة عندما تتجاوز مبلغًا معينًا. على سبيل المثال، إذا كانت الفاتورة تزيد عن 10,000 روبية، فقد يقدم الفندق خصمًا بنسبة 10%.",
          early_bird_discount: "خصم الحجز المبكر",
          last_minute_discount: "خصم اللحظة الأخيرة",
          full_name: "الاسم بالكامل",
          bulk_discount: "خصم جملة",
          fill_all_fields: "يرجى تعبئة جميع الحقول",
          keep_one_day_before_checkin:
            "يرجى الحفاظ على يوم واحد على الأقل قبل تاريخ الوصول",
          promotions_discounts: "العروض الترويجية / الخصومات",
          add_promotion: "إضافة عرض ترويجي",
          discounts: "خصومات",
          added: "تمت الإضافة",
          days_before_check_in: "الأيام قبل تاريخ الوصول",
          add_your_first_discount: "أضف خصمك الأول",
          early_bird_discounts_explanation:
            "خصومات الحجز المبكر هي وسيلة لتشجيع الضيوف على الحجز والدفع مقدمًا. على سبيل المثال، إذا حجز الضيف قبل أكثر من ثلاث أسابيع من موعد الإقامة، فقد يحصل على خصم إضافي بنسبة 10%.",
          profile_completed_message:
            "لقد أكملت بنجاح جميع الحقول المطلوبة لقسم الملف الشخصي. انقر على الزر أدناه لعرض الملف الشخصي أو تحديثه.",
          days_discount: "خصم على الأيام",
          add_now: "أضف الآن",
          bulk_discounts: "الخصومات الجماعية",
          apply: "تطبيق",
          percentage: "نسبة الخصم",
          for_total_bill_amount_more_than: "إذا كان إجمالي الفاتورة أكثر من",
          no_promotions_available:
            "لا توجد عروض حالياً، يرجى إضافة عروض لجذب المزيد من الضيوف!",
          discount: "الخصم",
          number_of_days: "عدد الأيام",
          discount_in: "خصم على",
          select_discount: "حدد الخصم",
          delete_promotion: "إلغاء العرض",
          personal_info: "المعلومات الشخصية",
          login: "تسجيل دخول",
          welcome_back: "مرحبًا بعودتك، يرجى تسجيل الدخول إلى حسابك",
          success: "تم بنجاح",
          logout_confirmation: "هل أنت متأكد أنك تريد تسجيل الخروج؟",
          send_otp: "إرسال رمز التحقق",
          enter_otp: "أدخل رمز التحقق",
          verify_otp: "تأكيد رمز التحقق",
          by_signing_in: "بتسجيل الدخول، أنت توافق على",
          or: "أو",
          terms_and_conditions: "الشروط والأحكام",
          change_language: "تغيير اللغة",
          create_property: "إضافة فندق",
          enter_property_name: "أدخل اسم الفندق",
          enter_address: "أدخل العنوان",
          select_hotel_type: "اختر تصنيف الفندق",
          please_try_again: "الرجاء المحاولة مرة أخرى",
          no_results_found: "لم يتم العثور على أي نتائج",
          names_updated_successfully: "تم تحديث الأسماء بنجاح",
          mobile_number_changed_successfully:
            "تم تغيير رقم الهاتف المحمول بنجاح",
          personal_information: "المعلومات الشخصية",
          first_name: "الاسم الأول",
          update_email: "تحديث البريد الإلكتروني",
          last_name: "الاسم الأخير",
          price_cannot_be_zero: "ا يمكن أن يكون السعر 0",
          contact_to_change_property_name_or_address:
            "يرجى الاتصال بنا لتغيير اسم العقار أو عنوانه",
          otp_send_successfully: "تم إرسال رمز التحقق بنجاح",
          you_have_seen_all_the_bookings: "لقد قمت بمراجعة جميع الحجوزات",
          value_cannot_be_less_than_0: "ا يمكن أن تكون القيمة أقل من 0",
          update_inventory_for_at_least_one_room:
            "يرجى تحديث التوافر لغرفة واحدة على الأقل",
          from_date_cannot_be_greater_than_to_date:
            "تاريخ البداية لا يمكن أن يكون أكبر من تاريخ النهاية",
          from_and_to_date_cannot_be_the_same:
            "تاريخ البداية وتاريخ النهاية لا يمكن أن يكونا متطابقين",
          please_select_from_date: "يرجى تحديد تاريخ البدء",
          please_select_to_date: "يرجى تحديد تاريخ الانتهاء",
          please_enter_valid_phone_number: "يرجى إدخال رقم هاتف صحيح",
          please_add_at_least_3_images: "يرجى إضافة 3 صور على الأقل",
          invalid_gst_number: "رقم GST غير صالح",
          please_try_again_later: "يرجى المحاولة مرة أخرى لاحقًا!",
          price_is_required: "فضلا أدخل السعر",
          check_in_dates_required: "مطلوب تحديد اول وقت للدخول وأخر وقت ",
          price_greater_than_zero: "يجب أن يكون السعر أكبر من 0",
          check_in_dates_order:
            "يجب ان يكون اخر وقت لتسجيل الدخول بعد أول وقت لتسجيل الخروج",
          price_less_than_base_price: "يجب أن يكون السعر أقل من السعر الأساسي",
          min_guest_greater_than_max_guest:
            "عدد الضيوف الأدنى لاينبغى أن يكون أكثر من عدد الضيوف الأقصى",
          enter_correct_price_for_all_guests:
            "يرجى إدخال السعر الصحيح لجميع الضيوف!",
          add_atleast_3_images: "يرجى إضافة 3 صور على الأقل",
          add_atleast_1_images: "يرجى إضافة صورة واحدة على الأقل",
          enter_room_name: "يرجى إدخال اسم الغرفة",
          select_bed_type: "يرجى تحديد نوع السرير",
          select_room_type: "يرجى تحديد نوع الغرفة",
          fill_all_details: "يرجى  إدخال جميع البيانات المطلوبة",
          enter_price_for: "يرجى إدخال السعر ل",
          enter_correct_prices: "يرجى إدخال الأسعار الصحيحة",
          minimum_guest_not_less_than_1:
            "لا يمكن أن يكون الحد الأدنى للضيوف أقل من 1!",
          room_count_greater_than_zero: "يجب أن يكون عدد الغرف أكبر من 0",
          guest_count_error:
            "عدد الضيوف الأقصى لا يمكن أن يكون أقل من عدد الضيوف الأدنى",
          account_number_required: "يرجى إدخال رقم الحساب",
          please_search_your_property: "يرجى البحث عن الفندق الخاص بك",
          please_select_property_type: "يرجى تحديد تصنيف الفندق ",
          account_holder_name_required: "يرجى إدخال اسم صاحب الحساب",
          keep_percentage_between_5_and_95: "يجب أن تكون النسبة بين 5% و 95%",
          select_date_range_below_one_month: "يرجى تحديد فترة زمنية أقل من شهر",
          ifsc_code_required: "رمز IFSC  مطلوب",
          enter_percentage_to_be_charged: "يرجى إدخال النسبة المطلوب تحصيلها",
          percentage_should_be_between_0_100: "النسبة يجب أن تكون بين 0 و100",
          days: "أيام",
          percentage_must_be_greater_than: "النسبة يجب أن تكون أكبر من",
          please_enter_name: "يرجى إدخال الاسم",
          please_enter_description: "يرجى إدخال الوصف",
          please_enter_number_of_days: "يرجى إدخال عدد الأيام",
          policy_already_exists: "السياسة موجودة بالفعل للأيام المعطاة",
          new_booking_alert: "إشعار حجز جديد",
          youve_got: "لقد حصلت علي",
          new_booking: "حجز جديد",
          reply_updated_successfully: "تم تحديث الرد بنجاح",
          accept_now: "موافقة الآن",
          image_upload_failed: "فشل تحميل الصورة",
          designation: "المسمى الوظيفي",
          enter_designation: "الرجاء إدخال المسمى الوظيفي",
          start_date: "تاريخ البدء",
          end_date: "تاريخ الانتهاء",
          cannot_mark_show_now: "لا يمكنك تحديد عدم الحضور الآن",
          discount_range_error: "الخصم يجب أن يكون أكبر من 0 وأقل من 100",
          "Custom Website": "موقع ويب مخصص",
          add_section: "إضافة قسم",
          section_title: "عنوان القسم",
          section_description: "وصف القسم",
          select_layout: "اختر التصميم",
          meta_title: "العنوان الفرعي",
          meta_description: "الوصف الفرعي",
          enter_meta_title: "الرجاء إدخال العنوان الفرعي",
          owner_details: "تفاصيل المالك",
          enter_meta_description: "الرجاء إدخال الوصف الفرعي",
          enter_analytics_id: "الرجاء إدخال رقم Google Analytics",
          user_id: "رقم المستخدم",
          owned_hotels: "الفنادق التي تمتلكها",
          no_hotels: "لا توجد فنادق",
          no_posts_yet: "لا توجد مشاركات بعد",
          enter_email: "الرجاء إدخال البريد الإلكتروني",
          "High Intent": "اهتمام كبير",
          "Medium Intent": "اهتمام متوسط",
          "Unlock for": "فتح ل",
          "No Leads Found": "لم يتم العثور على أي عملاء",
          AI_Revenue_Manager: "مدير الإيرادات الذكي",
          Enable_Scraper: "تفعيل الماسح",
          Scrape_Fast: "مسح أسرع",
          Include_OTA: "تشمل مواقع الحجز عبر الإنترنت",
          Select_Options: "يرجى تحديد الخيارات",
          Exclude_OTA: "استثناء مواقع الحجز عبر الإنترنت",
          Min_Reduction_Percentage: "الحد الأدنى للخصم (بالنسبة المئوية)",
          Enter_percentage: "الرجاء إدخال النسبة المئوية",
          Min_Price: "الحد الأدنى للسعر",
          Enter_Value: "الرجاء إدخال القيمة",
          Submit: "إرسال",
          Enter_Reason: "الرجاء إدخال السبب",
          please_enter_the_reason_for_blocking_the_rooms_to_continue:
            "يرجى إدخال سبب غلق الغرفة للمتابعة",
          Modified_Date: "تاريخ التعديل",
          Created_Date: "تاريخ الإضافة",
          rate_plan: "خطة الأسعار",
          Basic_Promotion: "العرض الأساسي",
          Offer_recurring_discounts_to_improve_occupancy:
            "قدم خصومات متكررة لتحسين معدل الاشغال",
          Last_Minute_Promotion: "عرض اللحظة الأخيرة",
          "Offer_last-minute_discounts_to_guests_who_book_0,_1,_or_2_days_before_check-in.":
            "قدم خصومات في اللحظة الأخيرة للضيوف الذين يحجزون 0، 1، أو 2 أيام قبل تسجيل الوصول",
          Early_Bird_Promotion: "عرض الحجز المبكر",
          "Offer exclusive discounts to those who reserve their stays well in advance.":
            "قدم خصومات حصرية لأولئك الذين يحجزون إقاماتهم مُسبقًا بشكل جيد",
          "Long Stay Promotion": "عرض الإقامة الطويلة",
          "Offer guests free nights or discounted prices to promote longer stays.":
            "قدم للضيوف ليالي مجانية أو أسعار مخفضة لتعزيز الإقامات الطويلة.",
          "Day Based Promotion": "عرض بناءا على عدد الأيام",
          "Offer discount on weekdays/non-peak days to improve occupancy on low demand days.":
            "قدم خصومات على أيام الأسبوع أو الأيام غير الذروة لتحسين معدل الاشغال في الأيام ذات الطلب المنخفض",
          "Custom Promotion": "عرض مخصص",
          "Create a customised promotion for your guests basis your preference.":
            "إنشاء عرض مخصص للضيوف على حسب رغبتك",
          "Create New Promotion": "إنشاء عرض جديد",
          "My Promotions": "العروض الخاصة بي",
          Promotions: "العروض",
          "Are you sure": "هل أنت متأكد ؟",
          "Delete Promotion": "إلغاء العرض",
          Active: "نشط",
          Expired: "منتهي الصلاحية",
          "No Name": "بدون اسم",
          onwards: "ابتداءً",
          Until: "حتى",
          "No End Date": "لا يوجد تاريخ انتهاء",
          MODIFY: "تعديل",
          DELETE: "حذف",
          "No Promotions": "لا توجد عروض",
          previous: "السابق",
          "Enter a unique and descriptive name for this promotion to easily identify and communicate the offer, ensuring clear organization and effective marketing":
            "أدخل اسمًا فريدًا ووصفيًا لهذا العرض الترويجي لتمييزه والتواصل بشكل سهل عن العرض، مما يضمن التنظيم الواضح والتسويق الفعال",
          "SPECIFY PROMOTION VALIDITY": "حدد صلاحية العرض",
          "Enter the Name for the promotion": "يرجى إدخال اسم للعرض",
          "Type here..": "أكتب هنا",
          Discount: "خصم",
          "Free Nights": "ليالي مجانية",
          "Select the type of promotion you want to create, choosing between a discount (fixed amount or percentage) or free nights":
            "يرجى تحديد نوع العرض الترويجي الذي ترغب في إنشائه، سواء كان خصم (مبلغ ثابت أو نسبة) أو ليالي مجانية",
          "OFFER TYPE": "نوع العرض",
          "What would you like to offer?": "ما الذي تود أن تقدمه؟",
          "Specify the exact amount of discount (either a fixed amount or percentage) or the number of free nights being offered.":
            "حدد المبلغ الدقيق للخصم (إما مبلغًا ثابتًا أو نسبة) أو عدد الليالي المجانية التي تُقدم.",
          "CONFIGURE OFFER": "ضبط العرض",
          "Free Night Rule": "قاعدة الليلة المجانية",
          "Free night": "ليلة مجانية",
          "Select offer Type": "يرجى اختيار نوع العرض",
          "Discount (Per Night)": "خصم (لكل ليلة)",
          "Enter Discount": "يرجى إدخال قيمة الخصم",
          Nights: "الليالي",
          "Enter the minimum number of nights a guest must book to qualify for this promotion.":
            "يرجى إدخال الحد الأدنى لعدد الليالي التي يجب على الضيف حجزها للاستفادة بهذا العرض",
          "LENGTH OF STAY": "مدة الإقامة",
          "Do you want to set min. length of stay?":
            "هل ترغب في تحديد الحد الأدنى لمدة الإقامة؟",
          "Min. stay duration to avail discount":
            "الحد الأدنى لمدة الإقامة للاستفادة من الخصم",
          Night: "ليلة",
          "Same day check-in": "تسجيل الوصول في نفس اليوم",
          "Enter how many days in advance the guest must book.":
            "الرجاء إدخال عدد الأيام اللازمة للحجز مُسبقًا.",
          "Specify how many days close to the booking date they should book.":
            "حدد كم يومًا قرب تاريخ الحجز يجب أن يحجز فيها.",
          "BOOKING WINDOW": "نافذة الحجز",
          "Do you want to set Early Bird rule?":
            "هل ترغب في تحديد قاعدة للحجز المبكر؟",
          "Early Bird Booking Window": "نافذة الحجز المبكر",
          "Do you want to set Last Minute Deal?":
            "هل ترغب في تطبيق عرض اللحظة الأخيرة؟",
          "Last Minute Deal Booking Window": "نافذة حجز عرض اللحظة الأخيرة",
          "Select the specific days of the week on which this promotion will be available.":
            "يرجى تحديد أيام الأسبوع التى سيكون فيها العرض متاحا",
          "DAY BASED APPLICABILITY": "تطبيق بناءا على الأيام",
          "Is this applicable on the selected days?":
            "هل هذا قابل للتطبيق في الأيام المحددة؟",
          "Selected Days": "الأيام المحددة",
          "Stay Date": "تاريخ الإقامة",
          "Booking And Stay Date": "تاريخ الحجز وتاريخ الإقامة",
          "For stay dates, enter the start and end dates when the guest can check-in. For booking dates, specify the period during which the guest can make the booking.":
            "لتواريخ الإقامة، يرجى إدخال تواريخ بداية ونهاية يمكن للضيف أن يسجل الوصول فيها. بالنسبة لتواريخ الحجز، يرجى تحديد الفترة التي يمكن للضيف أن يحجز فيها.",
          "PROMOTION DATES": "تواريخ العرض",
          "Is this promotion applicable for choosen dates ?":
            "هل هذا العرض ساري للتواريخ المحددة؟",
          "This promotion will be applicable for?": "هذا العرض سيكون ساري لـ؟",
          "No end date": "لا يوجد تاريخ انتهاء",
          "Booking Date": "تاريخ الحجز",
          "Specify any dates when this promotion will not be available.":
            "حدد أي تواريخ لن يكون فيها هذا العرض متاحًا",
          "Select the rooms and rate plans to which this promotion will apply.":
            "يرجى تحديد الغرف وخطط الأسعار التي سينطبق عليها هذا العرض ",
          "ADVANCE SETTINGS": "إعدادات متقدمة",
          "Do you want to blackout the offer for specific stay dates?":
            "هل ترغب في حجب العرض لتواريخ الإقامة المعينة؟",
          "Choose Blackout Dates": "اختر تواريخ الحجب",
          "Will the promotion be applicable for all rooms and rateplans?":
            "هل سيكون العرض متاح لجميع الغرف و خطة الأسعار؟",
          "Select Rooms And Rateplans": "اختر الغرف و خطة الأسعار",
          Loading: "جار التحميل",
          "No Rooms Available": "لا توجد غرف متاحة",
          "Specify the minimum booking amount required for a guest to be eligible for this promotion.":
            "حدد الحد الأدنى للمبلغ المطلوب للحجز ليكون الضيف مؤهلاً لهذا العرض.",
          "MINIMUM BOOKING AMOUNT": "الحد الأدنى للحجز",
          "Do you want to set min. booking amount?":
            "هل ترغب في تحديد الحد الأدنى لمبلغ الحجز؟",
          "Min. booking amount": "الحد الأدنى لمبلغ الحجز",
          "Enter booking amount": "أدخل مبلغ الحجز",
          "COUPON CODES": "أكواد الخصم",
          "Do you want the promotion to be applicable only using coupon codes?":
            "هل ترغب في جعل العرض ساريًا فقط باستخدام أكواد الخصم؟",
          "Enter the coupon codes": "أدخل أكواد الخصم",
          "Enter code": "ادخل الكود",
          "Start Date": "تاريخ البدء",
          "End Date": "تاريخ الانتهاء",
          Select: "حدد",
          "Select Duration": "حدد المدة",
          Create: "إنشاء",
          "Successfully created": "تم الإنشاء بنجاح",
          "Something went wrong": "حدث خطأ ما",
          "Successfully Deleted": "تم الحذف بنجاح",
          "Name is required": "الاسم مطلوب",
          "Max. length of stay is required": "الحد الأقصى للإقامة مطلوب",
          "Discount must be lesser than 100%": "يجب أن يكون الخصم أقل من 100%",
          "Discount must be higher than zero": "يجب أن يكون الخصم أكبر من 0",
          "Min. length of stay must be higher than free nights":
            "يجب أن يكون الحد الأدنى للإقامة أكبر من عدد الليالي المجانية",
          "Min. length of stay is required": "يرجى إدخال الحد الأدنى للإقامة",
          "Min booking amount must be higher than zero":
            "يجب أن يكون الحد الأدنى لمبلغ الحجز أكبر من0",
          "Early Bird Window is not selected":
            "لم يتم اختيار نافذة الحجز المبكر",
          "Last Minute Window is not selected":
            "لم يتم اختيار نافذة الحجز في اللحظة الأخيرة",
          "Days are not selected": "لم يتم اختيار الأيام",
          "Blackout dates are not selected": "لم يتم اختيار تواريخ الحجب",
          "Rooms or Rateplans are not selected":
            "لم يتم اختيار الغرف أو خطة الأسعار",
          "Coupon codes are required": "يرجى إدخال أكواد الخصم ",
          "Stay Start date is required": "يرجى إدخال تاريخ بدء الاقامة",
          "Booking Start date is required": "يرجى إدخال تاريخ بدء الحجز",
          "Booking start must be before stay start":
            "يجب أن يكون تاريخ بدء الحجز قبل تاريخ بدء الإقامة",
          "Booking end must be before stay end":
            "يجب أن يكون تاريخ نهاية الحجز قبل تاريخ نهاية الإقامة",
          "Booking End date is required": "يرجى إدخال تاريخ نهاية الحجز",
          "Stay End date is required": "يرجى إدخال تاريخ نهاية الإقامة",
          "Min. length of stay or Min. booking amount is required":
            "يرجى إدخال الحد الأدنى للإقامة أو الحد الأدنى لمبلغ الحجز",
          "Min. booking amount or Min. length of stay is required":
            "الحد الأدنى لمبلغ الحجز أو الإقامة مطلوب",
          Back: "رجوع",
          "With Coupon": "مع الكوبون",
          Name: "الاسم ",
          Type: "النوع",
          Expiring: "انتهاء ",
          Action: "إجراء",
          night: "ليلة",
          reorder: "إعادة ترتيب",
          name: "الاسم",
          tax_type: "نوع الضريبة",
          tax_value: "أدخل قيمة الضريبة",
          price_from: "نطاق السعر الخاضع للضريبة من (إن وجد)",
          price_to: "النطاق السعري الخاضع للضريبة إلى (إن أمكن)",
          tax_applicable_level: "مستوى تطبيق الضريبة",
          action: "إجراء",
          tax_details: "تفاصيل الضريبة",
          tax_name_heading: "اسم الضريبة",
          tax_name: "أدخل اسم الضريبة",
          tax_type: "حدد نوع الضريبة",
          tax_applicable_at: "تطبيق الضريبة عند",
          current_taxes: "الضرائب الحالية",
          tax_percent: "أدخل الضريبة بالنسبة المئوية",
          cumulative_percentage: "النسبة التراكمية",
          tax_type_heading: "نوع الضريبة",
          tax_value_heading: "القيمة الضريبية",
          booking: "الحجز",
          fixed: "ثابت",
          is_tax_included: " الضريبة مشمولة في جميع خطط الأسعار؟",
          drag_to_reorder: "اسحب لإعادة الترتيب",
          tax_inclusion_status_updated_successfully:
            "تم تحديث حالة تضمين الضريبة بنجاح",
          failed_to_update_tax_inclusion_status:
            "فشل في تحديث حالة تضمين الضريبة",
          error_updating_tax_inclusion_status:
            "خطأ في تحديث حالة تضمين الضريبة",
          name_cannot_be_empty: "لا يمكن أن يكون الاسم فارغًا",
          please_enter_tax_value: "يرجى إدخال قيمة الضريبة",
          to_price_can_not_be_less_than_from_price:
            "السعر إلى لا يمكن أن يكون أقل من السعر من",
          tax_saved_successfully: "تم حفظ الضريبة بنجاح",
          something_went_wrong: "حدث خطأ ما!!",
          tax_deleted_successfully: "تم حذف الضريبة بنجاح",
          somethings_wrong_try_again_later:
            "هناك خطأ، يرجى المحاولة مرة أخرى لاحقًا",
          cannot_be_less_than_0: "لا يمكن أن يكون أقل من 0",
          tax_percentage_should_be_between_0_and_100:
            "يجب أن تكون نسبة الضريبة بين 0 و 100",
          please_enter_correct_tax_value: "يرجى إدخال قيمة ضريبة صحيحة",
          to_price_cannot_be_less_than_from_price:
            "السعر إلى لا يمكن أن يكون أقل من السعر من",
          tax_sorted_successfully: "تم ترتيب الضريبة بنجاح",
          error_sorting_tax: "خطأ في ترتيب الضريبة",
          configuration: "إعدادات",
          see_how_leads_work: "شاهد كيف تعمل الفرص",
          medium_intent: "نية متوسطة",
          high_intent: "نية عالية",
          contact_information_will_be_revealed_after_purchase:
            "سيتم الكشف عن معلومات الاتصال بعد الشراء",
          charges_for_leads: "الرسوم للفرص",
          of_booking_amount: "0.5% _من_مبلغ_الحجز",
          contact_information: "معلومات الاتصال",
          show_lead: "عرض الفرصة |",
          expired: "منتهية",
          of_booking_amount: "من مبلغ الحجز",
          of_your_lowest_room_rate: "من أقل سعر لغرفتك",
          adults: "البالغين",
          adult: "بالغ",
          taxes: "الضرائب",
          view_bookings: "عرض الحجوزات",
          per_night: "لكل غرفة ليلة ",
          total_booking_amount: "إجمالي مبلغ الحجز",
          default_tax_cannot_be_deleted: "لا يمكن حذف الضريبة الافتراضية",
          default_tax_cannot_be_edited: "لا يمكن تحرير الضريبة الافتراضية",
          please_keep_the_percentage_more_than_8_85:
            "الرجاء إدخال النسبة المئوية أكثر من 8.85",
          please_enter_percentage: "الرجاء إدخال النسبة المئوية",
          auto_price_match: "مطابقة الأسعار التلقائية",
          min_reduction_type: "الحد الأدنى من نوع التخفيض",
          min_reduction_percent: "الحد الأدنى من نسبة التخفيض",
          min_reduction_price_in: "الحد الأدنى لسعر التخفيض في",
          Enable_auto_price_match: "تمكين مطابقة الأسعار التلقائية",
          block_room: "غرفة الكتلة",
          hotel_name_cannot_be_empty: "لا يمكن أن يكون اسم الفندق فارغًا ",
          tax_updated_successfully: "تم تحديث الضريبة بنجاح",
          you_have_seen_all_leads: "لقد رأيت كل الخيوط",
        },
      },
      // Add more languages here
    },
    lng: "en", // default language
    fallbackLng: "en",
    debug: false,

    interpolation: {
      escapeValue: false, // React already safes from XSS
    },
  });

export default i18n;
