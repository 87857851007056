import React from "react";
import * as Icon from "react-feather";
import { routes } from "../component_route/Index";
import permissionsObj from "../utills/adminPermissions";
import filterNavigationConfig from "../utills/filterNavigationConfig";
import RuleIcon from "@mui/icons-material/Rule";
import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import SwipeVerticalOutlinedIcon from "@mui/icons-material/SwipeVerticalOutlined";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { SiGoogleads, SiGooglemybusiness, SiMeta } from "react-icons/si";
import { BsCalculator } from "react-icons/bs";

import {
  BedroomParentOutlined,
  Dashboard,
  DashboardOutlined,
  RoomPreferencesOutlined,
  SmartToyRounded,
  StarHalfRounded,
} from "@mui/icons-material";
import { SiProbot } from "react-icons/si";
import { MdCampaign } from "react-icons/md";
import { MdLogin } from "react-icons/md";
import storageService from "../services/storageServices";

const hotel = storageService.get("selectedHotel");
const userId = storageService.get("userAdmin")?.userId;

let navigationConfig = [
  {
    id: "dashboard",
    title: "Home",
    type: "item",
    icon: <DashboardOutlined />,
    navLink: routes.home,
  },
  {
    id: "bookings",
    title: "Bookings",
    type: "item",
    icon: <Icon.Star size={20} />,
    permissions: ["HOTEL_OWNER", "EMPLOYEE", "ADMIN"],
    navLink: routes.bookings,
    module: permissionsObj.bookings,
  },
  {
    id: "inventoryManagement",
    title: "Manage Inventory",
    type: "item",
    icon: <Icon.Archive size={20} />,
    // permissions: ["HOTEL_OWNER", "EMPLOYEE", "ADMIN"],
    navLink: routes.inventoryManagement,
    // module: permissionsObj.inventoryManagement,
  },
  // {
  //   id: "socialAds",
  //   title: "AI Ads",
  //   type: "item",
  //   icon: <SmartToyRounded />,
  //   // permissions: ["HOTEL_OWNER", "EMPLOYEE", "ADMIN"],
  //   navLink: routes.socialAds,
  //   // module: permissionsObj.inventoryManagement,
  // },
  {
    id: "editProperty",
    title: "Update Property",
    type: "item",
    icon: <RoomPreferencesOutlined />,
    // permissions: ["HOTEL_OWNER", "EMPLOYEE", "ADMIN"],
    navLink: routes.updateProperty,
    // module: permissionsObj.addProperty,
  },
  {
    id: "roomList",
    title: "Rooms",
    type: "item",
    icon: <BedroomParentOutlined />,
    // permissions: ["HOTEL_OWNER", "EMPLOYEE", "ADMIN"],
    navLink: routes.rooms,
    // module: permissionsObj.addProperty,
  },
  {
    id: "socialAds",
    title: "AI Ads",
    type: "collapse",
    icon: <SiProbot size={20} />,
    ...([32753].includes(userId)
      ? {}
      : {
          permissions: ["ADMIN"],
        }),
    children: [
      {
        id: "socialAdsLogin",
        title: "Login",
        type: "item",
        icon: <MdLogin size={20} />,
        navLink: routes.socialAds,
      },
      {
        id: "campaigns",
        title: "Campaigns",
        type: "item",
        icon: <MdCampaign size={20} />,
        navLink: routes.campaigns,
      },
      // {
      //   id: "campaignCreation",
      //   title: "Campaign Creation",
      //   type: "item",
      //   icon: <SiMeta size={20} />,
      //   navLink: routes.campaignCreation,
      // },
    ],
  },
  {
    id: "manage",
    title: "Settings",
    type: "collapse",
    icon: <Icon.Settings size={20} />,
    permissions: ["HOTEL_OWNER", "EMPLOYEE", "ADMIN"],
    // modules: permissionsObj.manage,
    children: [
      {
        id: "paymentconfig",
        title: "Payment Settings",
        type: "item",
        icon: <Icon.DollarSign size={20} />,
        permissions: ["HOTEL_OWNER", "ADMIN"],
        navLink: routes.paymentConfig,
        subModule: permissionsObj.manage[0],
      },
      // {
      //   id: "buyDomain",
      //   title: "Buy Domain",
      //   type: "item",
      //   icon: <Icon.Globe size={20} />,
      //   navLink: routes.domain,
      // },
      {
        id: "taxes",
        title: "Taxes",
        type: "item",
        icon: <BsCalculator size={20} />,
        navLink: routes.taxes,
        //subModule: permissionsObj.manage[0],
      },

      {
        id: "social",
        title: "Social Media",
        type: "item",
        icon: <Icon.Users size={20} />,
        // permissions: ["HOTEL_OWNER", "EMPLOYEE", "ADMIN"],
        navLink: routes.social,
        // subModule: permissionsObj.manage[1],
      },
      {
        id: "report",
        title: "Reports",
        type: "item",
        icon: <Icon.BookOpen size={20} />,
        // permissions: ["HOTEL_OWNER", "EMPLOYEE", "ADMIN"],
        navLink: routes.report,
        // subModule: permissionsObj.manage[2],
      },

      {
        id: "logo",
        title: "Add Logo",
        type: "item",
        icon: <Icon.Triangle size={20} />,
        // permissions: ["HOTEL_OWNER", "EMPLOYEE", "ADMIN"],
        navLink: routes.addLogo,
        // subModule: permissionsObj.manage[3],
      },
      // {
      //   id: "policy",
      //   title: "Policy / Rules",
      //   type: "collapse",
      //   icon: <Icon.Tool size={20} />,
      //   // permissions: ["HOTEL_OWNER", "EMPLOYEE", "ADMIN"],
      //   children: [
      {
        id: "houseRules",
        title: "Property Rules",
        type: "item",
        icon: <RuleIcon />,
        // permissions: ["HOTEL_OWNER", "EMPLOYEE", "ADMIN"],
        navLink: routes.houseRules,
        // subModule: permissionsObj.policy[0],
      },
      {
        id: "cancellationPolicy",
        title: "Cancellation Policy",
        type: "item",
        icon: <PolicyOutlinedIcon />,
        // permissions: ["HOTEL_OWNER", "EMPLOYEE", "ADMIN"],
        navLink: routes.cancellationPolicy,
        // subModule: permissionsObj.policy[1],
      },
      //   ],
      // },
      {
        id: "bookingPreference",
        title: "Booking Preference",
        type: "item",
        icon: <Icon.Award size={20} />,
        navLink: routes.bookingPreference,
      },
      {
        id: "faq",
        title: "FAQ",
        type: "item",
        icon: <QuestionAnswerOutlinedIcon />,
        permissions: ["HOTEL_OWNER", "EMPLOYEE", "ADMIN"],
        navLink: routes.faq,
        subModule: permissionsObj.faq,
      },
      {
        id: "hotelAddons",
        title: "Addons",
        type: "item",
        icon: <SwipeVerticalOutlinedIcon />,
        permissions: ["HOTEL_OWNER", "EMPLOYEE", "ADMIN"],
        navLink: routes.hotelAddons,
        subModule: permissionsObj.manage[5],
      },
    ],
  },
  {
    id: "moreBookings",
    title: "More Bookings",
    type: "collapse",
    icon: <Icon.ArrowUpRight />,
    children: [
      {
        id: "gads",
        title: "Google Ads",
        type: "item",
        icon: (
          // <img
          //   src="https://cdn.icon-icons.com/icons2/2699/PNG/512/google_ads_logo_icon_171064.png"
          //   width={20}
          //   style={{ marginRight: "15px" }}
          // />
          <SiGoogleads size={20} />
        ),
        permissions: ["HOTEL_OWNER", "EMPLOYEE", "ADMIN"],
        navLink: routes.gads,
        module: permissionsObj.gads,
      },

      {
        id: "gmb-settings",
        title: "Google My Business",
        type: "item",
        icon: <SiGooglemybusiness size={20} />,
        // permissions: ["HOTEL_OWNER", "EMPLOYEE", "ADMIN"],
        navLink: routes.gmb,
      },
      {
        id: "post",
        title: "Google Post",
        type: "item",
        icon: <Icon.FileText size={20} />,
        navLink: routes.post,
      },
      // module: permissionsObj.gmb,

      {
        id: "reviews",
        title: "Google Reviews",
        type: "item",
        icon: <Icon.Star size={20} stroke="#F4B400" fill="#F4B400" />,
        navLink: routes.googleReviews,
      },
      {
        id: "profile",
        title: "About you",
        type: "item",
        icon: <Icon.User />,
        navLink: routes.profile,
      },
      {
        id: "promo",
        title: "Promotion",
        type: "item",
        icon: <Icon.Percent size={20} />,
        permissions: ["HOTEL_OWNER", "EMPLOYEE", "ADMIN"],
        navLink: routes.promo,
        subModule: permissionsObj.promo,
      },
    ],
  },

  {
    id: "leads",
    title: "Leads",
    type: "item",
    icon: <Icon.BarChart2 size={20} />,
    navLink: routes.leads,
  },
  {
    id: "addProperty",
    title: "Add Property",
    type: "item",
    icon: <Icon.Home size={20} />,
    // permissions: ["HOTEL_OWNER", "EMPLOYEE", "ADMIN"],
    navLink: routes.addProperty,
    // module: permissionsObj.addProperty,
  },
  {
    id: "invoices",
    title: "Invoices",
    type: "item",
    icon: <Icon.Book size={20} />,
    navLink: routes.invoices,
  },
  {
    id: "users",
    title: "Users",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: ["ADMIN"],
    navLink: routes.users,
    module: permissionsObj.users,
  },
  {
    id: "addStaff",
    title: "Staff",
    type: "item",
    icon: <Icon.UserPlus size={20} />,
    // permissions: ["HOTEL_OWNER", "ADMIN"],
    navLink: routes.addStaff,
    // module: permissionsObj.addStaff,
  },
  {
    id: "revenueManagement",
    title: "Manage Revenue",
    type: "item",
    icon: <Icon.BarChart2 size={20} />,
    navLink: routes.revenueManagement,
    // module: permissionsObj.revenueManagement,
    permissions: userId === 2129 ? null : ["ADMIN", "SUPER_ADMIN"],
  },
  {
    id: "channelManager",
    title: "Channel Manager",
    type: "item",
    icon: <Icon.Calendar size={20} />,
    permissions: userId === 2129 ? null : ["ADMIN", "SUPER_ADMIN"],
    navLink: routes.channelManager,
    // module: permissionsObj.channelManager,
  },
  {
    id: "customWebsite",
    title: "Custom Website",
    type: "item",
    icon: <Icon.Settings size={20} />,
    permissions: ["ADMIN", "SUPER_ADMIN"],
    navLink: routes.customWebsite,
    // module: permissionsObj.addStaff,
  },
  {
    id: "scraperPage",
    title: "Autoprice match",
    type: "item",
    icon: <Icon.RefreshCw size={20} />,
    permissions: ["ADMIN", "SUPER_ADMIN"],
    navLink: routes.scraper,
    // module: permissionsObj.addStaff,
  },

  // {
  //   id: "settlements",
  //   title: "Settlements",
  //   type: "item",
  //   icon: <Icon.FileMinus size={20} />,
  //   permissions: ["ADMIN"],
  //   navLink: routes.settlements,
  //   module: permissionsObj.settlements,
  // },
  // {
  //   id: "allImage",
  //   title: "Room Image Classifier",
  //   type: "item",
  //   icon: <Icon.Image size={20} />,
  //   permissions: ["ADMIN"],
  //   navLink: routes.allImage,
  //   module: permissionsObj.allImage,
  // },
  // {
  //   id: "hotelImage",
  //   title: "Hotel Image Classifier",
  //   type: "item",
  //   icon: <Icon.Image size={20} />,
  //   permissions: ["ADMIN"],
  //   navLink: routes.hotelImage,
  //   module: permissionsObj.hoteImage,
  // },
];

if (
  [
    56186, 32331, 59321, 15511, 88914, 94499, 91901, 91904, 88913, 91765,
  ].includes(userId)
) {
  navigationConfig = navigationConfig.map((obj) => {
    if (["Channel Manager"].includes(obj?.title)) {
      delete obj.permissions;
    }
    return obj;
  });
}

if (
  [
    56186, 32331, 59321, 15511, 88914, 94499, 41775, 91901, 91904, 88913, 91765,
  ].includes(userId)
) {
  navigationConfig = navigationConfig.map((obj) => {
    if (["Manage Revenue"].includes(obj?.title)) {
      delete obj.permissions;
    }
    return obj;
  });
}

if (userId === 32753) {
  navigationConfig = navigationConfig.map((obj) => {
    if (["Custom Website"].includes(obj?.title)) {
      delete obj.permissions;
    }
    return obj;
  });
}

const { parse, stringify } = JSON;
const originalNavigationConfig = parse(stringify(navigationConfig));

export { originalNavigationConfig };
export default filterNavigationConfig(navigationConfig);
