import storageService from "../../../../services/storageServices";
import authType from "../../../actionTypes/userTypes";

const { mobile, role, dialCode, email, firstName, lastName } = storageService.get() || {};

const initialState = {
  userRole: role || "",
  mobile,
  dialCode,
  fetching: false,
  email,
  firstName,
  lastName
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case authType.CHANGE_ROLE: {
      return { ...state, userRole: action.payload };
    }

    case authType.FETCHING: {
      return { ...state, fetching: action.payload };
    }

    case authType.SET_USER: {
      return { ...state, ...action.payload };
    }

    default: {
      return { ...state };
    }
  }
};
