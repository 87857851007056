import bookingTypes from "../../../actionTypes/bookingTypes";

const initialState = {
  fetching: false,
  bookingsList: [],
  bookingView: {},
  reasons: [],
  bookingCount: 0,
  bookingCountHotelWise: [],
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case bookingTypes.FETCHING: {
      return { ...state, fetching: action.payload };
    }

    case bookingTypes.SET_BOOKING_LIST: {
      return { ...state, bookingsList: action.payload };
    }

    case bookingTypes.SET_BOOKING_VIEW: {
      return { ...state, bookingView: action.payload };
    }
    case bookingTypes.SET_REASONS: {
      return { ...state, reasons: action.payload };
    }
    case bookingTypes.SET_BOOKING_COUNT: {
      return { ...state, bookingCount: action.payload };
    }
    case bookingTypes.SET_BOOKING_COUNT_HOTELWISE: {
      return { ...state, bookingCountHotelWise: action.payload };
    }

    default: {
      return { ...state };
    }
  }
};
