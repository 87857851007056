import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import {
  Disc,
  X,
  Circle,
  ArrowLeft,
  ChevronsLeft,
  ChevronsRight,
} from "react-feather";
import classnames from "classnames";
import { routes } from "../../../../component_route/Index";
import { useSelector } from "react-redux";
const SidebarHeader = (props) => {
  let {
    toggleSidebarMenu,
    activeTheme,
    collapsed,
    toggle,
    sidebarVisibility,
    menuShadow,
  } = props;

  const { selectedHotel } = useSelector((state) => state.hotels);

  return (
    <div className="navbar-header">
      <ul className="nav navbar-nav flex-row">
        <li className="nav-item mr-auto">
          <NavLink to={routes.home} className="navbar-brand">
            <div className={`brand-logo ${collapsed ? "" : "d-none"}`}>
              <img
                src={
                  selectedHotel?.logo ||
                  "https://ik.imagekit.io/hotelzifyprod/logo.png"
                }
                alt="icon-png"
               
                width={selectedHotel?.logo?"60px":"auto" }
                height="60px"
              />
            </div>
            <h2 className={`brand-text mb-0 ${collapsed ? "d-none" : ""}`}>
              <img
                src={
                  selectedHotel?.logo ||
                  "https://ik.imagekit.io/hotelzifyprod/logo1.png"
                }
                alt="icon-png"
                
                width={selectedHotel?.logo ? "60px" : "auto"}
                height="60px"
              />
              {/* {!selectedHotel?.logo && selectedHotel?.name?.slice(0, 10)} */}
            </h2>
          </NavLink>
        </li>
        <li className="nav-item nav-toggle">
          <div className="nav-link modern-nav-toggle">
            {collapsed === false ? (
              <ChevronsLeft
                onClick={() => {
                  toggleSidebarMenu(true);
                  toggle();
                }}
                className={classnames(
                  "toggle-icon icon-x d-none d-xl-block font-medium-4",
                  {
                    "text-primary": activeTheme === "primary",
                    "text-success": activeTheme === "success",
                    "text-danger": activeTheme === "danger",
                    "text-info": activeTheme === "info",
                    "text-warning": activeTheme === "warning",
                    "text-dark": activeTheme === "dark",
                  }
                )}
                size={20}
                data-tour="toggle-icon"
              />
            ) : (
              <ChevronsRight
                onClick={() => {
                  toggleSidebarMenu(false);
                  toggle();
                }}
                className={classnames(
                  "toggle-icon icon-x d-none d-xl-block font-medium-4",
                  {
                    "text-primary": activeTheme === "primary",
                    "text-success": activeTheme === "success",
                    "text-danger": activeTheme === "danger",
                    "text-info": activeTheme === "info",
                    "text-warning": activeTheme === "warning",
                    "text-dark": activeTheme === "dark",
                  }
                )}
                size={20}
              />
            )}
            <X
              onClick={sidebarVisibility}
              className={classnames(
                "toggle-icon icon-x d-block d-xl-none font-medium-4",
                {
                  "text-primary": activeTheme === "primary",
                  "text-success": activeTheme === "success",
                  "text-danger": activeTheme === "danger",
                  "text-info": activeTheme === "info",
                  "text-warning": activeTheme === "warning",
                  "text-dark": activeTheme === "dark",
                }
              )}
              size={20}
            />
          </div>
        </li>
      </ul>
      <div
        className={classnames("shadow-bottom", {
          "d-none": menuShadow === false,
        })}
      />
    </div>
  );
};

export default SidebarHeader;
