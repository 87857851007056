import moment from "moment";
import httpService from "./httpServices";

export const fetchUsersByNameApi = (name = "", page = 1) => {
  return httpService.get(
    `hotel/authorised/v1/hotel/search?name=${encodeURIComponent(
      name
    )}&page=${page}`
  );
};

export const updateUserApi = (data) => {
  return httpService.post("hotel/authorised/v1/hotel/number", data);
};

export const fetchHotelsApi = (hotelId) => {
  return httpService.get(`hotel/authorised/v1/hotel/detailed?id=${hotelId}`);
};

export const fetchHotelStatsApi = (id) => {
  return httpService.get(`hotel/authorised/v1/hotel/owner_stats?id=${id}`);
};

export const fetchWebViewsApi = (id) => {
  return httpService.post("hotel/authorised/v1/hotel/views-report", id);
};

export const fetchBookingsByStatusApi = (id, page, status) => {
  return httpService.get(
    `hotel/authorised/v1/bookings?status=${status}&page=${page}&hotelId=${id}`
  );
};

export const fetchBookingsByUserApi = (page) => {
  return httpService.get(`hotel/authorised/v1/bookings/all?page=${page}`);
};

export const fetchBookingBySearchApi = (id, url) => {
  return httpService.get(`hotel/authorised/v1/bookings?hotelId=${id}${url}`);
};

export const fetchBookingByIdApi = (hotelId, id) => {
  return httpService.get(
    `hotel/authorised/v1/bookings?hotelId=${hotelId}&id=${id}`
  );
};

export const fetchReasonsApi = (status) => {
  return httpService.get(`hotel/authorised/v2/hotel/lookups?type=${status}`);
};

export const rejectBookingApi = (id, obj) => {
  return httpService.put(
    `hotel/authorised/v1/bookings/${id}/status_by_owner`,
    obj
  );
};

export const updatePriceApi = (obj) => {
  return httpService.post(
    "hotel/authorised/v1/bookings/change_booking_amount",
    obj
  );
};

export const fetchSocialLinksApi = (id) => {
  return httpService.get(`hotel/authorised/v1/social?hotelId=${id}`);
};

export const updateSocialLinksApi = (obj) => {
  return httpService.post(`hotel/authorised/v1/social`, obj);
};

export const fetchReportApi = (obj) => {
  return httpService.post("hotel/authorised/v1/hotel/sales-report", obj);
};

export const fetchWhatsappApi = (id) => {
  return httpService.get(`hotel/authorized/v1/hotel/detail?id=${id}`);
};

export const updateWhatsappApi = (id, obj) => {
  return httpService.put(`hotel/authorised/v1/hotel/${id}`, obj);
};

export const fetchStaffsApi = (id) => {
  return httpService.get(`users/authorised/v1/employee?hotelId=${id}`);
};

export const addBulkStaffsApi = (id, obj) => {
  return httpService.post(`users/authorised/v1/employee?hotelId=${id}`, obj);
};

export const addStaffsApi = (obj) => {
  return httpService.post(`users/authorised/v2/create-employee`, obj);
};

export const editStaffsApi = (obj) => {
  return httpService.put(`users/authorised/v2/employee`, obj);
};

export const deleteStaffsApi = (id, obj) => {
  return httpService.post(
    `users/authorised/v1/delete-employee?hotelId=${id}`,
    obj
  );
};

export const fetchPaymentMethodsApi = () => {
  return httpService.get(`payments/v1/gateways`);
};

export const fetchHotelSelectedPaymentMethodsApi = (id) => {
  return httpService.get(`payments/authorised/v1/gateways/hotel?hotelId=${id}`);
};

export const fetchBankDetailsApi = (id) => {
  return httpService.get(
    `payments/authorized/v1/hotel_bank_details?hotelId=${id}`
  );
};

export const updateBankDetailsApi = (obj) => {
  return httpService.post(
    "payments/authorised/v1/order/createOrUpdateBankAccount",
    obj
  );
};

export const deleteBankDetailsApi = (id) => {
  return httpService.delete(`payments/authorized/v1/hotel_bank_details/${id}`);
};

export const addHotelGatewaysApi = (obj) => {
  return httpService.post(`payments/authorized/v1/gateways/hotel/bulk`, obj);
};

export const addSingleGatewayApi = (obj) => {
  return httpService.post(`payments/authorized/v1/gateways/hotel`, obj);
};

export const deleteHotelGatewayApi = (key) => {
  return httpService.delete(`payments/authorized/v1/gateways/hotel/${key}`);
};

export const updatePartialPercentageApi = (id, obj) => {
  return httpService.put(`payments/authorized/v1/gateways/hotel/${id}`, obj);
};

export const getImageUrlApi = (obj) => {
  return httpService.post(
    `hotel/authorised/v1/hotel/images/common-images`,
    obj
  );
};

export const uploadPropertyLogoApi = (id, obj) => {
  return httpService.put(`hotel/authorised/v1/hotel/${id}`, obj);
};

export const fetchRoomInventoryApi = (
  hotelId,
  hotelRoomId,
  checkInDate,
  checkOutDate
) => {
  return httpService.get(
    `hotel/authorised/v1/pricing_and_inventory?hotelRoomId=${hotelRoomId}&checkInDate=${checkInDate}&checkOutDate=${checkOutDate}&hotelId=${hotelId}`
  );
};

export const updateRoomInventoryApi = (hotelId, obj) => {
  return httpService.post(
    `hotel/authorised/v1/pricing_and_inventory/bulk?hotelId=${hotelId}`,
    obj
  );
};

export const fetchPromotionById = (hotelId, id) => {
  return httpService.get(
    `hotel/authorized/v1/promotions/get-promotion?hotelId=${hotelId}&id=${id}`
  );
};

export const fetchMyHotelsApi = () => {
  return httpService.get(`hotel/authorized/v1/hotel/mine`);
};

export const fetchFAQApi = (hotelId, ln = "en") => {
  return httpService.get(
    `hotel/authorized/v1/faq/get-faq?hotelId=${hotelId}&ln=${ln}`
  );
};

export const fetchFAQSuggestionsApi = (hotelId, ln) => {
  return httpService.get(
    `hotel/authorized/v2/faq/get-faq-types?hotelId=${hotelId}&ln=${ln}`
  );
};

export const fetchUnansweredFAQApi = (hotelId) => {
  return httpService.get(
    `hotel/authorized/v1/faq/get-unanswered-faq?hotelId=${hotelId}`
  );
};

export const updateFAQApi = (obj) => {
  return httpService.put(`hotel/authorized/v1/faq/update-faq`, obj);
};

export const addFAQApi = (obj) => {
  return httpService.post("hotel/authorized/v1/faq/add-faq", obj);
};

export const fetchPromotionsApi = (hotelId) => {
  return httpService.get(
    `hotel/authorized/v1/promotions/get-promotion?hotelId=${hotelId}`
  );
};

export const addPromotionApi = (obj) => {
  return httpService.post("hotel/authorized/v2/promotions/add-promotion", obj);
};

export const deletePromotionApi = (obj) => {
  return httpService.put(
    "hotel/authorized/v2/promotions/delete-promotion",
    obj
  );
};

export const fetchHouseRulesApi = (hotelId, ln = "en") => {
  return httpService.get(
    `hotel/authorized/v2/hotel/policies/${hotelId}?type=house_rules&ln=${ln}`
  );
};

export const fetchCancellationPolicyApi = (hotelId, ln = "en") => {
  return httpService.get(
    `hotel/authorized/v2/hotel/policies/${hotelId}?type=cancellation_policy&ln=${ln}`
  );
};

export const editPolicyApi = (obj) => {
  return httpService.post("hotel/authorised/v1/hotel/policy", obj);
};

export const addPolicyApi = (obj) => {
  return httpService.post("hotel/authorized/v1/hotel/addPolicies", obj);
};

export const fetchHotelAddonsTypeApi = (ln) => {
  return httpService.get(`hotel/v2/addons?type=hotel&ln=${ln}`);
};

export const fetchHotelAddonsApi = (hotelId) => {
  return httpService.get(`hotel/authorised/v1/hotel/addons?hotelId=${hotelId}`);
};

export const addHotelAddonApi = (obj) => {
  return httpService.post("hotel/authorised/v1/hotel/addons", obj);
};

export const disableHotelAddonApi = (addonId, obj) => {
  return httpService.put(`hotel/authorised/v1/hotel/addons/${addonId}`, obj);
};

export const editHotelAddonImageApi = (hotelId, obj) => {
  return httpService.post(
    `hotel/authorised/v1/hotel/addons/images?hotelId=${hotelId}`,
    obj
  );
};

export const editHotelAddonApi = (addonId, obj) => {
  return httpService.put(`hotel/authorised/v1/hotel/addons/${addonId}`, obj);
};

export const autoCompletePlacesApi = (place) => {
  return httpService.get(
    `hotel/authorised/v1/hotel/auto-complete?input=${place}`
  );
};

export const fetchPlaceDetailsApi = (placeId) => {
  return httpService.get(
    `hotel/authorised/v1/hotel/place-details?placeId=${placeId}`
  );
};

export const fetchImageUrlApi = (reference) => {
  return httpService.get(
    `hotel/authorised/v1/hotel/photo?maxwidth=1600&maxHeight=1600&photo_reference=${reference}`
  );
};

export const checkAvailabiltyApi = (placeId) => {
  return httpService.get(
    `hotel/authorized/v1/hotel/check-availability?googlePlaceId=${placeId}`
  );
};

export const requestStaffAccessApi = (obj) => {
  return httpService.post(`hotel/authorized/v1/hotel/request-owner`, obj);
};

export const fetchAllAmenitiesApi = (type) => {
  return httpService.get(`hotel/v2/amenities?type=${type}`);
};

export const fetchAllHotelTypeApi = (ln = "en") => {
  return httpService.get(`hotel/v2/hotel/types?ln=${ln}`);
};

export const fetchAllImagesApi = (page = 1, hotelId) => {
  return httpService.get(
    `hotel/authorised/v1/room/images/all-images?page=${page}&hotelId=${hotelId}`
  );
};

export const fetchAllHotelImagesApi = (page = 1, hotelId = "") => {
  return httpService.get(
    `hotel/authorised/v1/hotel/images/all-images?page=${page}&hotelId=${hotelId}`
  );
};

export const fetchUnsettledHotelsApi = (page = 1) => {
  return httpService.get(
    `hotel/authorized/v1/hotel/get-unsettled-list?page=${page}`
  );
};

export const fetchUnsettledBookingsApi = (hotelId, page) => {
  return httpService.get(
    `payments/authorized/v1/account/getunsettled-bookings?hotelId=${hotelId}`
  );
};

export const preConfirmSettlementApi = (hotelId, obj) => {
  return httpService.post(
    `payments/authorized/v1/account/total-amount-to-settle?hotelId=${hotelId}`,
    obj
  );
};

export const confirmSettlementApi = (hotelId, obj) => {
  return httpService.post(
    `hotel/authorized/v1/bookings/settlements?hotelId=${hotelId}`,
    obj
  );
};

export const getAdsSubscribedApi = (hotelId) => {
  return httpService.get(`hotel/authorised/v1/ads/all?hotelId=${hotelId}`);
};

export const getAdsSuggestionApi = (hotelId, obj) => {
  return httpService.post(
    `hotel/authorised/v1/ads/suggestion?hotelId=${hotelId}`,
    obj
  );
};

export const postAdsApi = (hotelId, obj) => {
  return httpService.post(`hotel/authorised/v1/ads?hotelId=${hotelId}`, obj);
};

export const getAdsPerformanceApi = (obj) => {
  return httpService.post(
    `hotel/authorised/v1/ads/get-day-wise-performance`,
    obj
  );
};

export const getOverallAdsPerformanceApi = (hotelId, toDate, fromDate) => {
  return httpService.get(
    `hotel/authorised/v1/ads/total-views?hotelId=${hotelId}&dateTill=${toDate}&dateFrom=${fromDate}`
  );
};

export const getGraphDataApi = (hotelId, toDate, fromDate) => {
  return httpService.get(
    `hotel/authorised/v1/ads/graph-views?hotelId=${hotelId}&dateTill=${toDate}&dateFrom=${fromDate}`
  );
};

export const getDbPerformanceApi = (hotelId, toDate, fromDate) => {
  return httpService.get(
    `hotel/authorised/v1/ads/performance?hotelId=${hotelId}&dateTill=${toDate}&dateFrom=${fromDate}`
  );
};

export const checkIfRejectedApi = (hotelId, reason = "Already Sold Out") => {
  return httpService.get(
    `hotel/authorised/v1/bookings/rejection?hotelId=${hotelId}&reason=${reason}`
  );
};

export const checkIfLowestApi = (hotelId) => {
  return httpService.get(
    `hotel/authorised/v1/hotel/get-my-price-in-google?hotelId=${hotelId}&checkInDate=${moment().format(
      "YYYY-MM-DD"
    )}`
  );
};

export const availabiltyApi = (id, checkInDate, checkOutDate, timezone) => {
  return httpService.get(
    `hotel/v2/hotel/availability?id=${id}&checkInDate=${checkInDate}&checkOutDate=${checkOutDate}&timezone=${timezone}`
  );
};

export const getGMBAccountApi = () => {
  return httpService.get(`hotel/authorised/v1/googlemybusiness/account`);
};

export const postServerAuthCodeApi = (obj) => {
  return httpService.post(`hotel/authorised/v1/googlemybusiness/account`, obj);
};

export const getListOfLocationFromAccountIdApi = (accountId) => {
  return httpService.get(
    `hotel/authorised/v1/googlemybusiness/location?googleMyBusinessAccountId=${accountId}`
  );
};

export const getListOfLocationFromHotelIdApi = (hotelId) => {
  return httpService.get(
    `hotel/authorised/v1/googlemybusiness/location/hotel?hotelId=${hotelId}`
  );
};

export const getSyncSettingsFromHotelIdApi = (hotelId) => {
  return httpService.get(
    `hotel/authorised/v1/googlemybusiness/sync/settings?hotelId=${hotelId}`
  );
};

export const getAllPromotionsApi = (hotelId) => {
  return httpService.get(
    `hotel/authorized/v1/promotions/get-promotion?hotelId=${hotelId}`
  );
};

export const postSyncSettingsApi = (obj) => {
  return httpService.post(
    `hotel/authorised/v1/googlemybusiness/sync/settings`,
    obj
  );
};

export const addLocationIdToAccountApi = (obj) => {
  return httpService.post("hotel/authorised/v1/googlemybusiness/location", obj);
};

export const createGmbProfileApi = (obj) => {
  return httpService.post(
    "hotel/authorised/v1/googlemybusiness/location/create",
    obj
  );
};

export const searchGmbApi = (query) => {
  return httpService.get(
    `hotel/authorised/v1/googlemybusiness/location/search?search=${query}`
  );
};

export const uploadImageApi = (obj) => {
  return httpService.post(
    `hotel/authorised/v1/hotel/images/common-images`,
    obj
  );
};

export const getRoomTypesApi = (ln = "en") => {
  return httpService.get(`hotel/v2/room/types?ln=${ln}`);
};

export const fetchAmenitiesApi = (ln, type, category) => {
  return httpService.get(
    `hotel/v2/amenities?ln=${ln}&type=${type}&category=${category}`
  );
};

export const createHotelApi = (obj) => {
  return httpService.post(`hotel/authorised/v1/hotel`, obj);
};

export const updateHotelApi = (id, obj) => {
  return httpService.put(`hotel/authorised/v1/hotel/${id}`, obj);
};

export const regenerateTokenApi = (obj) => {
  return httpService.post("users/authorised/v1/verifyRegenrateToken", obj);
};

export const uploadHotelImagesApi = (obj) => {
  return httpService.post("hotel/authorized/v1/hotel/images/hotel-images", obj);
};

export const uploadRoomImagesApi = (obj) => {
  return httpService.post("hotel/authorized/v1/room/images/bulkImages", obj);
};

export const uploadHotelAmenitiesApi = (obj) => {
  return httpService.post(`hotel/authorised/v1/hotel/amenities/bulk`, obj);
};

export const createRoomApi = (obj) => {
  return httpService.post("hotel/authorised/v1/room", obj);
};

export const editRoomApi = (id, obj) => {
  return httpService.put(`hotel/authorised/v1/room/${id}`, obj);
};

export const descriptionGeneratorApi = (obj) => {
  return httpService.post(`hotel/authorised/v1/hotel/description-prompt`, obj);
};

export const getPlaceDetailsApi = (placeid) => {
  return httpService.get(
    `hotel/authorized/v1/hotel/place-details?placeId=${placeid}`
  );
};

export const getUserRoleApi = () => {
  return httpService.get("users/authorised/v1/userDetails");
};

export const getAllRoomsApi = (id) => {
  return httpService.get(`hotel/authorised/v2/room?hotelId=${id}`);
};

export const fetchRoomDetailsApi = (id) => {
  return httpService.get(`hotel/authorised/v1/room?id=${id}`);
};

export const updateRoomAddonsApi = (obj) => {
  return httpService.post("hotel/authorised/v1/room/addons/bulk", obj);
};

export const updateRoomAmenitiesApi = (obj) => {
  return httpService.put("hotel/authorised/v1/room/amenities/bulk", obj);
};

export const getHotelImagesApi = (id) => {
  return httpService.get(`hotel/authorized/v1/hotel/get-images?hotelId=${id}`);
};

export const getHotelAmenitiesApi = (id) => {
  return httpService.get(
    `hotel/authorized/v1/hotel/get-amenities?hotelId=${id}`
  );
};

export const downloadCSVApi = () => {
  return httpService.get(`hotel/authorized/v1/hotel/csv`);
};

export const fetchBookingsByTabApi = (id, page, tab) => {
  return httpService.get(
    `hotel/authorised/v1/bookings?tab=${tab}&page=${page}&hotelId=${id}`
  );
};

export const fetchOverviewApi = (startDate, endDate, hotelId) => {
  return httpService.get(
    `hotel/authorised/v1/hotel/homepage-report?startDate=${startDate}&endDate=${endDate}&hotelId=${hotelId}`
  );
};

export const fetchGoogleScraperPriceApi = (
  hotelId,
  checkInDate,
  checkOutDate,
  checkLive
) => {
  return httpService.get(
    `hotel/authorized/v1/hotel/get-my-price-in-google-slow?hotelId=${hotelId}&checkInDate=${checkInDate}&checkOutDate=${checkOutDate}&checkLive=${checkLive}`
  );
};

export const fetchAvailableDomainsApi = (obj) => {
  return httpService.post("hotel/authorised/v1/themes/domain/availablity", obj);
};

export const createDomainOrderApi = (obj) => {
  return httpService.post("hotel/authorised/v1/themes/domain/order", obj);
};

export const domainPaymentStatusApi = (hotelId, obj) => {
  return httpService.post(
    `payments/authorised/v1/order/status?hotelId=${hotelId}`,
    obj
  );
};

export const domainPendingOrderApi = (hotelId) => {
  return httpService.get(
    `payments/authorised/v1/order/pending-order?hotelId=${hotelId}`
  );
};

export const fetchIsHourlyEnabledApi = (roomId) => {
  return httpService.get(
    `hotel/authorised/v1/hourly/is-room-enabled?roomId=${roomId}`
  );
};

export const enableDisableHourlyApi = (obj) => {
  return httpService.put(`hotel/authorised/v1/hourly/rooms`, obj);
};

export const fetchHourlySlotsApi = (roomId) => {
  return httpService.get(
    `hotel/authorised/v1/hourly/room/slots?roomId=${roomId}`
  );
};

export const createHourlySlot = (obj) => {
  return httpService.post(`hotel/authorised/v1/hourly/room/create-slots`, obj);
};

export const updateHourlySlot = (obj) => {
  return httpService.put(`hotel/authorised/v1/hourly/slot`, obj);
};

export const fetchReviewList = (hotelId, orderBy, nextPageToken) => {
  return httpService.get(
    `hotel/authorised/v1/googlemybusiness/reviews?hotelId=${hotelId}&pageSize=10&orderBy=${orderBy}&nextPageToken=${nextPageToken}`
  );
};

export const postReviewReplyApi = (obj) => {
  return httpService.post(`hotel/authorised/v1/googlemybusiness/reviews`, obj);
};

export const deleteReviewReplyApi = (obj) => {
  return httpService.delete(
    `hotel/authorised/v1/googlemybusiness/reviews`,
    obj
  );
};

export const replyReviewWithGptApi = (obj) => {
  return httpService.post(
    `hotel/authorised/v1/googlemybusiness/reviews/generate-review-reply`,
    obj
  );
};

export const verifyOtpLessApi = (obj) => {
  return httpService.post(`users/v1/otp-less`, obj);
};

export const verifyUserAuthProviderApi = (obj) => {
  return httpService.post(`users/v1/verify-user-auth-provider`, obj);
};

export const sendOtpUpdateNumber = (obj) => {
  return httpService.post(`users/authorised/v1/send-otp-update-number`, obj);
};

export const verifyOtpUpdateNumber = (obj) => {
  return httpService.post(`users/authorised/v1/verify-otp-update-number`, obj);
};

export const updateNames = (obj) => {
  return httpService.post(`users/authorised/v1/update-names`, obj);
};

export const verifyUpdateEmailApi = (obj) => {
  return httpService.post(`users/authorised/v1/verify-update-email`, obj);
};

export const addOrUpdateFcmIdApi = (obj) => {
  return httpService.post(`users/authorized/v1/tpum/fcmId`, obj);
};

export const getSignedS3UrlApi = (fileName) => {
  return httpService.get(
    `hotel/authorised/v1/s3upload/url?fileName=${fileName}`
  );
};

export const uploadUsingSignedUrl = (url, obj) => {
  return httpService.put(url, obj);
};

export const addPostApi = (obj) => {
  return httpService.post(`hotel/authorised/v1/posts`, obj);
};

export const getPostsApi = (hotelId, page, pageSize) => {
  return httpService.get(
    `hotel/authorised/v1/posts?hotelId=${hotelId}&page=${page ?? 1}&pageSize=${
      pageSize ?? 1
    }`
  );
};

export const getLiveScraperDataApi = (obj) => {
  return httpService.post(`hotel/authorised/v1/hotel/live-scrapping`, obj);
};

export const getBookingsv2Api = (
  hotelId,
  page,
  status,
  searchBy = "",
  searchValue = ""
) => {
  return httpService.get(
    `hotel/authorised/v2/bookings?hotelId=${hotelId}&tab=${status}&page=${page}&searchBy=${searchBy}&searchValue=${searchValue}`
  );
};
export const getBookingDetailsByIdApi = (bookingId) => {
  return httpService.get(`hotel/authorised/v2/bookings/${bookingId}`);
};

export const getBookingsApi = (hotelId, tab, page) => {
  return httpService.get(
    `hotel/authorised/v2/bookings?hotelId=${hotelId}&tab=${tab}&page=${page}`
  );
};

export const getCancelInfoApi = (obj) => {
  return httpService.post(`hotel/v1/bookings/cancel/info`, obj);
};

export const fetchLiveScrappingApi = (obj) => {
  return httpService.post(`hotel/authorised/v1/hotel/live-scrapping`, obj);
};

export const getAcceptanceRateApi = (hotelId) => {
  return httpService.get(
    `hotel/authorised/v1/bookings/acceptanceRate?hotelId=${hotelId}`
  );
};

export const getPaymentDetailsApi = (bookingId) => {
  return httpService.get(
    `payments/v1/order/paymentDetails?bookingId=${bookingId}`
  );
};

export const generateDescriptionApi = (obj) => {
  return httpService.post(`hotel/authorized/v1/profile/getDescription`, obj);
};

export const addProfileApi = (obj) => {
  return httpService.post(`hotel/authorized/v1/profile/addProfile`, obj);
};

export const getProfileApi = (hotelId) => {
  return httpService.get(
    `hotel/authorized/v1/profile/getProfile?hotelId=${hotelId}`
  );
};

export const addPostAllApi = (obj) => {
  return httpService.post(`hotel/authorized/v1/posts/all`, obj);
};

export const tryPayoutApi = (obj) => {
  return httpService.post(`payments/authorised/v1/order/payout`, obj);
};

export const tryRefundApi = (obj) => {
  return httpService.post(`payments/authorised/v1/order/refund`, obj);
};

export const requestGmbAccessApi = (obj) => {
  return httpService.post(
    `hotel/authorised/v1/googlemybusiness/location/request-access`,
    obj
  );
};

export const getBookingHistoryApi = (bookingId) => {
  return httpService.get(
    `hotel/authorised/v1/bookings/bookingHistory?bookingId=${bookingId}`
  );
};

export const getRefundDetailsApi = (bookingId) => {
  return httpService.get(
    `payments/authorized/v1/order/refund?bookingId=${bookingId}`
  );
};

export const updateProfileApi = (obj) => {
  return httpService.post(`hotel/authorised/v2/profile`, obj);
};

export const getAiProfileDescriptionApi = (hotelId, ln) => {
  return httpService.get(
    `hotel/authorised/v2/profile/ai-description?hotelId=${hotelId}&ln=${ln}`
  );
};

export const getProfileNewApi = (hotelId) => {
  return httpService.get(`hotel/authorised/v2/profile?hotelId=${hotelId}`);
};

export const getOwnerByHotelId = (hotelId) => {
  return httpService.get(
    `hotel/authorized/v1/hotel/owner-by-hotel-id?hotelId=${hotelId}`
  );
};

export const getHotelInternalDataApi = (hotelId) => {
  return httpService.get(
    `hotel/authorised/v1/hotel/hotel-internal?hotelId=${hotelId}`
  );
};

export const connectToSocialApi = (obj) => {
  return httpService.post(`hotel/authorised/v1/social/connect`, obj);
};

export const getSocialAccountsApi = ({ hotelId, socialType }) => {
  console.log(hotelId);
  return httpService.get(
    `hotel/authorised/v1/social/accounts?hotelId=${hotelId}&socialType=${socialType}`
  );
};

export const downloadCSVFullApi = () => {
  return httpService.get(`hotel/authorized/v1/hotel/csv-admin`);
};

export const adsPecentageApi = (hotelId) => {
  return httpService.get(
    `hotel/authorised/v1/hotel/ads-percentage?hotelId=${hotelId}`
  );
};

export const getPromotionDetailsApi = ({ promotionId }) => {
  return httpService.get(
    `hotel/authorized/v1/promotions/promotion-for-booking?id=${promotionId}`
  );
};

export const disconnectSocialApi = ({ hotelId, socialType }) => {
  return httpService.delete(
    `/hotel/authorised/v1/social/disconnect?hotelId=${hotelId}&socialType=${socialType}`
  );
};

export const resendBookingVoucherApi = (obj) => {
  return httpService.post(
    `hotel/authorised/v1/bookings/resendBookingVoucher`,
    obj
  );
};

export const getPricingAndInventoryHistory = ({
  hotelId,
  fromDate,
  toDate,
}) => {
  return httpService.get(
    `hotel/authorized/v1/pricing_and_inventory/history?hotelId=${hotelId}&fromDate=${fromDate}&toDate=${toDate}`
  );
};

export const getRoomsPricingAndInventoryHistoryApi = ({
  hotelRoomId,
  checkInDate,
}) => {
  return httpService.get(
    `hotel/authorized/v1/pricing_and_inventory/rooms-history?hotelRoomId=${hotelRoomId}&checkInDate=${checkInDate}`
  );
};

export const getInvoicesApi = ({ hotelId, isInvoiceActive }) => {
  return httpService.get(
    `hotel/authorised/v1/hotel/invoices?hotelId=${hotelId}&isInvoiceActive=${isInvoiceActive}`
  );
};

export const removeHotelRoomAdminApi = (obj) => {
  return httpService.put(`hotel/authorized/v1/room/remove`, obj);
};
export const toggleGoogleAdsApi = (obj) => {
  return httpService.post(`hotel/authorised/v2/ads/update-ad-groups`, obj);
};

export const getBookingsCountApi = (tab) => {
  return httpService.get(`hotel/authorised/v2/bookings/count?tab=${tab}`);
};

export const getCampaignsApi = (hotelId) => {
  return httpService.get(`hotel/authorised/v1/ai?hotelId=${hotelId}`);
};

export const getAdMetricsApi = (hotelId) => {
  return httpService.get(`hotel/authorised/v1/ai/stats?hotelId=${hotelId}`);
};

export const createAdCampaignApi = (hotelId, obj) => {
  return httpService.post(
    `hotel/authorised/v1/ai/create?hotelId=${hotelId}`,
    obj
  );
};

export const getPageAndAccountDetailsApi = (hotelId) => {
  return httpService.get(
    `hotel/authorised/v1/ai/getPagesAndAccounts?hotelId=${hotelId}`
  );
};

export const submitAdCampaignApi = (hotelId, obj) => {
  return httpService.post(
    `hotel/authorised/v1/ai/submit?hotelId=${hotelId}`,
    obj
  );
};

export const addAdAssetsApi = (campaignId, obj) => {
  return httpService.post(
    `hotel/authorised/v1/ai/assets?campaignId=${campaignId}`,
    obj
  );
};

export const aiAdsLoginApi = (obj) => {
  return httpService.post(`hotel/authorised/v1/ai/login`, obj);
};

export const logoutSocialMediaApi = (hotelId) => {
  return httpService.delete(`hotel/authorised/v1/ai/logout?hotelId=${hotelId}`);
};

export const getPricingAndInventoryHistoryCreatedDt = ({
  hotelId,
  checkInDate,
  createdDt,
}) => {
  return httpService.get(
    `hotel/authorised/v1/pricing_and_inventory/history-created-dt?hotelId=${hotelId}&createdDt=${createdDt}&checkInDate=${checkInDate}`
  );
};
export const getCampaignAssetsApi = (campaignId) => {
  return httpService.get(
    `hotel/authorised/v1/ai/assets?campaignId=${campaignId}`
  );
};

export const getRevenueManagementScores = (hotelId) => {
  return httpService.get(
    `hotel/authorized/v1/revenue-management/scores?hotelId=${hotelId}`
  );
};

export const getRevenueManagementPrices = ({ hotelId, fromDate, toDate }) => {
  return httpService.get(
    `hotel/authorized/v1/revenue-management/prices?hotelId=${hotelId}&fromDate=${fromDate}&toDate=${toDate}`
  );
};

export const getRevenueManagementScrapes = ({ hotelId, fromDate, toDate }) => {
  return httpService.get(
    `hotel/authorized/v1/revenue-management/scrapes?hotelId=${hotelId}&fromDate=${fromDate}&toDate=${toDate}`
  );
};

export const getCompetitorSearch = ({ hotelId, keyword }) => {
  return httpService.get(
    `hotel/authorized/v1/revenue-management/competitor/search?hotelId=${hotelId}&keyword=${keyword}`
  );
};

export const getCompetitorData = ({ hotelId, checkInDate }) => {
  return httpService.get(
    `hotel/authorized/v1/revenue-management/competitor/data?hotelId=${hotelId}&checkInDate=${checkInDate}`
  );
};

export const getRevenueManagementStatus = ({ hotelId }) => {
  return httpService.get(
    `hotel/authorized/v1/revenue-management/status?hotelId=${hotelId}`
  );
};

export const updateRevenueManagementStatus = (obj) => {
  return httpService.put(`hotel/authorized/v1/revenue-management/status`, obj);
};

export const addCompetitor = (obj) => {
  return httpService.put(
    `hotel/authorized/v1/revenue-management/competitor`,
    obj
  );
};

export const isUserLoggedinApi = (id) => {
  return httpService.get(`hotel/authorised/v1/ai/userdata?hotelId=${id}`);
};

export const metaLoginApi = (obj) => {
  return httpService.post("hotel/authorised/v1/ai/login", obj);
};

export const googleLoginApi = (obj) => {
  return httpService.post(
    "hotel/authorised/v2/ads/createOrUpdateGoogleAdAccount",
    obj
  );
};

export const getHotelsWithoutIdentifier = ({
  page,
  pageSize,
  hotelId = "",
}) => {
  return httpService.get(
    `hotel/authorized/v1/revenue-management/competitor/without-identifier?page=${page}&pageSize=${pageSize}&hotelId=${hotelId}`
  );
};

export const attachIdentifierToHotel = (obj) => {
  return httpService.put(
    `hotel/authorized/v1/revenue-management/competitor/identifier`,
    obj
  );
};

export const getAdsAccountLoggedInApi = (hotelId) => {
  return httpService.get(
    `hotel/authorised/v1/ai/getPagesAndAccounts?hotelId=${hotelId}`
  );
};

export const createAdsCampaignApi = (hotelId, obj) => {
  return httpService.post(
    `hotel/authorised/v1/ai/create?hotelId=${hotelId}`,
    obj
  );
};

export const postAdCreativesApi = (campaignId, obj) => {
  return httpService.post(
    `hotel/authorised/v1/ai/assets?campaignId=${campaignId}`,
    obj
  );
};

export const getAdSuggestionsApi = (hotelId) => {
  return httpService.get(
    `hotel/authorised/v1/ai/getAssetSuggestion?hotelId=${hotelId}`
  );
};

export const postAdTargetsApi = (hotelId, obj) => {
  return httpService.post(
    `hotel/authorised/v1/ai/submit?hotelId=${hotelId}`,
    obj
  );
};

export const getCampaignsListApi = (hotelId) => {
  return httpService.get(`hotel/authorised/v1/ai?hotelId=${hotelId}`);
};

export const getOverallCampaignApi = (obj) => {
  return httpService.get(`hotel/authorised/v1/ai/stats`, obj);
};

export const getStatsByAgeApi = (obj) => {
  return httpService.get("hotel/authorised/v1/ai/stats-by-age", obj);
};

export const getStatsByGenderApi = (obj) => {
  return httpService.get("hotel/authorised/v1/ai/stats-by-gender", obj);
};

export const getStatsByDevicesApi = (obj) => {
  return httpService.get("hotel/authorised/v1/ai/stats-by-device", obj);
};

export const getStatsForGraphApi = (obj) => {
  return httpService.get("hotel/authorised/v1/ai/stats-by-graph", obj);
};

export const getCreatomateTemplateApi = () => {
  return httpService.get("hotel/authorised/v1/ai/templates?type=image");
};

export const logoutAiAdsApi = (hotelId, type) => {
  return httpService.delete(
    `hotel/authorised/v1/ai/logout?hotelId=${hotelId}&type=${type}`
  );
};

export const getUserLocationApi = (obj) => {
  return httpService.get("hotel/authorised/v1/ai/location-info", obj);
};

export const getTemplatePreviewApi = (templateId, url) => {
  return httpService.get(
    `hotel/authorised/v1/ai/adcreative-snapshot?templateId=${templateId}&image=${url}`
  );
};

export const uploadCustomerDataApi = (obj) => {
  return httpService.post("hotel/authorised/v1/ai/uploadCustomerData", obj);
};

export const getHotDatesApi = (obj) => {
  return httpService.get("hotel/authorised/v1/ai/hotdates", obj);
};

export const getIsEnabledChannex = ({ hotelId }) => {
  return httpService.get(
    `hotel/authorised/v1/channex/is-enabled?hotelId=${hotelId}`
  );
};

export const enableChannex = (obj) => {
  return httpService.put(`hotel/authorised/v1/channex/enable`, obj);
};

export const getOneTimeTokenChannex = ({ hotelId }) => {
  return httpService.get(
    `hotel/authorised/v1/channex/one-time-token?hotelId=${hotelId}`
  );
};

export const getWebsiteSectionsApi = (hotelId) => {
  return httpService.get(
    `hotel/authorised/v1/hotel/custom-website-sections?hotelId=${hotelId}`
  );
};

export const updateWebsiteSectionsApi = (obj) => {
  return httpService.post(
    "hotel/authorised/v1/hotel/bulk-update-website-section",
    obj
  );
};

export const generateRoomDescriptionApi = (obj) => {
  return httpService.post(
    "hotel/authorised/v1/hotel/gpt-room-description",
    obj
  );
};

export const imporveSectionDescriptionApi = (obj) => {
  return httpService.post(
    "hotel/authorised/v1/hotel/gpt-custom-section-description",
    obj
  );
};

export const getNewLeadsApi = (obj) => {
  return httpService.get("hotel/authorized/v1/hotel/leads", obj);
};

export const unlockLeadApi = (obj) => {
  return httpService.put(`hotel/authorized/v1/hotel/leads/unlocked`, obj);
};

export const getLeadById = (typeId) => {
  return httpService.get(`hotel/authorized/v1/hotel/lead?typeId=${typeId}`);
};

export const getScraperDataApi = (id) => {
  return httpService.get(
    `hotel/authorised/v1/customer-success/scraper-config?hotelId=${id}`
  );
};

export const postScraperDataApi = (obj) => {
  return httpService.post(
    "hotel/authorised/v1/customer-success/scraper-config",
    obj
  );
};

export const isCallEnabledApi = (hotelId) => {
  return httpService.get(
    `hotel/v1/videoCalling/isCallEnabled?hotelId=${hotelId}`
  );
};

export const getUnlockedLeadsApi = (hotelId) => {
  return httpService.get(
    `hotel/authorised/v1/hotel/leads/unlocked-once?hotelId=${hotelId}`
  );
};

export const deleteAccountApi = (hotelId, reason) => {
  const requestBody = {
    hotelId: hotelId,
    reason: reason,
  };
  return httpService.post(
    "/hotel/authorised/v1/hotel/delete-account",
    requestBody
  );
};

export const getHotelRoomsRateplans = (hotelId) => {
  return httpService.get(
    `/hotel/authorised/v1/hotel/rooms-rateplans?hotelId=${hotelId}`
  );
};

export const getPromotionsAdminApi = ({
  hotelId,
  isExpired = false,
  page = 1,
  pageSize = 12,
}) => {
  return httpService.get(
    `hotel/authorized/v2/promotions/promotions-admin?hotelId=${hotelId}&isExpired=${isExpired}&page=${page}&pageSize=${pageSize}`
  );
};

export const getPromotionAdminApi = (promotionId) => {
  return httpService.get(
    `hotel/authorized/v2/promotions/promotion-admin?promotionId=${promotionId}`
  );
};

export const canUpdateInventoryApi = (obj) => {
  return httpService.post(`hotel/authorised/v1/hotel/canUpdateInventory`, obj);
};

export const addTaxesApi = (obj) => {
  return httpService.post(`payments/authorized/v1/tax`, obj);
};
export const getTaxesApi = (
  hotelId,
  page = 1,
  pageSize = 12,
  getDefaultTaxes = true
) => {
  return httpService.get(
    `payments/v1/tax/list?hotelId=${hotelId}&page=${page}&pageSize=${pageSize}&getDefaultTaxes=${getDefaultTaxes}`
  );
};

export const updateTaxApi = (obj) => {
  return httpService.put(`payments/authorized/v1/tax`, obj);
};

export const getTaxByIdApi = (taxId) => {
  return httpService.get(`payments/authorized/v1/tax?taxId=${taxId}`);
};

export const postRatePlanApi = (obj) => {
  return httpService.post(`hotel/daas/v1/hyperguest/get-hotel-ari`, obj);
};

export const postTaxSetApi = (taxes) => {
  return httpService.put("/payments/authorized/v1/tax", taxes);
};

export const deleteTaxApi = (taxId) => {
  return httpService.put(`payments/authorized/v1/tax/remove-tax`, taxId);
};

export const isTaxIncludedApi = (obj) => {
  return httpService.post(
    `hotel/authorised/v1/hotel/toggle-is-tax-included`,
    obj
  );
};

export const getBookingCountHotelwiseApi = () => {
  return httpService.get(`hotel/authorized/v1/bookings/count-hotel-wise`);
};
