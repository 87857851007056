import manageTicketTypes from "../../actionTypes/manageTicketTypes";

const initialState = {
  ticketList: [],
  ticketDetails: {},
  fetching: false,
  actionFetching: false,
  pagination: { pageNo: 1, pages: 1 },
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case manageTicketTypes.SET_LIST: {
      return { ...state, ticketList: action.payload };
    }

    case manageTicketTypes.DETAILS: {
      return { ...state, ticketDetails: action.payload };
    }

    case manageTicketTypes.FETCHING: {
      return { ...state, fetching: action.payload };
    }

    case manageTicketTypes.ACTION_FETCHING: {
      return { ...state, actionFetching: action.payload };
    }

    case manageTicketTypes.PAGINATION: {
      return { ...state, pagination: action.payload };
    }

    default: {
      return { ...state };
    }
  }
};
