const apaylosTypes = {
  FETCHING: "APAYLO_LIST_FETCHING",
  ACTION_FETCHING: "APAYLO_ACTION_FETCHING",
  SET_LIST: "SET_APAYLO_LIST",
  DETAILS: "SET_APAYLO_DETAILS",
  PAGINATION: "APAYLO_LIST_PAGINATION",
  MORE_DETAILS: "MORE_DETAILS",
};

export default apaylosTypes;
