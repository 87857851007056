const appConfigTypes = {
  EXCHANGE_FETCHING: "EXCHANGE_FETCHING",
  SET_EXCHANGE_DETAILS: "SET_EXCHANGE_DETAILS",

  NEWS_FETCHING: "NEWS_FETCHING",
  SET_NEWS_DETAILS: "SET_NEWS_DETAILS",

  INTEREST_FETCHING: "INTEREST_FETCHING",
  SET_INTEREST_DETAILS: "SET_INTEREST_DETAILS",

  REFERRAL_FETCHING: "REFERRAL_FETCHING",
  SET_REFERRAL_DETAILS: "SET_REFERRAL_DETAILS",

  CONTACT_FETCHING: "CONTACT_FETCHING",
  SET_CONTACT_DETAILS: "SET_CONTACT_DETAILS",

  SOCIAL_FETCHING: "SOCIAL_FETCHING",
  SET_SOCIAL_DETAILS: "SET_SOCIAL_DETAILS",

  MIN_LIMIT_FETCHING: "MIN_LIMIT_FETCHING",
  SET_MIN_LIMIT_DETAILS: "SET_MIN_LIMIT_DETAILS",

  WITHDRAW_FETCHING: "WITHDRAW_FETCHING",
  SET_WITHDRAW_DETAILS: "SET_WITHDRAW_DETAILS",
};

export default appConfigTypes;
