import { policyTypes } from "../../../actionTypes/policyTypes";

const initialState = {
  fetching: false,
  houseRules: [],
  cancellationPolicy: [],
};

export default (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case policyTypes.FETCHING: {
      return { ...state, fetching: payload };
    }

    case policyTypes.SET_HOUSE_RULES: {
      return { ...state, houseRules: payload };
    }

    case policyTypes.SET_CANCELLATION_POLICY: {
      return { ...state, cancellationPolicy: payload };
    }

    default: {
      return { ...state };
    }
  }
};
