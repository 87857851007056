import complysTypes from "../../actionTypes/complyAdvantageTypes";

const initialState = {
  complysList: [],
  complyDetails: {},
  fetching: false,
  actionFetching: false,
  pagination: { pageNo: 1, pages: 1 },
  documents: [],
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case complysTypes.SET_LIST: {
      return { ...state, complysList: action.payload };
    }

    case complysTypes.DETAILS: {
      return { ...state, complyDetails: action.payload };
    }

    case complysTypes.FETCHING: {
      return { ...state, fetching: action.payload };
    }

    case complysTypes.ACTION_FETCHING: {
      return { ...state, actionFetching: action.payload };
    }

    case complysTypes.PAGINATION: {
      return { ...state, pagination: action.payload };
    }

    case complysTypes.SET_DOCUMENTS: {
      return { ...state, documents: action.payload };
    }

    case complysTypes.CLEAR_DOCUMENTS: {
      return { ...state, documents: [] };
    }

    default: {
      return { ...state };
    }
  }
};
