const permissionsObj = {
  dashboard: "dashboard",
  settlements: "settlements",
  addStaff: "add_staff",
  manage: ["social", "paymentconfig", "report", "add_logo"],
  faq: "faq",
  gads: "gads",
  gmb: "gmb",
  addProperty: "add_property",
  promo: "promo",
  bookings: "bookings",
  users: "users",
  allImage: "allImage",
  hoteImage: "hotelImage",
  hotelAddons: "hotelAddons",
  policy: ["houseRules", "cancellationPolicy"],
  inventoryManagement: "inventoryManagement",
  manageUsers: ["customers", "manage_kyc", "manage_payee"],
  manageTransaction: [
    ["withdrawal_management", "deposit_management", "wallet_management"],
    "edit_limit",
  ],
  manageContents: [
    "manage_banner",
    "announcement",
    "manage_app_config",
    "manage_ticket",
    "feedbacks",
    "reject_reason",
  ],
  walleteBalanceUpdate: "wallete_balance_update",
  blockUnblockUser: "block_unblock_user",
  requestDocuments: "request_documents",
  kycUpdate: "kyc_update",
  managePayee: "manage_payee",
  withdrawalManagement: "withdrawal_management",
  depositManagement: "deposit_management",
  walletManagement: "wallet_management",
  editLimit: "edit_limit",
  manageBanner: "manage_banner",
  announcement: "announcement",
  manageAppConfig: "manage_app_config",
  manageTicket: "manage_ticket",
  feedbacks: "feedbacks",
  rejectReason: "reject_reason",
};

export default permissionsObj;
