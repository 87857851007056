import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";
import storageService from "./services/storageServices";
import { v4 as uuid } from "uuid";

mixpanel.init("78069d76d871d74660b9e84625d26ba8");

ReactGA.initialize("G-1XC6Q6RMGL");

const user = storageService.get("userAdmin");

if (window && user?.userId) {
  window.plotline(
    "init",
    "MjZkYTVmYjEtMGJjZi00ZTE5LTk3NWQtNjA2NzRjMDE0NTJh",
    user.userId
  );
}

const eventTrack = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};

export const trackEvent = (name, data = {}) => {
  let user = storageService.get("userAdmin") || {};
  let hotelId = storageService.get("id") || 0;
  let hotel = storageService.get("selectedHotel") || {};
  if (!user?.userId) {
    return;
  }
  if (user) {
    user?.token && delete user?.token;
    user?.regenrateToken && delete user?.regenrateToken;
  }

  

  let obj = {
    customData: data,
    ...user,
    hotelId,
    url: window.location.host,
    hotelName: hotel?.name,
  };

  const host = window.location.host;

  if (host.includes("dev-extranet") || host.includes("localhost")) {
    return;
  }
  mixpanel.track(name, obj);
  window !== undefined && window.plotline("track", `${name}_web`, obj);
};

const pageView = (page) => {
  ReactGA.send({ hitType: "pageview", page: page });
};

export default { pageView, eventTrack };
