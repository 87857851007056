// import React from "react";
// import { useSelector } from "react-redux";
// import { useDispatch } from "react-redux";
// import { fetchBookingCount } from "../../../../redux/actions/NEWDASHBOARD/bookings";
// import { fetchBookingCountHotelWise } from "../../../../redux/actions/NEWDASHBOARD/bookings";
// import { useEffect } from "react";
// import { useTranslation } from "react-i18next";
// import { Link } from "react-router-dom";
// import { MdArrowOutward } from "react-icons/md";

// const BookingCountBanner = () => {
//   const { selectedHotel } = useSelector((state) => state.hotels);
//   const { bookingCount } = useSelector((state) => state.bookings);
//   const dispatch = useDispatch();
//   const { t } = useTranslation();
//   const bookingText = bookingCount > 1 ? t("bookings") : t("booking");

//   const getBookingCount = () => {
//     dispatch(fetchBookingCount("New"));
//     dispatch(fetchBookingCountHotelWise());
//   };

//   useEffect(() => {
//     selectedHotel?.id && getBookingCount();
//   }, [selectedHotel?.id]);

//   const bannerStyle = {
//     //backgroundColor: "#E97451",
//     transform: "translateY(-5px)",
//     backgroundColor: "#ea5455",
//     outline: "2px solid #ea5455",
//     color: "#fff",
//     padding: "0px 50px",
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//     //borderRadius: "10px",
//     boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
//   };

//   const textContainerStyle = {
//     display: "flex",
//     flexDirection: "column",
//   };

//   const headingStyle = {
//     fontWeight: "bold",
//     fontSize: "16px",
//   };

//   const descriptionStyle = {
//     fontSize: "14px",
//   };

//   const buttonStyle = {
//     backgroundColor: "#fff",
//     color: "#f44336",
//     border: "none",
//     // padding: "10px 20px",
//     borderRadius: "4px",
//     cursor: "pointer",
//     fontWeight: "bold",
//   };

//   return bookingCount > 0 ? (
//     <div style={bannerStyle}>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "space-evenly",
//           width: "100%",
//         }}
//       >
//         {/* <div style={headingStyle}>{t("new_booking_alert")}</div> */}
//         <div style={descriptionStyle}>
//           {t("youve_got")} {bookingCount} {bookingText}
//         </div>
//         <Link to="/bookings">
//           <button style={buttonStyle}>
//             {t("view_bookings")} <MdArrowOutward />
//           </button>
//         </Link>
//       </div>
//     </div>
//   ) : null;
// };

// export default BookingCountBanner;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { MdArrowOutward } from "react-icons/md";
import { useHistory } from "react-router-dom";
import {
  fetchBookingCount,
  fetchBookingCountHotelWise,
} from "../../../../redux/actions/NEWDASHBOARD/bookings";
import { FaRegStar } from "react-icons/fa";
import { trackEvent } from "../../../../analytics";

const BookingCountBanner = () => {
  const { selectedHotel } = useSelector((state) => state.hotels);
  const { bookingCount } = useSelector((state) => state.bookings);
  //const { bookingCountHotelWise } = useSelector((state) => state.bookings);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  // const totalBookingCount =
  //   bookingCountHotelWise?.reduce((total, count) => total + count.count, 0) ||
  //   0;
  // console.log("booking", totalBookingCount);

  const bookingText = bookingCount > 1 ? t("bookings") : t("booking");
  // Fetch booking counts
  const getBookingCount = () => {
    dispatch(fetchBookingCount("New"));
    //     dispatch(fetchBookingCountHotelWise());
  };

  useEffect(() => {
    if (selectedHotel?.id) {
      getBookingCount();
    }
  }, [selectedHotel?.id]);

  // Style definitions
  const bannerStyle = {
    transform: "translateY(-5px)",
    backgroundColor: "#ea5455",
    outline: "2px solid #ea5455",
    color: "#fff",
    padding: "0px 50px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  };

  const descriptionStyle = {
    fontSize: "14px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    margin: "0 50px",
  };

  const buttonStyle = {
    backgroundColor: "#fff",
    color: "black",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontWeight: "bold",
    alignItems: "center",
    padding: "2px",
  };

  return bookingCount > 0 ? (
    <div style={bannerStyle}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          alignItems: "center",
          padding: "5px 0",
        }}
      >
        <div
          style={{
            ...descriptionStyle,

            display: "flex",
            alignItems: "center",
          }}
        >
          <FaRegStar
            size={20}
            style={{
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 10px",
            }}
          />
          <p
            style={{
              marginTop: "none",

              margin: "0 ",
            }}
          >
            {t("youve_got")}
            <span style={{ fontWeight: "bold" }}>
              {" "}
              {bookingCount} {""}
              {t("new")} {""} {bookingText}
            </span>
          </p>
        </div>
        <Link
          to={`/bookings`}
          style={buttonStyle}
          onClick={() => trackEvent("clicked_on_booking_banner", bookingCount)}
        >
          {t("view_bookings")} <MdArrowOutward />
        </Link>
      </div>
    </div>
  ) : null;
};

export default BookingCountBanner;
