import bannersTypes from "../../actionTypes/bannersTypes";

const initialState = {
  bannersList: [],
  bannerDetails: {},
  fetching: false,
  pagination: { pageNo: 1, pages: 1 },
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case bannersTypes.SET_LIST: {
      return { ...state, bannersList: action.payload };
    }

    case bannersTypes.DETAILS: {
      return { ...state, bannerDetails: action.payload };
    }

    case bannersTypes.FETCHING: {
      return { ...state, fetching: action.payload };
    }

    case bannersTypes.PAGINATION: {
      return { ...state, pagination: action.payload };
    }

    default: {
      return { ...state };
    }
  }
};
