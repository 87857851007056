import onboardingTypes from "../../../actionTypes/onboardingTypes";

const initialState = {
  places: [],
  fetching: false,
  hotelTypes: [],
  hotelAmenities: [],
  roomAmenities: [],
};

export default (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case onboardingTypes.FETCHING: {
      return { ...state, fetching: payload };
    }
    case onboardingTypes.SET_PLACES_SUGGESTION: {
      return { ...state, places: [...payload] };
    }
    case onboardingTypes.SET_HOTEL_AMENITIES: {
      return { ...state, hotelAmenities: [...payload] };
    }
    case onboardingTypes.SET_HOTEL_TYPES: {
      return { ...state, hotelTypes: [...payload] };
    }
    case onboardingTypes.SET_ROOM_AMENITIES: {
      return { ...state, roomAmenities: [...payload] };
    }
    default: {
      return { ...state };
    }
  }
};
