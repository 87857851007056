import { Autocomplete, Badge, TextField } from "@mui/material";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Navbar, NavItem, NavLink } from "reactstrap";
import userImg from "../../../assets/img/portrait/small/avatar-s-11.jpg";
import { fetchHotels } from "../../../redux/actions/NEWDASHBOARD/hotels";
import hotelTypes from "../../../redux/actionTypes/hotelTypes";

import { StarBorderOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { trackEvent } from "../../../analytics";
import { routes } from "../../../component_route/Index";
import { fetchBookingsByStatus } from "../../../redux/actions/NEWDASHBOARD/bookings";
import {
  fetchBookingsByStatusApi,
  fetchHotelsApi,
  fetchUsersByNameApi,
  getGMBAccountApi,
  getUserRoleApi,
  isCallEnabledApi,
  getAdsSubscribedApi,
  getUnlockedLeadsApi,
  unlockLeadApi,
} from "../../../services/newServices";
import storageService from "../../../services/storageServices";
import NavbarUser from "./NavbarUser";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import BookingCountBanner from "../../../views/pages/NEWDASHBOARD/BookingCountBanner/BookingCountBanner";

const ThemeNavbar = (props) => {
  const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"];
  const navbarTypes = ["floating", "static", "sticky", "hidden"];
  const dispatch = useDispatch();
  const { hotels = [], selectedHotel } = useSelector((state) => state.hotels);
  // const { bookingsList } = useSelector((state) => state.bookings);

  const history = useHistory();
  const location = useLocation();

  const [bookingsList, setBookingsList] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const [isGmbEnabled, setIsGmbEnabled] = useState(false);
  const [isVideoCallEnabled, setIsVideoCallEnabled] = useState(false);
  const [isAdsEnabled, setIsAdsEnabled] = useState(false);
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [sendEvent, setSendEvent] = useState(false);

  const [timeOutId, setTimeOutId] = useState(null);
  const [adminList, setAdminList] = useState([]);

  const [value, setValue] = useState(selectedHotel && selectedHotel);

  useEffect(() => {
    dispatch(fetchHotels());
  }, []);

  useEffect(() => {
    let userAdmin = storageService.get("userAdmin");

    const { permission = [], role = "HOTEL_OWNER" } = userAdmin;
    if (!permission?.includes("gads") || !permission?.includes("gmb")) {
      let arr = [...permission];
      arr.push("gads");
      arr.push("gmb");
      let admin = { ...userAdmin, permission: arr };
      storageService.set("userAdmin", admin);
    }
    if (role === "ADMIN") {
      setIsAdmin(true);
    } else if (role !== "ADMIN") {
      setIsAdmin(false);
    }
  }, []);

  useEffect(() => {
    selectedHotel &&
      setValue({ label: selectedHotel?.name, value: selectedHotel });
    // setValue({ ...value, label: selectedHotel?.name, value: selectedHotel });
  }, [selectedHotel]);

  const handleChange = async (hotel, newHotel) => {
    if (newHotel) {
      const { value } = newHotel;
      setValue(newHotel);
      dispatch({ type: hotelTypes.FETCHING, payload: true });

      console.log("valala", value);

      const resp2 = await fetchHotelsApi(value?.id);
      console.log("resp2nav", resp2);

      const roleResp = await getUserRoleApi();
      if (roleResp?.status === 200) {
        let roleName = roleResp?.data?.Role?.name;
        let role = roleResp?.data?.Role;

        let userAdmin = storageService.get("userAdmin") || {};
        userAdmin = { ...userAdmin, role: roleName, roleInfo: role };
        storageService.set("userAdmin", userAdmin);
      }

      dispatch({
        type: hotelTypes.SET_SELECTED_HOTEL,
        payload: resp2?.data[0],
      });

      // dispatch({ type: hotelTypes.SET_SELECTED_HOTEL, payload: value });
      // dispatch({ type: hotelTypes.SET_SELECTED_HOTEL, payload: hotel });

      // storageService.set("id", hotel?.id);
      // storageService.set("selectedHotel", hotel);
      storageService.set("id", resp2?.data[0]?.id);
      storageService.set("selectedHotel", resp2?.data[0]);

      dispatch({ type: hotelTypes.FETCHING, payload: false });
    }
  };

  const handleChange2 = async (hotel, newHotel) => {
    if (newHotel) {
      const { value } = newHotel;
      setValue(newHotel);
      dispatch({ type: hotelTypes.FETCHING, payload: true });

      console.log("val", value);

      // const resp2 = await fetchMyHotelsApi(value?.id);

      dispatch({
        type: hotelTypes.SET_SELECTED_HOTEL,
        payload: value,
      });

      // dispatch({ type: hotelTypes.SET_SELECTED_HOTEL, payload: value });
      // dispatch({ type: hotelTypes.SET_SELECTED_HOTEL, payload: hotel });

      // storageService.set("id", hotel?.id);
      // storageService.set("selectedHotel", hotel);

      const roleResp = await getUserRoleApi();
      if (roleResp?.status === 200) {
        let roleName = roleResp?.data?.Role?.name;
        let role = roleResp?.data?.Role;

        let userAdmin = storageService.get("userAdmin") || {};
        userAdmin = { ...userAdmin, role: roleName, roleInfo: role };
        storageService.set("userAdmin", userAdmin);
      }

      dispatch({
        type: hotelTypes.SET_SELECTED_HOTEL,
        payload: value,
      });

      storageService.set("id", value?.id);
      storageService.set("selectedHotel", value);

      dispatch({ type: hotelTypes.FETCHING, payload: false });
    }
  };

  useEffect(() => {
    if (selectedHotel?.id && window && !isAdmin) {
      let userAdmin = storageService.get("userAdmin") || {};
      window.usetifulTags = {
        hotel_id: `${selectedHotel?.id}`,
        role: `${userAdmin?.role}`,
        user_id: `${userAdmin?.userId}`,
      };
    }
  }, [selectedHotel]);

  useEffect(() => {
    if (inputValue?.length > 0 && isAdmin) {
      onSearchChange();
    }
  }, [inputValue]);

  const onSearchChange = () => {
    if (inputValue?.length > 0) {
      clearTimeout(timeOutId);
      const newTimeOutId = setTimeout(() => {
        fetchUsersList(inputValue);
      }, 500);
      setTimeOutId(newTimeOutId);
    }
  };

  const fetchUsersList = async () => {
    const body = {
      name: inputValue,
      page: 1,
    };
    const response = await fetchUsersByNameApi(inputValue, 1);

    setAdminList(response?.data);
  };

  useEffect(() => {
    if (selectedHotel?.HotelRooms?.length === 0) {
      toast.error("Please create a room to continue");
      history.push(routes.addRoom);
    }
  }, [selectedHotel?.HotelRooms, location.pathname]);

  const checkGMBF = async () => {
    const response = await getGMBAccountApi();
    if (response?.status === 200 && response?.data?.id && window) {
      storageService.set("gmbConnect", true);
      setIsGmbEnabled(true);
      console.log("is gmb enabled?", isGmbEnabled);
      // window.usetifulTags = {
      //   userId: `${user?.userId}`,
      //   language: `${storageService.get("lang")}` || "en",
      //   role: `${user?.role}`,
      //   hotel_id: `${storageService.get("selectedHotel")?.id}`,
      //   gmb_connected: `true`,
      // };
    } else if (window && !response?.data?.id) {
      setIsGmbEnabled(false);
      // window.usetifulTags = {
      //   userId: `${user?.userId}`,
      //   language: `${storageService.get("lang")}` || "en",
      //   role: `${user?.role}`,
      //   hotel_id: `${storageService.get("selectedHotel")?.id}`,
      //   gmb_connected: `false`,
      // };
    } else {
      setIsGmbEnabled(null);
      // window.usetifulTags = {
      //   userId: `${user?.userId}`,
      //   language: `${storageService.get("lang")}` || "en",
      //   role: `${user?.role}`,
      //   hotel_id: `${storageService.get("selectedHotel")?.id}`,
      //   gmb_connected: `null`,
      // };
    }
  };

  const callEnabled = async () => {
    const hotelId = selectedHotel && selectedHotel?.id;
    const callEnabledResponse = await isCallEnabledApi(hotelId);
    if (
      callEnabledResponse?.status === 200 &&
      callEnabledResponse?.data === 1
    ) {
      setIsVideoCallEnabled(true);
    } else {
      setIsVideoCallEnabled(false);
    }
  };
  const adsSubscribed = async () => {
    const hotelId = selectedHotel && selectedHotel?.id;
    const adsSubscribedResponse = await getAdsSubscribedApi(hotelId);
    if (
      adsSubscribedResponse?.status === 200 &&
      adsSubscribedResponse?.data?.length < 1
    ) {
      setIsAdsEnabled(true);
    } else {
      setIsAdsEnabled(false);
    }
  };
  const leadsUnlocked = async () => {
    const hotelId = selectedHotel && selectedHotel?.id;
    const leadsResponse = await getUnlockedLeadsApi(hotelId);
    if (
      leadsResponse?.status === 200 &&
      leadsResponse?.hasUnlockedOnce === true
    ) {
      setIsUnlocked(true);
    } else {
      setIsUnlocked(false);
    }
  };

  useEffect(() => {
    const checkGMB = async () => {
      const funcs = [checkGMBF, callEnabled, adsSubscribed, leadsUnlocked];

      funcs.forEach((fn) => fn());

      setSendEvent(true);
    };

    selectedHotel?.id && checkGMB();
  }, [selectedHotel?.id]);

  useEffect(() => {
    if (sendEvent && window) {
      const user = storageService.get("userAdmin");
      window.usetifulTags = {
        userId: `${user?.userId}`,
        language: `${storageService.get("lang")}` || "en",
        role: `${user?.role}`,
        hotel_id: `${storageService.get("selectedHotel")?.id}`,
        gmb_connected: `true`,
        is_video_call_enabled: `${isVideoCallEnabled}`,
        is_ads_connected: `${isAdsEnabled}`,
        is_leads_unlocked: `${isUnlocked}`,
      };
    }
  }, [sendEvent]);

  return (
    <React.Fragment>
      <div className="content-overlay" />
      {/* className="header-navbar-shadow"  */}
      <div />

      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light":
              props.navbarColor === "default" ||
              !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) ||
              (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            scrolling: props.horizontal && props.scrolling,
          }
        )}
      >
        <div className="navbar-wrapper">
          <BookingCountBanner  />
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="bookmark-wrapper">
                <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                  <ul className="navbar-nav d-xl-none">
                    <NavItem className="mobile-menu mr-auto">
                      <NavLink
                        className="nav-menu-main menu-toggle hidden-xs is-active"
                        onClick={props.sidebarVisibility}
                      >
                        <Icon.Menu className="ficon" />
                      </NavLink>
                    </NavItem>
                  </ul>
                  {/* <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggle}
                    direction="down"
                  >
                    <DropdownToggle
                      caret
                      color="primary"
                      direction="down"
                      outline
                    >
                      {" "}
                      {selectedHotel?.name}{" "}
                      <Icon.ArrowDown height={20} width={20} />
                    </DropdownToggle>
                    <DropdownMenu className="overrideDropdown">
                      {hotels?.map((hot) => (
                        <DropdownItem onClick={() => handleChange(hot)}>
                          {hot?.name}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown> */}

                  {selectedHotel && !isAdmin && (
                    <Autocomplete
                      id="combo-box-demo-hotel"
                      inputValue={inputValue}
                      value={value}
                      onInputChange={(e, val) => setInputValue(val)}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      options={hotels?.map((hot) => {
                        const obj = {
                          label: `${hot.name} (${hot.id})`,
                          value: hot,
                        };
                        return obj;
                      })}
                      onChange={handleChange}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          defaultValue={selectedHotel?.name}
                          placeholder="Enter Hotel Name"
                        />
                      )}
                    />
                  )}
                  {isAdmin && (
                    <Autocomplete
                      id="combo-box-demo-admin"
                      inputValue={inputValue}
                      value={value}
                      onInputChange={(e, val) => setInputValue(val)}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      options={adminList?.map((hot) => {
                        const obj = {
                          label: `${hot.name} (${hot.id})`,
                          value: hot,
                        };
                        return obj;
                      })}
                      onChange={handleChange2}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          defaultValue={selectedHotel?.name}
                          placeholder="Enter Hotel Name"
                        />
                      )}
                    />
                  )}
                </div>
              </div>

              {props.horizontal ? (
                <div className="logo d-flex align-items-center">
                  <div className="brand-logo mr-50"></div>
                  <h2 className="text-primary brand-text mb-0">Hotelzify</h2>
                </div>
              ) : null}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <NavbarUser
                  handleAppOverlay={props.handleAppOverlay}
                  changeCurrentLang={props.changeCurrentLang}
                  userName=""
                  userImg={userImg}
                />
              </div>
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  );
};

export default ThemeNavbar;
