const userTypes = {
  FETCHING: "USER_FETCHING",
  ACTION_FETCHING: "USER_ACTION_FETCHING",
  SET_LIST: "SET_USER",
  DETAILS: "SET_USER_DETAILS",
  PAGINATION: "USER_PAGINATION",
  FETCH_IMAGE: "FETCH_IMAGE",
  FETCH_HOTEL_IMAGE: "FETCH_HOTEL_IMAGE",
  SETTLE_HOTEL_LIST: "SETTLE_HOTEL_LIST",
  SETTLE_HOTEL_ID: "SETTLE_HOTEL_ID",
  FETCH_BOOKING_ID: "FETCH_BOOKING_ID",
  SET_USER: "AUTH_SET_USER"
};

export default userTypes;
