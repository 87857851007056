import SET_UTM_SOURCE from "../../../actionTypes/utmSourceTypes";

const initialState = {
  utm_source: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_UTM_SOURCE:
      return {
        ...state,
        utm_source: action.payload,
      };
    default:
      return state;
  }
};
