const manageTypes = {
  FETCHING: "FETCHING_MANAGE",
  SET_SOCIAL_URLS: "SET_SOCIAL_URLS",
  FETCH_REPORT: "FETCH_REPORT",
  SET_WHATSAPP: "SET_WHATSAPP",
  FETCH_GATEWAYS: "FETCH_GATEWAYS",
  FETCH_SELECTED_GATEWAYS: "FETCH_SELECTED_GATEWAYS",
  SET_BANK: "SET_BANK",
  SET_PARTIAL_PERCENTAGE: "SET_PARTIAL_PERCENTAGE",
  SET_LOGO: "SET_LOGO",
  FETCH_FAQ: "FETCH_FAQ",
  UPDATE_FAQ: "UPDATE_FAQ",
  FETCH_UNANSWERED_FAQ: "FETCH_UNANSWERED_FAQ",
  FETCH_FAQ_SUGGESTION: "FETCH_FAQ_SUGGESTION",
  FETCH_PROMOTIONS: "FETCH_PROMOTIONS",
  DELETE_PROMOTIONS: "DELETE_PROMOTIONS",
  ADD_PROMOTIONS: "ADD_PROMOTIONS",
};

export default manageTypes;
