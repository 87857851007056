import apaylosTypes from "../../actionTypes/apayloTypes";

const initialState = {
  apaylosList: [],
  apayloDetails: {},
  fetching: false,
  actionFetching: false,
  pagination: { pageNo: 1, pages: 1 },

  moreDetails: {},
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case apaylosTypes.SET_LIST: {
      return { ...state, apaylosList: action.payload };
    }

    case apaylosTypes.DETAILS: {
      return { ...state, apayloDetails: action.payload };
    }

    case apaylosTypes.FETCHING: {
      return { ...state, fetching: action.payload };
    }

    case apaylosTypes.ACTION_FETCHING: {
      return { ...state, actionFetching: action.payload };
    }

    case apaylosTypes.PAGINATION: {
      return { ...state, pagination: action.payload };
    }

    case apaylosTypes.MORE_DETAILS: {
      return { ...state, moreDetails: action.payload };
    }

    default: {
      return { ...state };
    }
  }
};
