import payeesTypes from "../../actionTypes/payeesTypes";

const initialState = {
  payeesList: [],
  payeeDetails: {},
  fetching: false,
  pagination: { pageNo: 1, pages: 1 },
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case payeesTypes.SET_LIST: {
      return { ...state, payeesList: action.payload };
    }

    case payeesTypes.DETAILS: {
      return { ...state, payeeDetails: action.payload };
    }

    case payeesTypes.FETCHING: {
      return { ...state, fetching: action.payload };
    }

    case payeesTypes.PAGINATION: {
      return { ...state, pagination: action.payload };
    }

    default: {
      return { ...state };
    }
  }
};
