const customersTypes = {
  FETCHING: "CUSTOMER_LIST_FETCHING",
  SET_LIST: "SET_CUSTOMER_LIST",
  DETAILS: "SET_CUSTOMER_DETAILS",
  PAGINATION: "CUSTOMER_LIST_PAGINATION",
  SET_PAYEE_LIST: "SET_PAYEE_LIST",
  PAGINATION_PAYEE: "PAYEE_LIST_PAGINATION",
  ACTION_FETCHING: "CUSTOMER_ACTION_FETCHING",
  SET_USER_LEDGER_LIST: "SET_USER_LEDGER_LIST",
  PAGINATION_USER_LEDGER: "PAGINATION_USER_LEDGER",
};

export default customersTypes;
