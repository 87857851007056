import { staffsType } from "../../../actionTypes/staffsType";

const initialState = {
  fetching: false,
  staffList: [],
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case staffsType.FETCHING: {
      return { ...state, fetching: action.payload };
    }
    case staffsType.SET_STAFFS: {
      return { ...state, staffList: action.payload };
    }
    default: {
      return { ...state };
    }
  }
};
