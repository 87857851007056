import { useEffect, useState } from "react";
import { trackEvent } from "../analytics";

export default function useApi(
  {
    api,
    values,
    doFetch = true,
    defaultValue = null,
    defaultLoading = true,
    trackingName = "",
  },
  dependencies = []
) {
  const [data, setData] = useState(defaultValue);
  const [loading, setLoading] = useState(defaultLoading);
  const [error, setError] = useState(null);

  const fetch = async () => {
    try {
      trackEvent(`apiFetch-${trackingName}-initiated`, { values });
      setLoading(true);
      const response = await api(values);
      if (response?.status !== 200) {
        trackEvent(`apiFetch-${trackingName}-error`);
        return setError(response);
      }
      setData(response.data);
      setLoading(false);
      trackEvent(`apiFetch-${trackingName}-success`);
    } catch (error) {
      setError(error);
      setLoading(false);
      trackEvent(`apiFetch-${trackingName}-internal-error`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (doFetch) {
      fetch();
    } else {
      setLoading(false);
    }
  }, dependencies);

  return [data, loading, error, fetch, setData];
}
