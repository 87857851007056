import bookingTypes from "../../../actionTypes/bookingTypes";
import { toast } from "react-toastify";
import {
  fetchBookingByIdApi,
  fetchBookingBySearchApi,
  fetchBookingsByStatusApi,
  fetchBookingsByTabApi,
  fetchBookingsByUserApi,
  fetchReasonsApi,
  getBookingsv2Api,
  getBookingDetailsByIdApi,
  rejectBookingApi,
  updatePriceApi,
  getBookingsCountApi,
  getBookingCountHotelwiseApi,
} from "../../../../services/newServices";
export const fetchBookingCountHotelWise = () => async (dispatch) => {
  dispatch({ type: bookingTypes.FETCHING, payload: true });
  const response = await getBookingCountHotelwiseApi();
  //console.log("response hotelwise", response?.data);
  if (response.status === 200) {
    dispatch({
      type: bookingTypes.SET_BOOKING_COUNT_HOTELWISE,
      payload: response.data,
    });
  }
  dispatch({ type: bookingTypes.FETCHING, payload: false });
};
export const fetchBookingsByStatus = (obj) => async (dispatch) => {
  dispatch({ type: bookingTypes.FETCHING, payload: true });

  const { id, page, status } = obj;

  const response = await fetchBookingsByStatusApi(id, page, status);

  if (response.status === 200) {
    dispatch({ type: bookingTypes.SET_BOOKING_LIST, payload: response.data });
  }

  dispatch({ type: bookingTypes.FETCHING, payload: false });
};

export const fetchBookingCount = (obj) => async (dispatch) => {
  dispatch({ type: bookingTypes.FETCHING, payload: true });
  const response = await getBookingsCountApi(obj);
  if (response.status === 200) {
    dispatch({ type: bookingTypes.SET_BOOKING_COUNT, payload: response.data });
  }
  dispatch({ type: bookingTypes.FETCHING, payload: false });
};

export const fetchBookingsByTab = (obj) => async (dispatch) => {
  dispatch({ type: bookingTypes.FETCHING, payload: true });

  const { id, page, status } = obj;

  const response = await getBookingsv2Api(id, page, status);

  if (response.status === 200) {
    dispatch({ type: bookingTypes.SET_BOOKING_LIST, payload: response.data });
  }

  dispatch({ type: bookingTypes.FETCHING, payload: false });
};

export const fetchBookingsBySearch = (id, url) => async (dispatch) => {
  dispatch({ type: bookingTypes.FETCHING, payload: true });

  const response = await fetchBookingBySearchApi(id, url);
  if (response.status === 200) {
    dispatch({ type: bookingTypes.SET_BOOKING_LIST, payload: response.data });
  }

  dispatch({ type: bookingTypes.FETCHING, payload: false });
};

export const fetchBookingByUser = (page) => async (dispatch) => {
  dispatch({ type: bookingTypes.FETCHING, payload: true });

  const response = await fetchBookingsByUserApi(page);

  if (response.status === 200) {
    dispatch({ type: bookingTypes.SET_BOOKING_LIST, payload: response.data });
  }

  dispatch({ type: bookingTypes.FETCHING, payload: false });
};

export const fetchBookingById = (bookingId) => async (dispatch) => {
  dispatch({ type: bookingTypes.FETCHING, payload: true });

  const response = await getBookingDetailsByIdApi(bookingId);

  if (response.status === 200) {
    dispatch({
      type: bookingTypes.SET_BOOKING_VIEW,
      payload: response?.data,
    });
  }

  dispatch({ type: bookingTypes.FETCHING, payload: false });
};

export const fetchReasons = (status) => async (dispatch) => {
  dispatch({ type: bookingTypes.FETCHING, payload: true });

  const response = await fetchReasonsApi(status);
  console.log(response);

  dispatch({ type: bookingTypes.SET_REASONS, payload: response.data });

  dispatch({ type: bookingTypes.FETCHING, payload: false });
};

export const rejectBooking =
  (id, obj, callback = () => {}) =>
  async (dispatch) => {
    dispatch({ type: bookingTypes.FETCHING, payload: true });

    const response = await rejectBookingApi(id, obj);

    if (response.status === 200) {
      callback();
      toast.success(response.message);
    } else {
      toast.error(response?.error || "failure");
    }

    dispatch({ type: bookingTypes.FETCHING, payload: false });
  };

export const updatePrice = (obj) => async (dispatch) => {
  dispatch({ type: bookingTypes.FETCHING, payload: true });

  const response = await updatePriceApi(obj);

  if (response.status === 200) {
    toast.success(response.message);
  } else {
    toast.error(response?.error || "failure");
  }

  dispatch({ type: bookingTypes.FETCHING, payload: false });
};
