import { combineReducers } from "redux";
import announcement from "./announcement/index";
import appConfig from "./appConfig/index";
import auth from "./NEWDASHBOARD/auth/index";
import banner from "./banners/index";
import customer from "./customers/index";
import customizer from "./customizer/";
import feedbacks from "./feedbacks/index";
import kyc from "./kyc/index";
import payee from "./payee/index";
import transaction from "./transaction/index";
import walletTopup from "./walletTopup/index";
import tickets from "./tickets/index";
import navbar from "./navbar/Index";
import comply from "./complyAdvantage/index";
import reason from "./reasons/index";
import apaylo from "./apaylo/index";
import admin from "./admin/index";
import manageDeposit from "./manageDeposit";
import auditLogs from "./auditLogs/index";
import users from "./NEWDASHBOARD/users";
import hotels from "./NEWDASHBOARD/hotels";
import bookings from "./NEWDASHBOARD/bookings";
import manage from "./NEWDASHBOARD/manage";
import addStaff from "./NEWDASHBOARD/addStaff";
import manageInventory from "./NEWDASHBOARD/manageInventory";
import policy from "./NEWDASHBOARD/policy";
import hotelAddons from "./NEWDASHBOARD/addons";
import onboarding from "./NEWDASHBOARD/onboarding";
import utmSource from "./NEWDASHBOARD/utm_source";

const rootReducer = combineReducers({
  customizer: customizer,
  auth,
  navbar: navbar,
  customer,
  banner,
  announcement,
  appConfig,
  feedbacks,
  tickets,
  kyc,
  payee,
  transaction,
  walletTopup,
  comply,
  reason,
  apaylo,
  admin,
  manageDeposit,
  auditLogs,
  users,
  hotels,
  bookings,
  manage,
  addStaff,
  manageInventory,
  policy,
  hotelAddons,
  onboarding,
  utmSource,
});

export default rootReducer;
