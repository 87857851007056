import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Layout } from "./utility/context/Layout";
// import * as serviceWorker from "./serviceWorker"
import { store } from "./redux/storeConfig/store";
import Spinner from "./components/@vuexy/spinner/Fallback-spinner";
import "./index.scss";
import "./@fake-db";
import "./override.scss";
import "react-table/react-table.css";
import storageService from "./services/storageServices";
import "react-phone-input-2/lib/style.css";
import "./i18n";
import ErrorBoundary from "./components/errorBoundary/ErrorBoundary";

const LazyApp = lazy(() => import("./App"));

const userDetails = storageService.get("userAdmin") || {};

const userInfo = {
  username: userDetails?.userId,
  role: userDetails?.role,
  props: { ...userDetails, token: "", regenrateToken: "" },
};

// configureDatabase()
ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <Layout>
          <LazyApp />
        </Layout>
      </Suspense>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
