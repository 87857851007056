import reasonTypes from "../../actionTypes/reasonTypes";

const initialState = {
  reasonList: [],
  fetching: false,
  actionLoader: false,
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case reasonTypes.SET_LIST: {
      return { ...state, reasonList: action.payload };
    }

    case reasonTypes.FETCHING: {
      return { ...state, fetching: action.payload };
    }

    case reasonTypes.ACTION_LOADER: {
      return { ...state, actionLoader: action.payload };
    }

    default: {
      return { ...state };
    }
  }
};
