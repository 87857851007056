import httpService from "./httpServices";

export const verifyNumber = (data) => {
  return httpService.post("users/v1/isExistAndSendOtp", data);
};

export const verifyOTP = (data) => {
  return httpService.post("users/v1/verifyOtp", data);
};

export const updateProfileApi = (data) => {
  return httpService.post("/admin/update-profile", data);
};

export const getProfileApi = () => {
  return httpService.get("/admin/profile");
};

export const forgotPasswordApi = (data) => {
  return httpService.post("/admin/forgot-password", data);
};

export const recoverPasswordApi = () => {};

export const changePasswordApi = (data) => {
  return httpService.post("/admin/change-password", data);
};
