import announcementsTypes from "../../actionTypes/announcementsTypes";

const initialState = {
  announcementsList: [],
  announcementDetails: {},
  fetching: false,
  pagination: { pageNo: 1, pages: 1 },
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case announcementsTypes.SET_LIST: {
      return { ...state, announcementsList: action.payload };
    }

    case announcementsTypes.DETAILS: {
      return { ...state, announcementDetails: action.payload };
    }

    case announcementsTypes.FETCHING: {
      return { ...state, fetching: action.payload };
    }

    case announcementsTypes.PAGINATION: {
      return { ...state, pagination: action.payload };
    }

    default: {
      return { ...state };
    }
  }
};
