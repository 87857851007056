import adminsTypes from "../../actionTypes/adminTypes";

// const dummyData = [
//   {
//     name: "Tribhuvan",
//     email: "tribhuvan.sharma@affle.com",
//     createdAt: "2021-12-09T06:08:15.761Z",
//     mobile_number: "",
//     id: "66ccf759-3076-426d-88e9-94beeb2b18b4",
//     permissions: ["dashboard"],
//     is_active: true,
//   },
// ];

const initialState = {
  adminsList: [],
  adminDetails: {},
  fetching: false,
  actionFetching: false,
  pagination: { pageNo: 1, pages: 1 },
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case adminsTypes.SET_LIST: {
      return { ...state, adminsList: action.payload };
    }

    case adminsTypes.DETAILS: {
      return { ...state, adminDetails: action.payload };
    }

    case adminsTypes.FETCHING: {
      return { ...state, fetching: action.payload };
    }

    case adminsTypes.ACTION_FETCHING: {
      return { ...state, actionFetching: action.payload };
    }

    case adminsTypes.PAGINATION: {
      return { ...state, pagination: action.payload };
    }

    default: {
      return { ...state };
    }
  }
};
