export const routes = {
  home: "/",
  customWebsite: "/custom-website",
  scraper: "/scraper",
  users: "/users",
  domain: "/domain",
  // socialAds: "/ai-ads",
  aiCampCreate: "/create-campaign/:id",
  aiCampEdit: "/edit-campaign/:id",
  bookingPreference: "/booking-preference",
  socialAds: "/ai",
  campaigns: "/campaigns-view",
  campaignCreation: "/campaigns",
  allImage: "/allImage",
  terms: "/terms-conditions",
  terms1: "/terms-conditons",
  hotelImage: "/hotelImage",
  addProperty: "/add-property",
  updateProperty: "/update-property",
  leads: "/leads",
  rooms: "/rooms",
  addRoom: "/add-room",
  faq: "/faq",
  gads: "/google-ads",
  gmb: "/google-my-business",
  settlements: "/settlements",
  step1: "/create-property",
  promo: "/promotions",
  addFaq: "/faq",
  hotelAddons: "/addons",
  addHotelAddons: "/add-addons",
  editHotelAddons: "/edit-addons",
  addStaff: "/add-staff",
  houseRules: "/house-rules",
  cancellationPolicy: "/cancellation-policy",
  inventoryManagement: "/inventory-management",
  social: "/social",
  addLogo: "/add-logo",
  report: "/report",
  bookings: "/bookings",
  paymentConfig: "/payment",
  bookingDetails: `/booking/:hotelId/:id`,
  login: "/pages/login",
  forgotPassword: "/pages/forgot-password",
  recoverPassword: "/pages/recover-password",
  settings: "/pages/settings",
  dashboard: "/pages/dashboard",
  volume: "/pages/volume",
  customers: "/pages/customers",
  kyc: "/pages/kyc",
  walletTopup: "/pages/wallet-top-up",
  transactionHistory: "/pages/transaction-history",
  withdrawal: "/pages/withdrawal",
  payee: "/pages/payee",
  transactionStatus: "/pages/transaction-status",
  ticket: "/pages/ticket",
  feedback: "/pages/feedback",
  banner: "/pages/manage-banner",
  urgentAnnouncement: "/pages/manage-announcement",
  appConfig: "/pages/manage-app-config",
  complyAdvantage: "/pages/comply-advantage",
  rejectReason: "/pages/reject-reason",
  apayloMail: "/pages/apayloMail",
  apayloTransaction: "/pages/apayloTransaction",
  moreDetails: "/pages/more-details",
  manageAdmin: "/pages/manage-admin",
  auditLogs: "/pages/audit-logs",
  manageDepositWorkflow: "/pages/add-money-options",
  googleReviews: "/google-reviews",
  profile: "/profile",
  post: "/post",
  scrape: "/scrape",
  personalInfo: "/personal-info",
  invoices: "/invoices",
  revenueManagement: "/revenue-management",
  channelManager: "/channel-manager",
  requestAccountDeletion: "/request-account-deletion",
  taxes: "/taxes",
};
