import manageTypes from "../../../actionTypes/manageTypes";

const initialState = {
  socialLinks: [],
  fetching: false,
  report: "",
  whatsapp: {},
  gateways: [],
  selectedGateways: [],
  bankDetails: [],
  faqs: [],
  unansweredFaqs: [],
  faqSuggestions: [],
  promotions: [],
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case manageTypes.FETCHING: {
      return { ...state, fetching: action.payload };
    }
    case manageTypes.SET_SOCIAL_URLS: {
      return { ...state, socialLinks: action.payload };
    }
    case manageTypes.FETCH_REPORT: {
      return { ...state, report: action.payload };
    }

    case manageTypes.SET_WHATSAPP: {
      return { ...state, whatsapp: action.payload };
    }

    case manageTypes.FETCH_GATEWAYS: {
      return { ...state, gateways: action.payload };
    }

    case manageTypes.FETCH_SELECTED_GATEWAYS: {
      return { ...state, selectedGateways: action.payload };
    }

    case manageTypes.SET_BANK: {
      return { ...state, bankDetails: action.payload };
    }

    case manageTypes.FETCH_FAQ: {
      return { ...state, faqs: action.payload };
    }

    case manageTypes.FETCH_FAQ_SUGGESTION: {
      return { ...state, faqSuggestions: action.payload };
    }

    case manageTypes.FETCH_UNANSWERED_FAQ: {
      return { ...state, unansweredFaqs: action.payload };
    }

    case manageTypes.FETCH_PROMOTIONS: {
      return { ...state, promotions: action.payload };
    }

    default: {
      return { ...state };
    }
  }
};
