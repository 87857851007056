import { BiSupport } from "react-icons/bi";
import React, { useEffect, useState } from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import Badge from "@mui/material/Badge";
import { Link } from "react-router-dom";
import axios from "axios";
import * as Icon from "react-feather";
import { logOutAction } from "../../../redux/actions/NEWDASHBOARD/auth/index";
import { connect, useDispatch } from "react-redux";
import CustomModal from "../../../components/custom/CustomModal";
import { history } from "../../../history";
import { routes } from "../../../component_route/Index";
import { trackEvent } from "../../../analytics";
import { TranslateRounded } from "@mui/icons-material";
import storageService from "../../../services/storageServices";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { FaRegStar } from "react-icons/fa";
import { getBookingsCountApi } from "../../../services/newServices";
import useApi from "../../../custom_hooks/useApi";
import { useSelector } from "react-redux";
import { fetchBookingCount } from "../../../redux/actions/NEWDASHBOARD/bookings";
import { BiLogoGmail } from "react-icons/bi";
import { CgMail } from "react-icons/cg";

const UserDropdown = (props) => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const toggleOpen = () => {
    setOpen(!open);
    trackEvent("NavbarUser-toggled-open", { isOpened: !open });
  };

  const logOutUser = () => {
    props.logOutAction();
    trackEvent("NavbarUser-logout-button-clicked");
  };

  const navigateToPersonalInfo = () => {
    history.push("/personal-info");
  };

  return (
    <React.Fragment>
      <DropdownMenu right className="mr-0" style={{ minWidth: "fit-content" }}>
        {/* <DropdownItem tag="span" onClick={() => history.push(routes.settings)}>
          <Icon.Settings size={14} className="mr-50" />
          <span className="align-middle">Settings</span>
        </DropdownItem>
        <DropdownItem divider /> */}
        <DropdownItem tag="span" href="#" onClick={navigateToPersonalInfo}>
          <Icon.User size={14} className="mr-50" />
          <span className="align-middle">{t("personal_info")}</span>
        </DropdownItem>
        <DropdownItem tag="span" href="#" onClick={toggleOpen}>
          <Icon.Power size={14} className="mr-50 danger" />
          <span className="align-middle danger">{t("logout")}</span>
        </DropdownItem>
      </DropdownMenu>
      {open ? (
        <CustomModal
          className="logout_modal"
          open={open}
          toggleModal={toggleOpen}
          headingText={t("logout")}
          paragraphHeading={t("logout_confirmation")}
          successAction={logOutUser}
          successText={t("logout")}
        />
      ) : null}
    </React.Fragment>
  );
};

const LanguageTranslate = (props) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
    trackEvent("Language-toggled-open", { isOpened: !open });
  };

  const toggleLanguage = (lang) => {
    storageService.set("lang", lang);
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    const setLang = () => {
      let lang = storageService.get("lang");
      i18n.changeLanguage(lang);
    };
    setLang();
  }, []);

  return (
    <React.Fragment>
      <DropdownMenu right className="mr-0" style={{ minWidth: "fit-content" }}>
        {/* <DropdownItem tag="span" onClick={() => history.push(routes.settings)}>
          <Icon.Settings size={14} className="mr-50" />
          <span className="align-middle">Settings</span>
        </DropdownItem>
        <DropdownItem divider /> */}
        <DropdownItem tag="span" href="#" onClick={() => toggleLanguage("de")}>
          <span className="align-middle" onClick={() => toggleLanguage("de")}>
            German
          </span>
        </DropdownItem>
        <DropdownItem tag="span" href="#" onClick={() => toggleLanguage("ar")}>
          <span className="align-middle" onClick={() => toggleLanguage("ar")}>
            Arabic
          </span>
        </DropdownItem>
        <DropdownItem tag="span" href="#" onClick={() => toggleLanguage("en")}>
          <span className="align-middle">English</span>
        </DropdownItem>
      </DropdownMenu>
      {/* {open ? (
        <CustomModal
          className="logout_modal"
          open={open}
          toggleModal={toggleOpen}
          headingText={"Logout"}
          paragraphHeading={"Are you sure you want to logout ?"}
          successAction={logOutUser}
          successText="Log Out"
        />
      ) : null} */}
    </React.Fragment>
  );
};

const BookingCount = () => {
  const { selectedHotel } = useSelector((state) => state.hotels);
  const { bookingCount } = useSelector((state) => state.bookings);
  console.log("hoteldata", selectedHotel, bookingCount);

  const dispatch = useDispatch();
  const getbookingCount = () => {
    dispatch(fetchBookingCount("New"));
  };

  useEffect(() => {
    selectedHotel?.id && getbookingCount();
  }, [selectedHotel?.id]);

  return (
    bookingCount > 0 && (
      <Badge badgeContent={bookingCount} color="primary">
        <Link to="/bookings">
          <FaRegStar
            size={20}
            style={{
              color: "#01275d",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        </Link>
      </Badge>
    )
  );
};

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    suggestions: [],
  };

  componentDidMount() {
    axios.get("/api/main-search/data").then(({ data }) => {
      this.setState({ suggestions: data.searchResult });
    });
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    });
  };

  render() {
    return (
      <>
        <ul className="nav navbar-nav navbar-nav-user float-right">
          <div className="user-nav d-sm-flex">{/* <BookingCount /> */}</div>
        </ul>
        <ul className="nav navbar-nav navbar-nav-user float-right">
          <div className="user-nav d-sm-flex">
            {/* {bookingsCount > 0 && (
              <span className="bookings-count">{bookingsCount}</span>
            )} */}
            <a
              href="tel:+917975660291"
              className="user-name text-bold-600 p-1"
              style={{
                color: "#01275d",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
              onClick={() => trackEvent("support_number_clicked")}
            >
              <BiSupport size={20} /> +91 79756 60291
            </a>
            <a
              href={`mailto:support@hotelzify.com`}
              className="user-name text-bold-600 p-1"
              style={{
                color: "#01275d",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
              onClick={() => trackEvent("support_email_clicked")}
            >
              <CgMail size={20} /> support@hotelzify.com
            </a>
          </div>
        </ul>
        <ul className="nav navbar-nav navbar-nav-user float-right">
          <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
            <DropdownToggle tag="a" className="nav-link dropdown-user-link">
              <div className="user-nav d-sm-flex d-none">
                <span className="user-name text-bold-600">
                  {this.props.userName}
                </span>
              </div>
              <span data-tour="user" style={{ color: "#00275E" }}>
                <TranslateRounded />
              </span>
            </DropdownToggle>
            <LanguageTranslate {...this.props} />
          </UncontrolledDropdown>
        </ul>
        <ul className="nav navbar-nav navbar-nav-user float-right">
          <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
            <DropdownToggle tag="a" className="nav-link dropdown-user-link">
              <div className="user-nav d-sm-flex d-none">
                <span className="user-name text-bold-600">
                  {this.props.userName}
                </span>
              </div>
              <span data-tour="user" style={{ color: "#00275E" }}>
                <Icon.MoreVertical />
              </span>
            </DropdownToggle>
            <UserDropdown {...this.props} />
          </UncontrolledDropdown>
        </ul>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logOutAction: (callback) => dispatch(logOutAction(callback)),
  };
};

export default connect(null, mapDispatchToProps)(NavbarUser);
