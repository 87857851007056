import { toast } from "react-toastify";
import hotelTypes from "../../../actionTypes/hotelTypes";
import {
  fetchHotelsApi,
  fetchHotelStatsApi,
  fetchMyHotelsApi,
  fetchWebViewsApi,
} from "../../../../services/newServices";
import storageService from "../../../../services/storageServices";
import { routes } from "../../../../component_route/Index";

export const fetchHotels = () => async (dispatch) => {
  dispatch({ type: hotelTypes.FETCHING, payload: true });
  let hotel = storageService.get("selectedHotel");
  let id = storageService.get("id");

  const response = await fetchMyHotelsApi();
  if (response.status === 200 && response?.data?.length > 0) {
    dispatch({ type: hotelTypes.SET_HOTELS_LIST, payload: response?.data });
    let obj = id ? id : response?.data[0]?.id;
    const resp2 = await fetchHotelsApi(obj);
    console.log("resp2", resp2);
    dispatch({
      type: hotelTypes.SET_SELECTED_HOTEL,
      payload: resp2?.data?.[0] || hotel,
    });
    !hotel && storageService.set("id", resp2?.data[0]?.id);
    !hotel && storageService.set("selectedHotel", resp2?.data[0]);
  } else {
    window.location.href = "/admin/add-property";
  }
  dispatch({ type: hotelTypes.FETCHING, payload: false });
};

export const fetchHotelStats = (id) => async (dispatch) => {
  dispatch({ type: hotelTypes.FETCHING, payload: true });

  const response = await fetchHotelStatsApi(id);

  if (response.status === 200 && response.data) {
    dispatch({ type: hotelTypes.SET_STATS, payload: response.data });
  }

  dispatch({ type: hotelTypes.FETCHING, payload: false });
};

export const fetchWebViews = (id) => async (dispatch) => {
  dispatch({ type: hotelTypes.FETCHING, payload: true });

  const obj = {
    hotelId: id,
  };

  const response = await fetchWebViewsApi(obj);

  if (response.status === 200) {
    dispatch({ type: hotelTypes.SET_VIEWS, payload: response.data });
  }

  dispatch({ type: hotelTypes.FETCHING, payload: false });
};
