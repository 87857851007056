import { hotelAddonTypes } from "../../../actionTypes/hotelAddonsType";

const initialState = {
  fetching: false,
  addonTypes: [],
  hotelAddons: [],
};

export default (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case hotelAddonTypes.FETCHING: {
      return { ...state, fetching: payload };
    }
    case hotelAddonTypes.FETCH_TYPES: {
      return { ...state, addonTypes: payload };
    }
    case hotelAddonTypes.FETCH_ADDONS: {
      return { ...state, hotelAddons: payload };
    }
    default: {
      return { ...state };
    }
  }
};
