import walletTopupTypes from "../../actionTypes/walletTopupTypes";

// const list = [
//   {
//     customer_name: "Tribhuvan",
//     amount: "10000",
//     wallet_amount: "25.42",
//   },
// ];

const initialState = {
  walletTopupList: [],
  walletTopupDetails: {},
  fetching: false,
  pagination: { pageNo: 1, pages: 1 },
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case walletTopupTypes.SET_LIST: {
      return { ...state, walletTopupList: action.payload };
    }

    case walletTopupTypes.DETAILS: {
      return { ...state, walletTopupDetails: action.payload };
    }

    case walletTopupTypes.FETCHING: {
      return { ...state, fetching: action.payload };
    }

    case walletTopupTypes.PAGINATION: {
      return { ...state, pagination: action.payload };
    }

    default: {
      return { ...state };
    }
  }
};
